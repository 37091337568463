import React, { useEffect } from "react";
import './join-us.scss';
import Helmet from 'react-helmet';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Container, Button, Grid, AccordionSummary, AccordionDetails, Typography, TextField, } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PartnerLogo2 from "../../../../components/shared/partner-logo2/PartnerLogo";

import img0 from "../../../../assets/public/images/join-us/banner-logo.png";
import img1 from "../../../../assets/public/images/join-us/banner-bt-1.png";
import img2 from "../../../../assets/public/images/join-us/banner-bt-2.png";
import img3 from "../../../../assets/public/images/join-us/banner-bt-3.png";
import img4 from "../../../../assets/public/images/join-us/right-arrow-after.png";
import img5 from "../../../../assets/public/images/join-us/email marketing -1-.png";
import img6 from "../../../../assets/public/images/join-us/Social.png";
import img7 from "../../../../assets/public/images/join-us/img.png";
import img8 from "../../../../assets/public/images/join-us/LiveChat.png";
import img9 from "../../../../assets/public/images/join-us/Broker Websites.png";
import img10 from "../../../../assets/public/images/join-us/Intranet.png";
import img11 from "../../../../assets/public/images/join-us/flat-free-bg.png";
import img12 from "../../../../assets/public/images/join-us/your-success.png";
import img13 from "../../../../assets/public/images/join-us/with-us-1.png";
import img14 from "../../../../assets/public/images/join-us/with-us-2.png";
import img15 from "../../../../assets/public/images/join-us/with-us-3.png";
import logo from '../../../../assets/public/images/webp/rateshop-logo.webp';

const JoinUs = function () {

    const [baseUrl, setBaseUrl] = React.useState('/');
    var options = {
        className: "owl-theme testimonial-slider",
        margin: 0,
        responsiveClass: true,
        center: true,
        dots: false,
        nav: false,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            991: {
                items: 3,
            },
            1300: {
                items: 3,
            }
        }
    }
    var options1 = {
        className: "owl-theme  owl-carousel-vertical  marketing-slider-1",
        margin: 0,
        responsiveClass: true,
        center: true,
        dots: false,
        nav: false,
        loop: true,
        items: 1,

    }
    return (
        <>
            {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Canada's Maximum Mortgage & Affordability Calculator</title>
          <meta name='description' content='Canada&#x27;s maximum mortgage and affordability calculator helps prospective home buyers estimate how much they can afford to borrow based on their income, expenses, and other financial factors. Use this tool to determine your mortgage affordability and make informed decisions when purchasing a home in Canada.' />
          <meta name="keywords" content="mortgage affordability calculator, td mortgage affordability calculator, mortgage affordability calculator canada, affordability calculator"/>
        </Helmet>  */}
            {/* banner start */}
            <section className="banner-sec">
                <div className="banner-outer-layer">
                </div>
                <Container>
                    <div className="banner_content">
                        <figure className="banner-logo">
                            <img src={img0} className="img-fluid" />
                        </figure>
                        <h1>PROGRESS YOUR CAREER AS A <br></br>
                            <span>MORTAGE BROKER</span>
                        </h1>
                        <div className="bottom-outer">
                            <ul className="d-flex align-items-center flex-wrap justify-content-between">
                                <li>
                                    <figure>
                                        <img src={img1} />
                                    </figure>
                                </li>
                                <li>
                                    <figure>
                                        <img src={img2} />
                                    </figure>
                                </li>
                                <li>
                                    <figure>
                                        <img src={img3} />
                                    </figure>
                                </li>
                            </ul>
                        </div>
                        <ul className="banner-list">
                            <li>
                                <p><img src={img4} className="img-fluid" /> Volume Brokerage Status</p>
                            </li>
                            <li>
                                <p><img src={img4} className="img-fluid" /> Highest Commission Payout</p>
                            </li>
                            <li>
                                <p><img src={img4} className="img-fluid" /> Underwriting Help Desk</p>
                            </li>
                            <li>
                                <p><img src={img4} className="img-fluid" /> Sales &amp; Submission Training</p>
                            </li>
                        </ul>
                        <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                            Join in 3 steps
                        </Link> 
                        <div className="make-more-outer d-flex flex-wrap align-items-center">
                            <h5>CLOSE MORE DEALS WITH</h5>
                            <img src={logo} className="img-fluid" />
                        </div>
                    </div>
                </Container>
            </section>
            {/* leader in start */}
            <section className="leader-in-sec">
                <Container>
                    <div className="leader-in_content">
                        <Grid container>
                            <Grid xs={12} sm={12} md={12} lg={5} xl={6} item className="cs-p0">
                                <div className="item_card cs-left">
                                    <div class="heading"><h2><span>Leaders</span> in Mortgages</h2></div>
                                    <p>RateShop.ca is the expert brokerage you need to build your career. We are now hiring mortgage agents, mortgage advisors, and mortgage brokers in Ontario! </p> <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                        Talk To Us
                                    </Link>
                                </div>
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} item className="cs-p0">
                                <div className="item_card cs-right">
                                    <ul className="d-flex ">
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 435 525"    >
                                                        <g>
                                                            <path d="M390.18,428.39l5.34,2.88c1.42,0.77,2.82,1.6,4.14,2.47c2.81,1.81,5.29,3.78,7.38,5.87c3.53,3.54,5.31,7.75,5.31,12.53
        c0,6.69-1.73,12.06-5.13,15.95c-3.2,3.67-7.28,6.49-12.14,8.35l-4.91,1.88V428.39L390.18,428.39z M376.48,404.77l-5.78-4.3
        c-2.31-1.71-4.25-3.72-5.79-5.94c-1.75-2.53-2.64-5.87-2.64-9.91c0-2.87,0.77-5.87,2.35-9.16c1.54-3.2,3.54-5.82,5.92-7.82
        l5.94-4.95V404.77L376.48,404.77z M395.25,367.86c1.97,0.88,3.66,2.01,5,3.37c1.26,1.26,2.23,2.72,2.87,4.33
        c1.01,2.5,1.92,5,2.7,7.46c0.62,1.93,1.46,3.57,2.56,5.03c0.58,0.76,2.16,1.19,4.34,1.19c2.35,0,4.17-0.76,5.74-2.39
        c1.59-1.66,2.36-3.68,2.36-6.22c0-3.78-0.73-7.33-2.18-10.59c-1.48-3.36-3.49-6.35-6-8.92c-2.61-2.66-5.63-4.92-8.96-6.7
        c-2.31-1.23-4.68-2.22-7.04-2.94c-1.21-0.38-2.39-0.7-3.59-0.95l-2.88-0.6v-5.19c0-0.38-0.01-0.74-0.03-1.1
        c-0.32-6.01-3.05-7.23-7.05-7.23c-3.72,0-6.26,1.22-6.57,7.19c-0.03,0.4-0.04,0.77-0.04,1.14v5.06l-2.73,0.69
        c-3.63,0.92-7.06,2.37-10.18,4.3c-3.19,1.97-6.05,4.42-8.52,7.27c-2.45,2.83-4.42,6.16-5.85,9.89c-1.39,3.7-2.1,7.97-2.1,12.65
        c0,4.74,0.68,8.97,2.01,12.58c1.33,3.65,3.23,6.98,5.63,9.9c2.43,2.97,5.32,5.64,8.62,7.95c0.49,0.34,1.03,0.7,1.56,1.06
        c2.98,1.96,6.18,3.76,9.51,5.35l2.05,0.98v55.7l-4.96-1.98c-9.35-3.73-15.51-10.47-18.3-20.03c-1.27-4.65-3.66-6.61-8.01-6.61
        c-1.97,0-3.81,0.74-5.63,2.26c-1.06,0.88-2.44,2.48-2.44,6.34c0,3.72,0.88,7.47,2.63,11.14c0.59,1.21,1.18,2.29,1.8,3.29
        c1.54,2.51,3.45,4.92,5.71,7.19c3.23,3.22,7.15,6.07,11.6,8.45c4.49,2.4,9.45,4.17,14.72,5.25l2.89,0.59v7.93
        c0,5,1.85,6.86,6.85,6.86c4.98,0,6.83-1.86,6.83-6.86v-7.8l3.03-0.51c4.59-0.76,9.05-2.23,13.28-4.38
        c4.16-2.12,7.89-4.93,11.09-8.35c3.15-3.36,5.71-7.54,7.61-12.42c1.91-4.88,2.88-10.55,2.88-16.87c0-4.96-0.9-9.5-2.68-13.47
        c-0.3-0.66-0.6-1.3-0.93-1.92c-1.72-3.35-3.9-6.42-6.49-9.13c-3.2-3.36-7.03-6.43-11.37-9.13c-3.29-2.07-6.81-4.03-10.47-5.82
        c-1.48-0.72-2.69-1.29-3.8-1.79l-2.14-0.96V365.6L395.25,367.86L395.25,367.86z"></path>
                                                            <path d="M75.91,246.11c-7.65,11.31-12.12,24.99-12.12,39.65v35.21c0,2.92,1.77,5.57,4.47,6.69c2.7,1.12,5.82,0.49,7.89-1.58
        l16.32-16.4l157.5,158.28h-51.48v-6.2c0-3.99-3.24-7.23-7.23-7.23s-7.23,3.24-7.23,7.23l0,0v13.43c0,1.9,0.77,3.77,2.12,5.11
        c1.35,1.35,3.21,2.12,5.11,2.12h111.28c3.99,0,7.23-3.24,7.23-7.23c0-3.99-3.24-7.23-7.23-7.23h-32.15L97.6,294.32
        c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13l-9.09,9.13v-17.7c0-7.81,1.58-15.22,4.43-21.96
        c4.28-10.12,11.44-18.75,20.46-24.83c9.02-6.08,19.84-9.63,31.55-9.63h18.4l51.62,89.39c1.5,2.59,4.45,4,7.4,3.53
        c2.95-0.47,5.32-2.73,5.94-5.65l9.52-45.26c0.37-1.75,0.08-3.55-0.81-5.11l-11.7-20.26l9.61-16.64l19.22,0l9.61,16.64l-11.7,20.26
        c-0.89,1.55-1.18,3.36-0.81,5.11l9.2,43.59c0.62,2.93,2.99,5.18,5.94,5.65c2.95,0.47,5.9-0.94,7.4-3.53l50.65-87.72h18.4
        c7.81,0,15.22,1.58,21.97,4.43c10.12,4.28,18.75,11.44,24.84,20.45c6.09,9.02,9.63,19.83,9.64,31.55v32.17
        c0,3.99,3.24,7.23,7.23,7.23c3.99,0,7.23-3.24,7.23-7.23l0,0v-32.17c0-9.77-1.98-19.11-5.58-27.6
        c-5.39-12.73-14.37-23.54-25.68-31.18c-11.31-7.64-24.99-12.11-39.65-12.11h-22.58c-2.58,0-4.97,1.38-6.26,3.62l-43.29,74.99
        l-4.73-22.4l12.4-21.48c1.29-2.24,1.29-4.99,0-7.23l-13.78-23.87c-1.29-2.24-3.68-3.62-6.26-3.62H220.5
        c-2.58,0-4.97,1.38-6.26,3.62l-13.78,23.87c-1.29,2.24-1.29,4.99,0,7.23l12.4,21.48l-5.06,24.06l-44.26-76.64
        c-1.29-2.24-3.68-3.62-6.26-3.62H134.7c-9.77,0-19.11,1.98-27.6,5.57C94.36,225.82,83.55,234.8,75.91,246.11L75.91,246.11z"></path>
                                                            <path d="M282.95,128.59c-3.9-2.5-7.95-5.74-11.81-9.6c-4.94-4.94-8.86-10.2-11.52-15.05c-1.33-2.42-2.34-4.74-3.03-6.83
        c-0.69-2.09-1.04-3.94-1.12-5.4c-0.18-3.25-2.53-5.99-5.72-6.67c-5.31-1.13-10.72-1.7-16.12-1.7c-13.36,0-26.73,3.45-38.7,10.37
        l0,0h0c-23.96,13.83-38.71,39.39-38.71,67.05c0,3.09,0.2,6.15,0.56,9.17c-11.44-15.88-17.9-35.18-17.9-55.41
        c0-33.86,18.06-65.15,47.39-82.07l0,0c0,0,0,0,0,0c14.73-8.5,31.18-12.7,47.66-12.7c16.25,0,32.51,4.27,47.11,12.69c0,0,0,0,0,0
        c29.32,16.93,47.39,48.22,47.39,82.07c0,20.23-6.46,39.53-17.9,55.41c0.36-3.02,0.56-6.08,0.56-9.17c0-7.18-1-14.22-2.89-20.95
        c-0.97-3.45-4.33-5.67-7.88-5.22c-0.36,0.05-0.76,0.07-1.2,0.07c-1.38,0-3.21-0.27-5.32-0.9
        C290.61,132.85,286.85,131.09,282.95,128.59L282.95,128.59z M287.63,193.18c-1.77,2.96-1.22,6.73,1.32,9.06
        c2.55,2.33,6.36,2.53,9.14,0.5c27.95-20.42,44.79-53.1,44.79-88.19c0-39.02-20.82-75.08-54.61-94.6c0,0,0,0-0.01,0
        c-16.8-9.7-35.56-14.64-54.34-14.63c-18.92,0-37.89,4.82-54.89,14.63c0,0-0.01,0-0.01,0c-33.8,19.51-54.62,55.57-54.62,94.6
        c0,35.09,16.83,67.76,44.79,88.19c2.79,2.03,6.6,1.83,9.14-0.5c2.55-2.33,3.1-6.1,1.32-9.06c-5.78-9.63-8.98-20.78-8.98-32.4
        c0-22.49,12-43.28,31.48-54.53c0,0,0,0,0,0c9.74-5.62,20.6-8.43,31.47-8.43c2.77,0,5.54,0.18,8.29,0.55
        c0.26,1.09,0.57,2.18,0.93,3.27c1.51,4.59,3.85,9.27,6.89,13.92c3.04,4.65,6.79,9.27,11.18,13.66c6.1,6.09,12.63,10.95,19.09,14.39
        c3.23,1.72,6.45,3.08,9.63,4.03c1.96,0.58,3.9,1.02,5.84,1.26c0.75,3.87,1.14,7.84,1.14,11.87
        C296.61,172.39,293.41,183.54,287.63,193.18L287.63,193.18z"></path>
                                                            <path d="M124.65,469.52h-11.72v-11.7h11.72V469.52L124.65,469.52z M101.98,482.73c1.23,0.8,2.71,1.26,4.24,1.26h25.13
        c2.04,0,3.98-0.81,5.4-2.18c0.71-0.69,1.3-1.53,1.72-2.48c0.41-0.95,0.64-2.02,0.64-3.08V451.1c0-1.07-0.23-2.14-0.64-3.09
        c-0.62-1.43-1.64-2.59-2.87-3.4c-1.23-0.8-2.71-1.26-4.24-1.26h-25.13c-2.04,0-3.98,0.8-5.39,2.18c-0.71,0.69-1.3,1.52-1.72,2.48
        c-0.42,0.95-0.65,2.02-0.64,3.09v25.15c0,1.07,0.23,2.14,0.64,3.09C99.73,480.76,100.75,481.92,101.98,482.73L101.98,482.73z"></path>
                                                            <path d="M76.15,379.07l16.32-16.39l60.91,61.21c-1.73,0.12-3.39,0.86-4.62,2.09c-1.35,1.35-2.12,3.21-2.12,5.11v50.82v23.3H88.95
        c-0.05-0.41-0.1-0.81-0.15-1.17c-0.07-0.52-0.12-0.97-0.16-1.27c0-0.03-0.01-0.05-0.01-0.08V478.7v-27.6c0-1.02-0.2-2.05-0.6-2.98
        c-0.59-1.41-1.58-2.62-2.84-3.45c-1.25-0.84-2.78-1.31-4.31-1.3h-2.63h-22.5c-2.04,0-3.98,0.8-5.39,2.18
        c-0.71,0.69-1.3,1.52-1.72,2.48c-0.42,0.95-0.65,2.02-0.64,3.09v54.1h-9.69v-74.11c0-1.9-0.77-3.77-2.12-5.11
        c-1.23-1.23-2.89-1.97-4.63-2.09L76.15,379.07L76.15,379.07z M62.46,457.82h11.7v20.88v24.37c0,0.29,0.01,0.4,0.02,0.49
        c0.01,0.17,0.02,0.24,0.03,0.32c0.01,0.13,0.03,0.23,0.04,0.35c0.03,0.25,0.07,0.58,0.12,0.96H62.46V457.82L62.46,457.82z
         M7.51,433.86c1.12,2.7,3.75,4.46,6.68,4.46h9.65v74.11c0,1.9,0.77,3.77,2.12,5.11c1.35,1.35,3.21,2.12,5.11,2.12h23.45
        c0.08,0.01,0.15,0.01,0.21,0.01c0.21,0.01,0.31,0.01,0.38,0.01c0.07,0,0.1,0,0.12,0h0.01l27.14-0.03h71.48
        c1.9,0,3.77-0.77,5.11-2.12c1.35-1.35,2.12-3.21,2.12-5.11V481.9v-43.58l9.65,0c2.92,0,5.56-1.76,6.68-4.46
        c1.12-2.7,0.51-5.8-1.55-7.88L97.6,347.32c-1.36-1.37-3.19-2.13-5.13-2.13c-1.93,0-3.76,0.76-5.13,2.13L65.9,368.86L9.06,425.98
        C7,428.06,6.39,431.16,7.51,433.86L7.51,433.86z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>Book a Confidential Consultation</h5>
                                                <p>Discuss your Future with us online, over the phone, or in person.</p>
                                                <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                                    Schedule Appointment
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <div className="leader-in_card">
                                                <figure>
                                                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 495 448"  >
                                                        <g>
                                                            <path d="M347.16,232.88l78.74,31.16c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-78.74-31.16
        c-3.48-1.38-7.42,0.33-8.79,3.81C341.98,227.57,343.68,231.51,347.16,232.88L347.16,232.88z"></path>
                                                            <path d="M379.19,191.08l51.91,20.54c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-51.91-20.54
        c-3.48-1.38-7.42,0.33-8.79,3.81C374,185.76,375.71,189.7,379.19,191.08L379.19,191.08z"></path>
                                                            <path d="M309.14,263.57l123.49,48.87c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79l-123.49-48.87
        c-3.48-1.38-7.41,0.33-8.79,3.81C303.96,258.26,305.66,262.2,309.14,263.57L309.14,263.57z"></path>
                                                            <path d="M291.57,307.97L291.57,307.97l24.54,9.71l98.95,39.15c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-98.95-39.15l-24.54-9.71c-3.48-1.38-7.42,0.33-8.79,3.81C286.39,302.66,288.09,306.6,291.57,307.97L291.57,307.97z"></path>
                                                            <path d="M300.19,286.19L300.19,286.19l14.22,5.63l0,0l109.27,43.24c3.48,1.38,7.42-0.33,8.79-3.81c1.38-3.48-0.33-7.42-3.81-8.79
        l-109.27-43.24l0,0l-14.22-5.63c-3.48-1.38-7.42,0.33-8.79,3.81C295,280.88,296.71,284.81,300.19,286.19L300.19,286.19z"></path>
                                                            <path d="M68.77,215.55c17.3-11.68,38.1-18.49,60.56-18.5h3.61h19.03l-3.43,2.13l0,0l-5.05,3.13c-2.73,1.69-3.9,5.07-2.79,8.08
        l3.17,8.66l3.91,16.77l-0.88,10.2v0l-12.48,143.96c-0.19,2.14,0.64,4.22,2.25,5.65l24.79,22.04c2.55,2.27,6.37,2.29,8.94,0.05
        l25.11-21.81c1.62-1.41,2.48-3.49,2.31-5.64l-11.17-144l-0.8-10.27l4.05-16.71l3.27-8.5c1.15-3,0.03-6.4-2.67-8.13l-5.12-3.28
        l-3.61-2.31h18.45h3.29c13.38,0,26.16,2.42,37.98,6.85c11.81,4.42,22.65,10.85,32.1,18.88c2.53,2.15,4.93,4.52,7.21,7.06l8.79,9.8
        l-45.67,115.42l0,0c-0.85,2.15-1.26,4.41-1.26,6.61c0,3.6,1.08,7.07,3.03,10.01c1.95,2.94,4.83,5.36,8.35,6.74l0,0l57.87,22.9
        c-0.04,0.3-0.07,0.61-0.07,0.92l0,0v27.74H21.01v-124.6c0-14.97,3.03-29.21,8.51-42.16C37.73,243.79,51.48,227.23,68.77,215.55
        L68.77,215.55z M156.96,215.58c-0.06-0.28-0.14-0.52-0.23-0.79l-1.41-3.87l0.36-0.22l0,0l11.32-7.01l11.08,7.09l0.47,0.3
        l-1.44,3.76c-0.1,0.26-0.19,0.56-0.26,0.83l-3.15,12.98l-13.7-0.06L156.96,215.58L156.96,215.58z M166,403.58l-17.8-15.82
        l12.18-140.57l0,0l0.44-5.05l11.92,0.05l0.4,5.12l10.91,140.6L166,403.58L166,403.58z M325.1,186.71c0.36-0.9,0.93-1.57,1.66-2.07
        c0.73-0.49,1.61-0.76,2.48-0.76c0.54,0,1.08,0.1,1.64,0.32l8.35,3.31c1.66,0.66,3.55,0.63,5.18-0.08c1.64-0.71,2.95-2.07,3.61-3.72
        l4.39-11.1c0.86-2.17,2.3-3.88,4.09-5.08c1.79-1.2,3.93-1.86,6.11-1.86c1.34,0,2.69,0.24,4.03,0.78l90.5,35.81l2.49-6.3l-2.49,6.3
        c2.17,0.86,3.87,2.3,5.08,4.09c1.2,1.79,1.86,3.93,1.86,6.11c0,1.34-0.24,2.69-0.77,4.03l-4.39,11.1
        c-1.38,3.48,0.33,7.41,3.81,8.79l8.35,3.31c0.89,0.36,1.57,0.93,2.06,1.66c0.49,0.73,0.76,1.61,0.76,2.49
        c0,0.54-0.1,1.08-0.32,1.63v0l-68.59,173.32c-0.36,0.9-0.93,1.57-1.66,2.07c-0.73,0.49-1.61,0.76-2.48,0.76
        c-0.54,0-1.08-0.1-1.64-0.32l-78.12-30.91l-62.08-24.57l0,0c-0.89-0.36-1.56-0.92-2.06-1.66c-0.49-0.73-0.76-1.61-0.76-2.49
        c0-0.54,0.1-1.08,0.31-1.63l0,0l47.06-118.93L325.1,186.71L325.1,186.71z M28.27,237.23c-13.14,19.44-20.82,42.93-20.82,68.15
        v131.37c0,1.78,0.72,3.53,1.98,4.79c1.26,1.26,3.01,1.98,4.79,1.98h304.38c1.78,0,3.53-0.72,4.79-1.98
        c1.26-1.26,1.98-3.01,1.98-4.79v-30.1l68.85,27.25c2.16,0.85,4.41,1.27,6.62,1.27c3.6,0,7.07-1.08,10.01-3.04
        c2.94-1.96,5.35-4.83,6.74-8.35l68.59-173.32v0c0.85-2.16,1.27-4.41,1.27-6.62c0-3.6-1.08-7.07-3.04-10.01
        c-1.96-2.94-4.83-5.35-8.35-6.74l-2.06-0.81l1.9-4.8c1.17-2.94,1.73-6.01,1.72-9.02c0-4.89-1.47-9.62-4.13-13.63
        c-2.66-4-6.57-7.28-11.36-9.17l0,0l-90.5-35.81c-2.94-1.17-6.01-1.73-9.02-1.73c-4.89,0-9.62,1.47-13.63,4.13
        c-4,2.66-7.28,6.57-9.17,11.37l-1.9,4.8l-2.05-0.81c-2.16-0.85-4.42-1.27-6.63-1.27c-3.6,0-7.07,1.08-10.01,3.04
        c-2.94,1.96-5.35,4.84-6.74,8.35l-17.34,43.83l-4.27-4.76c-2.63-2.93-5.45-5.74-8.52-8.35c-10.62-9.02-22.82-16.26-36.12-21.24
        c-13.3-4.98-27.71-7.71-42.73-7.71h-3.29h-67.27h-3.61c-16.81,0-32.86,3.41-47.44,9.58C60.01,202.34,41.41,217.79,28.27,237.23
        L28.27,237.23z"></path>
                                                            <path d="M229.82,112.88c-5.22,12.34-13.95,22.86-24.94,30.28c-10.99,7.42-24.19,11.75-38.47,11.75c-9.52,0-18.55-1.93-26.78-5.4
        c-12.34-5.22-22.86-13.95-30.28-24.94c-7.42-10.99-11.75-24.19-11.75-38.47c0-9.52,1.93-18.55,5.4-26.78
        c5.22-12.34,13.95-22.86,24.94-30.28c10.99-7.42,24.19-11.75,38.47-11.75c9.52,0,18.55,1.93,26.78,5.4
        c12.34,5.22,22.86,13.95,30.28,24.94c7.42,10.99,11.75,24.19,11.75,38.47C235.23,95.61,233.3,104.65,229.82,112.88L229.82,112.88z
         M212.47,17.81c-13.14-8.88-29.02-14.07-46.05-14.07c-11.35,0-22.2,2.3-32.06,6.48c-14.79,6.26-27.35,16.69-36.23,29.83
        C89.25,53.18,84.06,69.06,84.06,86.1c0,11.35,2.3,22.2,6.48,32.06c6.26,14.79,16.69,27.35,29.83,36.23
        c13.14,8.88,29.02,14.07,46.05,14.07c11.35,0,22.2-2.3,32.06-6.48c14.79-6.26,27.35-16.69,36.23-29.83
        c8.88-13.14,14.07-29.02,14.07-46.05c0-11.35-2.3-22.2-6.48-32.06C236.05,39.25,225.61,26.68,212.47,17.81L212.47,17.81z"></path>
                                                            <path d="M360.41,183.78c3.85,1.52,8.19-0.36,9.71-4.2c1.52-3.85-0.36-8.2-4.21-9.71c-3.84-1.52-8.19,0.36-9.71,4.21
        C354.68,177.91,356.57,182.26,360.41,183.78L360.41,183.78z"></path>
                                                            <path d="M451.13,219.36c3.84,1.52,8.19-0.36,9.71-4.21c1.52-3.84-0.36-8.19-4.2-9.72c-3.85-1.52-8.2,0.37-9.72,4.21
        C445.39,213.49,447.28,217.83,451.13,219.36L451.13,219.36z"></path>
                                                        </g>
                                                    </svg>
                                                </figure>
                                                <h5>One Flat Fee Transparent Package</h5>
                                                <p>New to the Industry or looking for something better, Build a Business with our Support</p>


                                                <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                                    Start Now
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>
            {/* marketing solution start */}
            <section className="Marketing-solution-sec" id="marketing-sec">
                <Container>
                    <div className="Marketing-solution-content">
                        <div class="heading text-center"><h2><span>Proprietary</span> CRM Technology</h2></div>
                        <p className="sec-head-pera">
                            Generate leads, Qualify Customers and Earn Commissions in 3 Easy Steps
                        </p>
                    </div>
                    {/* <div className="owl-carousel owl-carousel-vertical owl-theme marketing-slider-1 owl-loaded owl-drag" id="marketing-slider-1"> */}
                    <OwlCarousel  {...options1} >
                        <div className="item">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-left">
                                        <figure className="cs-left-img">
                                            <img src={img5} className="img-fluid" />
                                        </figure>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-right">
                                        <div className="inner-head">
                                            <h3>EMAIL MARKETING THAT DRIVES SALES</h3>
                                        </div>
                                        <ul className="listing-ul">
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Unlimited Emails To Unlimited Contacts
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Promote and Connect with Newsletters
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    40+ Professional Email Templates
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Build a CASL Compliant Email List
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Keep Your Clients Posted on Rates
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="item">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-left">
                                        <figure className="cs-left-img">
                                            <img src={img6} className="img-fluid" />
                                        </figure>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-right">
                                        <div className="inner-head">
                                            <h3>BOOST YOUR SOCIAL MEDIA PRESENCE</h3>
                                        </div>
                                        <ul className="listing-ul">
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Publish Instantly or Schedule Posts
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Access Multiple Social Accounts on 1 Platform
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Engage Your Audience, Create a Following
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Connect with potential clients' on the go
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Prevail as a Subject Matter Expert
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="item">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-left">
                                        <figure className="cs-left-img">
                                            <img src={img7} className="img-fluid" />
                                        </figure>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                    <div className="marketing_card cs-right">
                                        <div className="inner-head">
                                            <h3>ONE WINDOW SOFTWARE TO DO IT ALL</h3>
                                        </div>
                                        <ul className="listing-ul">
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Customers, Contacts, Emails, Underwriting
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    CRA Docs Request, Support Center, Tools
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Resource Center, Commission and Compliance Tracker
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Text, Call, Email from One Window
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <span className="double-arrow">»</span>
                                                    Automate your Client Feedback and Reviews
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </OwlCarousel>
                </Container>
            </section>
            {/* marketing solution2 start */}
            <section className="Marketing-solution-sec Marketing-solution-2" id="tools-sec">
                <div className="marketing_2-inner-wrap">
                    <Container>
                        <div className="Marketing-solution-content">
                            <div class="heading text-center"><h2><span>Tools</span> that lead to success</h2></div>
                            <p className="sec-head-pera">
                                More Efficent, More Mortgages, More Money
                            </p>
                            <p className="text-center">Run your business easily from one centralized platform. Help clients sign and send documents, keep track of your finances, and monitor your business' performance.</p>
                        </div>
                        {/* <div className="owl-carousel owl-carousel-vertical owl-theme marketing-slider-1 owl-loaded owl-drag" id="marketing-slider-2"> */}
                        <OwlCarousel  {...options1} >
                            <div className="item">
                                <Grid container>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-left">
                                            <figure className="cs-left-img">
                                                <img src={img8} className="img-fluid" />
                                            </figure>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-right">
                                            <div className="inner-head">
                                                <h3>CRM JUST FOR MORTGAGE AGENTS</h3>
                                            </div>
                                            <ul className="listing-ul">
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Access all your Email, Website, Cellphone Inquiries
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Never Forget a Closing or a Customer's Birthday
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Evaluate, Follow-up and Convert in One Place
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Stay Connected with All Your Customers
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Work in Teams or Independently
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        See the Source for Every Lead
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="item">
                                <Grid container>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-left">
                                            <figure className="cs-left-img">
                                                <img src={img9} className="img-fluid" />
                                            </figure>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-right">
                                            <div className="inner-head">
                                                <h3>CRM JUST FOR MORTGAGE AGENTS</h3>
                                            </div>
                                            <ul className="listing-ul">
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Access all your Email, Website, Cellphone Inquiries
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Never Forget a Closing or a Customer's Birthday
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Evaluate, Follow-up and Convert in One Place
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Stay Connected with All Your Customers
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Work in Teams or Independently
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        See the Source for Every Lead
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="item">
                                <Grid container>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-left">
                                            <figure className="cs-left-img">
                                                <img src={img10} className="img-fluid" />
                                            </figure>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                                        <div className="marketing_card cs-right">
                                            <div className="inner-head">
                                                <h3>An Eco-system of Resources to deliver Deal Efficiency</h3>
                                            </div>
                                            <ul className="listing-ul">
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Access your website, profile, landing pages, leads
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Automate Mass Messaging and Emails
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Real time Customer Alerts from Application to Funding
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Identify new opportunities, track referrals &amp; deal progress
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Collaborate, Network and get instant Support
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="double-arrow">»</span>
                                                        Stay Connected to Information on your Fingertips
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </OwlCarousel>
                    </Container>
                </div>
            </section>

            {/* flat fees start */}
            < section className="flat-fee-sec" >
                <figure className="flat-fee-img-left">
                    <img src={img11} />
                </figure>
                <Container>
                    <Grid container>
                        <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                            <div className="flat-fee-card">
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={6} lg={6} item className="cs-p0">
                            <div className="flat-fee-card">
                                <div class="heading"><h2><span>ONE FLAT FEE.</span> EVERYTHING INCLUDED.</h2></div>
                                <p className="sec-head-pera">
                                    Because you should not have to pay for every breath you take:</p>
                                <div className="content-bottom">
                                    <p>
                                        E&amp;O INSURANCE | OFFICE/PRINT USE | UNDERWRITING HELP DESK | <br></br>
                                        24 EQUIFAX REPORTS | ONGOING TRAINING | SUPPORT CENTER | CRA DOC REQUEST | CRM &amp; WEBSITE | LENDER RESOURCE CENTER | PRIVATE LENDING HUB | VOLUME BROKERAGE RATES | COMMERCIAL MORTGAGE SPECIALISTS |
                                    </p>
                                    <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                        Join Us Today
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section >

            {/* social link start */}
            < PartnerLogo2 />
            {/* success start */}
            < section className="your-success-sec" id="about-us-sec" >
                <Container>
                    <div className="your-success-content">
                        <Grid container>
                            <Grid xs={12} sm={12} md={7} lg={8} item className="cs-p0">
                                <div className="your-success-card cs-left">
                                    <div class="heading"><h2><span>A Trusted Name</span> in Mortgages, <br></br> A Reliable Partner to Your Success</h2></div>

                                    <p>In 2016 we set out to differentiate ourselves by pioneering in technology and bringing a one stop solution to our clients. </p>
                                    <p>
                                        Led by ex-bankers and underwriters, RateShop grew to become a Volume Brokerage, while focusing on client service. We knew bringing value to financial advice was key to stand out from the competition. RateShop focused on building a network of lenders, offering quick and transparent service with a commitment to lowest rates every time.
                                    </p>
                                    <p>
                                        We converted this expertise into a formula for success, re-writing outdated industry norms, creating efficient processes and heavily investing in technology.
                                    </p>
                                    <p>
                                        Since inception,  a dozen awards and accolades later, notably CMP Top Independent Brokerage 2020, CMP Top Brokerage 2021, Readers Choice 2021 Diamond Winner – We are a proud team of 200+ Mortgage Advisors with one vision: To Deliver Excellence in Service. </p>
                                    <br></br>
                                    <p>
                                        <i>“I had a free hand in building the brokerage infrastructure from scratch, every possible instance of knowledge access point, underwriting support, administrative assistance, technological ability and expertise in training has been utilized to create a seamless experience for the Agent and Client, all that I wish I had when I entered the industry” – <strong>Ali Zaidi, Principal Broker</strong></i></p><br></br>
                                    <p><i>
                                        “We chose to be an independent brokerage so we can cut the bureaucratic red tape and quickly adapt to the market changes. We succeed when our agents succeed, our vision is very different from the big networks, we strive to be better and deliver every time.” – <strong>Michael Squeo, Director, BD</strong></i></p><br></br>

                                    <p><i>
                                        “Clients want to feel confident in your mortgage knowledge, product offering, so you can either spend 6 months doing it yourself or become an expert with our training in 6 weeks!” – <strong>Lisa Walker, Director Sales &amp; Training</strong> </i></p>
                                </div>
                            </Grid>

                            <Grid xs={12} sm={12} md={5} lg={4} item className="cs-p0">
                                <div className="your-success-card cs-right">
                                    <figure>
                                        <img src={img12} className="img-fluid" />
                                    </figure>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section >
            {/* testimonial start */}
            < section className="testimonial-sec" >
                <Container>
                    <div className="testimonial-content">
                        <div class="heading text-center"><h2><span>See What</span>  our Agents have to Say About Us!</h2></div>
                    </div>
                </Container>
                <OwlCarousel  {...options} >
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/_0mbYtG0Z0o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/EIJhmag6Fk8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/B8Y_bmAOs6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/_0mbYtG0Z0o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/EIJhmag6Fk8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/B8Y_bmAOs6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/_0mbYtG0Z0o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/EIJhmag6Fk8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonial-card">
                            <iframe width="100%" height="" src="https://www.youtube.com/embed/B8Y_bmAOs6c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </div>
                    </div>
                </OwlCarousel>
            </section >
            {/* with us start */}
            < section className="with-us-sec" >
                <Container>
                    <div className="with-us-content">
                        <div class="heading text-center"><h2><span>With Us</span>  You Will Get</h2></div>
                        <div className="top text-center">
                            <p>Business &amp; Marketing Training| | Access to Top Tier and Exclusive Lenders | Canada Wide Funding | <br></br>
                                A &amp; B Residential Lending Support | Commercial &amp; Private Mortgage Underwriting| 98% Approval Rate | <br></br>
                                New Location Setup Allowance | Real Time Deal Support| Referral Pay on RateShop.ca |</p>
                        </div>
                        <div className="text-center">
                            <Link to={`${window.location.origin}/join-us/packages${baseUrl}`} className="cs-btn MuiButton-contained MuiButton-containedPrimary">
                                Join Us Today
                            </Link>
                        </div>
                        <div className="cs-row-item">
                            <Grid container>
                                <Grid xs={12} sm={12} md={4} lg={4} item className="cs-p0">
                                    {/* <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-flex"> */}
                                    <div className="with-us-card">
                                        <div className="d-flex align-items-center">
                                            <figure>
                                                <img src={img13} className="img-fluid" />
                                            </figure>
                                            <h5>Our Philosphy</h5>
                                        </div>
                                        <hr />
                                        <p>We believe in making mortgages fair for the customer and the broker. Kickstart your career with our lead share plan, find more clients, close more deals - and make a lot more money. We equip you to perform with a 97% approval rate, 2 hour submission, the latest tools and expert training.</p>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={4} lg={4} item className="cs-p0">
                                    <div className="with-us-card">
                                        <div className="d-flex align-items-center">
                                            <figure>
                                                <img src={img14} className="img-fluid" />
                                            </figure>
                                            <h5>Our Training</h5>
                                        </div>
                                        <hr />
                                        <p>Advanced training programs prepare you for knowledgeable conversations with customers. Learn insights on underwriting rules and products and how to maximize your commission on each file. Get trained on marketing, customer acquisition and retention, and convert a lead in a customer for life.</p>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={4} lg={4} item className="cs-p0">
                                    <div className="with-us-card">
                                        <div className="d-flex align-items-center">
                                            <figure>
                                                <img src={img15} className="img-fluid" />
                                            </figure>
                                            <h5>Our Technology</h5>
                                        </div>
                                        <hr />
                                        <p>Our technology is a game changer. Use automated marketing tools to stay connected with customers. Work on multiple deals efficiently and deliver a seamless experience for each one. Lead generation tools, ongoing support, and transparent pay means you can grow your personal band and team.</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Container>
            </section >
        </>
    )
}

export default JoinUs;