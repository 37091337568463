import React from 'react';
import { Outlet } from "react-router-dom";  

const NotFoundHead=()=>{
    
     return (
    
        <> 
        <Outlet /> 
      </>
     )
}
  export default NotFoundHead 
  