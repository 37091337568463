import React from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";

const ApplicationAddresses = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const initialValues = {
    currently_live: "",
    unit: "",
    address_type: "",
    move_at: "",
    move_at2: "",
    residential: "",
    plans: "",
    previous_live: "",
    previous_unit: "",
    previous_address_type: "",
    previous_move_at: "",
    previous_move_at2: "",
    previous_residential: "",
    previous_plans: "",
  }

  const formSchema = Yup.object({
    currently_live: Yup.string().required("This is required field"),
    unit: Yup.string().required("This is required field"),
    address_type: Yup.string().required("This is required field"),
    move_at: Yup.string().required("This is required field"),
    move_at2:  Yup.string().required("This is required field"),
    residential: Yup.string().required("This is required field"),
    plans: Yup.string().when('residential', {is: 'owned', then: () => Yup.string().required("This is required field")}),
    previous_live: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_unit: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_address_type: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_move_at: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_move_at2: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_residential: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
    previous_plans: Yup.string().when('move_at2', {is: 'option2', then: () => Yup.string().required("This is required field")}),
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <form className="form-2" method="post" name="address" id="address"  onSubmit={handleSubmit}>
          <div className="mb-10 address-qstn">
            <h2>Addresses</h2>
            <p>Our lenders require your registered address(es) over the last 3 years.</p>
          </div>
          {JSON.stringify(errors)}
          <Grid container>
            <Grid xs={12} item>
              <FormControl>
                <FormLabel>Where do you currently live?</FormLabel>
                <Input
                  name="currently_live"
                  onBlur={handleBlur}
                  value={values.currently_live}
                  onChange={handleChange}
                  placeholder="Placeholder" />
                {touched.currently_live && <p>{errors.currently_live}</p>}
              </FormControl>
            </Grid>
            <Grid xs={12} md={4} item>
              <FormControl>
                <FormLabel>Unit</FormLabel>
                <Input name="unit"
                       onBlur={handleBlur}
                       value={values.unit}
                       onChange={handleChange}
                       placeholder="Placeholder" />
                {touched.unit && <p>{errors.unit}</p>}
              </FormControl>
            </Grid>
          </Grid>
          <div className="form-group mb-5">
            <h2>Address Type</h2>
            <div role="radiogroup" className="residential-form cstm-tick-radiogroup">
              <FormControl>
                <RadioGroup
                  overlay
                  orientation="vertical"
                  sx={{ gap: 2, mt: 1 }}
                >
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="address_type"
                      checked={values.address_type === 'home equity'}
                      onChange={handleChange}
                      value="home equity"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Current</Typography>
                  </Sheet>
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="address_type"
                      checked={values.address_type === 'private'}
                      onChange={handleChange}
                      value="private"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Previous</Typography>
                  </Sheet>
                </RadioGroup>
                {touched.address_type && <p>{errors.address_type}</p>}
              </FormControl>
            </div>
          </div>
          <div className="form-group mb-10">
            <h2>When did you move into this property?</h2>
            <Grid container>
              <Grid xs={12} md={8} item>
                <div className="birthdate tw-inputs   d-flex select-cstm-wrap subject-birth-date ">
                  <Select
                    name="move_at"
                    onClose={() => {setFieldTouched('move_at')}}
                    value={values.move_at}
                    onChange={(e, newValue) => {setFieldValue('move_at', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.move_at && <p>{errors.move_at}</p>}
                  <Select
                    name="move_at2"
                    onClose={() => {setFieldTouched('move_at2')}}
                    value={values.move_at2}
                    onChange={(e, newValue) => {setFieldValue('move_at2', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.move_at2 && <p>{errors.move_at2}</p>}
                </div>
              </Grid>
            </Grid>
            <p className="cs-error" id="timeYear"></p>
          </div>

          <div className="form-group mb-5">
            <h2>Residential situation</h2>
            <div role="radiogroup" className="residential-form ">
              <div className="cstm-tick-radiogroup">
                <FormControl>
                  <RadioGroup
                    overlay
                    orientation="vertical"
                    sx={{ gap: 2, mt: 1 }}
                  >
                    <Sheet
                      component="label"

                      variant="outlined"
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        bgcolor: 'background.body',
                        gap: 1.5,
                      }}
                    >
                      <Radio
                        name="residential"
                        checked={values.residential === 'owned'}
                        onChange={handleChange}
                        value="owned"
                        sx={{
                          mt: -1,
                          mr: -1,
                          mb: 0.5,
                          alignSelf: 'flex-end',
                          '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                        }}
                      />
                      <Typography level="body2">I own this property</Typography>
                    </Sheet>
                    <Sheet
                      component="label"

                      variant="outlined"
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        bgcolor: 'background.body',
                        gap: 1.5,
                      }}
                    >
                      <Radio
                        name="residential"
                        checked={values.residential === 'rent'}
                        onChange={handleChange}
                        value="rent"
                        sx={{
                          mt: -1,
                          mr: -1,
                          mb: 0.5,
                          alignSelf: 'flex-end',
                          '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                        }}
                      />
                      <Typography level="body2">I rent this property</Typography>
                    </Sheet>
                    <Sheet
                      component="label"

                      variant="outlined"
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        bgcolor: 'background.body',
                        gap: 1.5,
                      }}
                    >
                      <Radio
                        name="residential"
                        checked={values.residential === 'other'}
                        onChange={handleChange}
                        value="other"
                        sx={{
                          mt: -1,
                          mr: -1,
                          mb: 0.5,
                          alignSelf: 'flex-end',
                          '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                        }}
                      />
                      <Typography level="body2">Other (e.g. Live with spouse/parents)</Typography>
                    </Sheet>
                  </RadioGroup>
                  {touched.residential && <p>{errors.residential}</p>}
                </FormControl>
              </div>
              { values.residential === 'owned' && <div className="form-added1 e">
                <h2>What are your plans for this property?</h2>
                <div role="radiogroup" className="cstm-tick-radiogroup">
                  <FormControl>
                    <RadioGroup
                      overlay
                      orientation="vertical"
                      sx={{ gap: 2, mt: 1 }}
                    >
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="plans"
                          checked={values.plans === 'home equity'}
                          onChange={handleChange}
                          value="home equity"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Typography level="body2">Sell it</Typography>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="plans"
                          checked={values.plans === 'private'}
                          onChange={handleChange}
                          value="private"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Typography level="body2">Rent it</Typography>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="plans"
                          checked={values.plans === 'private 2nd'}
                          onChange={handleChange}
                          value="private 2nd"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Typography level="body2">Continue to occupy it</Typography>
                      </Sheet>
                    </RadioGroup>
                    {touched.plans && <p>{errors.plans}</p>}
                  </FormControl>
                </div>
              </div>}
            </div>
            <p className="cs-error" id="ownershipProperty"></p>
          </div>
          {values.move_at2 === 'option2' &&
            <>
              <div className="mb-10 address-qstn previousAddHeading  " >
                <h2>Previous Addresses</h2>
                <p>Our lenders require your registered address(es) over the last 3 years.</p>
              </div>
              <div className="row previousAddress " >
                <Grid xs={12} item>
                  <FormControl>
                    <FormLabel>Where do you currently live?</FormLabel>
                    <Input name="previous_live" onBlur={handleBlur} value={values.previous_live} onChange={handleChange} placeholder="Placeholder" />
                    {touched.previous_live && <p>{errors.previous_live}</p>}
                  </FormControl>
                  <div className="form-group">
                    <Grid container>
                      <Grid xs={12} md={4} item>
                        <FormControl>
                          <FormLabel>Unit</FormLabel>
                          <Input name="previous_unit" onBlur={handleBlur} value={values.previous_unit} onChange={handleChange} placeholder="Placeholder" />
                          {touched.previous_unit && <p>{errors.previous_unit}</p>}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group mb-5">
                    <h2>Address Type</h2>
                    <div role="radiogroup" className="residential-form cstm-tick-radiogroup">
                      <FormControl>
                        <RadioGroup
                          overlay
                          orientation="vertical"
                          sx={{ gap: 2, mt: 1 }}
                        >
                          <Sheet
                            component="label"

                            variant="outlined"
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              boxShadow: 'sm',
                              borderRadius: 'md',
                              bgcolor: 'background.body',
                              gap: 1.5,
                            }}
                          >
                            <Radio
                              name="previous_address_type"
                              checked={values.previous_address_type === 'home equity'}
                              onChange={handleChange}
                              value="home equity"
                              sx={{
                                mt: -1,
                                mr: -1,
                                mb: 0.5,
                                alignSelf: 'flex-end',
                                '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                              }}
                            />
                            <Typography level="body2">Current</Typography>
                          </Sheet>
                          <Sheet
                            component="label"

                            variant="outlined"
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              boxShadow: 'sm',
                              borderRadius: 'md',
                              bgcolor: 'background.body',
                              gap: 1.5,
                            }}
                          >
                            <Radio
                              name="previous_address_type"
                              checked={values.previous_address_type === 'private'}
                              onChange={handleChange}
                              value="private"
                              sx={{
                                mt: -1,
                                mr: -1,
                                mb: 0.5,
                                alignSelf: 'flex-end',
                                '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                              }}
                            />
                            <Typography level="body2">Previous</Typography>
                          </Sheet>
                        </RadioGroup>
                        {touched.previous_address_type && <p>{errors.previous_address_type}</p>}
                      </FormControl>
                    </div>
                    <p className="cs-error" id="addressTypePropertyPrevious"></p>
                  </div>
                  <div className="form-group mb-10">
                    <h2>When did you move into this property?</h2>
                    <Grid container>
                      <Grid xs={12} md={8} item>
                        <div className="birthdate tw-inputs  thre-inputs d-flex select-cstm-wrap subject-birth-date ">
                          <Select
                            name="previous_move_at"
                            onClose={() => {setFieldTouched('previous_move_at')}}
                            value={values.previous_move_at}
                            onChange={(e, newValue) => {setFieldValue('previous_move_at', newValue)}}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="option1">Option I</Option>
                            <Option value="option2">Option II</Option>
                          </Select>
                          {touched.previous_move_at && <p>{errors.previous_move_at}</p>}
                          <Select
                            name="previous_move_at2"
                            onClose={() => {setFieldTouched('previous_move_at2')}}
                            value={values.previous_move_at2}
                            onChange={(e, newValue) => {setFieldValue('previous_move_at2', newValue)}}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="option1">Option I</Option>
                            <Option value="option2">Option II</Option>
                          </Select>
                          {touched.previous_move_at2 && <p>{errors.previous_move_at2}</p>}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group mb-5">
                    <h2>Residential situation</h2>
                    <div role="radiogroup" className="residential-form cstm-tick-radiogroup">

                      <FormControl>
                        <RadioGroup
                          overlay
                          orientation="vertical"
                          sx={{ gap: 2, mt: 1 }}
                        >
                          <Sheet
                            component="label"

                            variant="outlined"
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              boxShadow: 'sm',
                              borderRadius: 'md',
                              bgcolor: 'background.body',
                              gap: 1.5,
                            }}
                          >
                            <Radio
                              name="previous_residential"
                              checked={values.previous_residential === 'home equity'}
                              onChange={handleChange}
                              value="home equity"
                              sx={{
                                mt: -1,
                                mr: -1,
                                mb: 0.5,
                                alignSelf: 'flex-end',
                                '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                              }}
                            />
                            <Typography level="body2">I own this property</Typography>
                          </Sheet>
                          <Sheet
                            component="label"

                            variant="outlined"
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              boxShadow: 'sm',
                              borderRadius: 'md',
                              bgcolor: 'background.body',
                              gap: 1.5,
                            }}
                          >
                            <Radio
                              name="previous_residential"
                              checked={values.previous_residential === 'private'}
                              onChange={handleChange}
                              value="private"
                              sx={{
                                mt: -1,
                                mr: -1,
                                mb: 0.5,
                                alignSelf: 'flex-end',
                                '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                              }}
                            />
                            <Typography level="body2">I rent this property</Typography>
                          </Sheet>
                          <Sheet
                            component="label"

                            variant="outlined"
                            sx={{
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                              boxShadow: 'sm',
                              borderRadius: 'md',
                              bgcolor: 'background.body',
                              gap: 1.5,
                            }}
                          >
                            <Radio
                              name="previous_residential"
                              checked={values.previous_residential === 'private1'}
                              onChange={handleChange}
                              value="private1"
                              sx={{
                                mt: -1,
                                mr: -1,
                                mb: 0.5,
                                alignSelf: 'flex-end',
                                '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                              }}
                            />
                            <Typography level="body2">Other (e.g. Live with spouse/parents)</Typography>
                          </Sheet>
                        </RadioGroup>
                        {touched.previous_residential && <p>{errors.previous_residential}</p>}
                      </FormControl>

                      <div className="form-addedPre1"  >
                        <h2>What are your plans for this property?</h2>
                        <div role="radiogroup" className="cstm-tick-radiogroup">
                          <FormControl>
                            <RadioGroup
                              overlay
                              orientation="vertical"
                              sx={{ gap: 2, mt: 1 }}
                            >
                              <Sheet
                                component="label"

                                variant="outlined"
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  boxShadow: 'sm',
                                  borderRadius: 'md',
                                  bgcolor: 'background.body',
                                  gap: 1.5,
                                }}
                              >
                                <Radio
                                  name="previous_plans"
                                  checked={values.previous_plans === 'home equity'}
                                  onChange={handleChange}
                                  value="home equity"
                                  sx={{
                                    mt: -1,
                                    mr: -1,
                                    mb: 0.5,
                                    alignSelf: 'flex-end',
                                    '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                  }}
                                />
                                <Typography level="body2">Sell it</Typography>
                              </Sheet>
                              <Sheet
                                component="label"

                                variant="outlined"
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  boxShadow: 'sm',
                                  borderRadius: 'md',
                                  bgcolor: 'background.body',
                                  gap: 1.5,
                                }}
                              >
                                <Radio
                                  name="previous_plans"
                                  checked={values.previous_plans === 'private'}
                                  onChange={handleChange}
                                  value="private"
                                  sx={{
                                    mt: -1,
                                    mr: -1,
                                    mb: 0.5,
                                    alignSelf: 'flex-end',
                                    '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                  }}
                                />
                                <Typography level="body2">Rent it</Typography>
                              </Sheet>
                              <Sheet
                                component="label"

                                variant="outlined"
                                sx={{
                                  p: 2,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'start',
                                  boxShadow: 'sm',
                                  borderRadius: 'md',
                                  bgcolor: 'background.body',
                                  gap: 1.5,
                                }}
                              >
                                <Radio
                                  name="previous_plans"
                                  checked={values.previous_plans === 'private1'}
                                  onChange={handleChange}
                                  value="private1"
                                  sx={{
                                    mt: -1,
                                    mr: -1,
                                    mb: 0.5,
                                    alignSelf: 'flex-end',
                                    '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                  }}
                                />
                                <Typography level="body2">Continue to occupy it</Typography>
                              </Sheet>
                            </RadioGroup>
                            {touched.previous_plans && <p>{errors.previous_plans}</p>}
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <p className="cs-error" id="ownershipPropertyPrevious"></p>
                  </div>
                </Grid>
              </div >
            </>
          }
          {/* Previous Address Fields */}
          < div className="button-grp d-flex align-items-start" >
            <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
              Previous
            </Button>
            <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ApplicationAddresses;
