import React, { useEffect, useState } from "react";
import AOS from 'aos';
import "aos/dist/aos.css";
import './sweaptake.scss';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import image6 from '../../../assets/public/sweepstake/marble.svg'
import image7 from '../../../assets/public/sweepstake/guarantee-certificate.png'
import image8 from '../../../assets/public/sweepstake/work-for-you.png'
import image9 from '../../../assets/public/sweepstake/home-served.svg'
import image10 from '../../../assets/public/sweepstake/bank.png'
import image11 from '../../../assets/public/sweepstake/top-2021.png'
import image12 from '../../../assets/public/sweepstake/mpc.png'
import image13 from '../../../assets/public/sweepstake/cmba.jpg'
import image14 from '../../../assets/public/sweepstake/mortgage_pro.jpg'
import image15 from '../../../assets/public/sweepstake/Readers.jpg'
import image16 from '../../../assets/public/sweepstake/top.png'
import image17 from '../../../assets/public/sweepstake/home.svg'
import image18 from '../../../assets/public/sweepstake/rent.svg'
import image19 from '../../../assets/public/sweepstake/agent.svg'
import axios from 'axios';

const Sweaptake = () => {
    const initialValues = { firstName: '', lastName: '', email: '', terms: '', phone: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const handelChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const handelSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

        }
    })
    const validate = (values) => {

        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'This field is required';
        }
        if (!values.lastName) {
            errors.lastName = 'This field is required';
        }
        if (!values.email) {
            errors.email = 'This field is required';
        }
        if (!values.phone) {
            errors.phone = 'This field is required';
        }
        if (!values.terms) {
            errors.terms = 'This field is required';
        }

        return errors;

    }
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    var options = {
        loop: true,
        margin: 24,
        dots: true,
        nav: false,
        autoHeight: true,
        responsive: {
            0: {
                items: 2,
            },
            768: {
                items: 3,
            },
            900: {
                items: 6,
            }
        }
    }
    var options1 = {
        loop: true,
        margin: 30,
        dots: false,
        nav: false,
        responsive: {
            0: {
                items: 1,
                dots: true,
            },
            768: {
                items: 1.5,
            },
            1200: {
                items: 2.5,
            }
        }
    }
    var options2 = {
        loop: true,
        margin: 24,
        dots: false,
        nav: true,
        items: 3,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    }
    const [agentslugs, setagentSlugs] = useState([]);

    const data = {
        type: 'unique',
        Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs'
    };
    useEffect(() => {
        axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
            .then(res => {
                // console.log(res.data.data);
                setagentSlugs(res.data.data);
            })

    }, [])

    return (
        <>
            <section className="sweepstake-banner" id="top">
                <div className="container">
                    <div className="banner-content">
                        <h1>It's time for giving back. Imagine what you could do with No Mortgage payments for a Whole THREE Months!</h1>
                        <p>Plus, <br></br>
                            -Get a Free No Obligation Mortgage Check up<br></br>
                            -Get Free Credit Monitoring with our Partners <img src={image6} alt="" /> <br></br>
                            -Access VIP Referral Partner APP &amp; Start Earning</p><br></br><br></br>
                        <a href="https://www.rateshop.ca/get-quote/michael-squeo" target="_blank" className="apply-text-btn">Apply Now</a>
                    </div>
                    <div className="banner-form">
                        <form onSubmit={handelSubmit} autoComplete="off">
                            <h2>Win 3 Monthly Mortgage Payments on Us!</h2>
                            <div className="form-inner">
                                <div className="form-group">
                                    <TextField id="standard-basic" onChange={handelChange} value={formValues.firstName} className="w-100" label="First Name" /> </div>
                                <p>{formErrors.firstName}</p>
                                <div className="form-group">
                                    <TextField id="standard-basic" onChange={handelChange} value={formValues.lastName} className="w-100" label="Last Name" /> </div>
                                <p>{formErrors.lastName}</p>
                                <div className="form-group">
                                    <TextField id="standard-basic" onChange={handelChange} value={formValues.phone} className="w-100" label="Phone" /> </div>
                                <p>{formErrors.phone}</p>
                                <div className="form-group">
                                    <TextField id="standard-basic" onChange={handelChange} value={formValues.email} className="w-100" label="Email" /> </div>
                                <p>{formErrors.email}</p>
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                // checked={state.checkedB}
                                                value={formValues.terms}
                                                onChange={handelChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="I agree to communication for the Sweepstakes draw, marketing and ongoing communication."
                                    />
                                </div>
                                <p>{formErrors.terms}</p>
                                <div className="form-btn">
                                    <input type="submit" name="submitLead" value="Submit Entry for 2023" />
                                </div>
                                <p className="info"><strong>Terms &amp; Conditions</strong>: Draw Details - For details on our sweepstakes content Terms, Conditions and Rules, please visit <a href="https://cloud.rateshop.ca/s/gQtgw5RtpG92xHQ">here</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="happy-customers">
                <div className="container">
                    <h2 className="cs-n-heading">We Get Mortgages Done!</h2>
                    <ul className="d-flex flex-wrap">
                        <li>
                            <div className="happy-customers-inner">
                                <figure>
                                    <img src={image7} alt="" />
                                </figure>
                                <h3><span>8,714</span>+</h3>
                                <p>Lowest Rates, Guaranteed Service</p>
                            </div>
                        </li>
                        <li>
                            <div className="happy-customers-inner">
                                <figure>
                                    <img src={image8} alt="" />
                                </figure>
                                <h3><span>8,714</span>+</h3>
                                <p>We Work for You, Not the Bank!</p>
                            </div>
                        </li>
                        <li>
                            <div className="happy-customers-inner">
                                <figure>
                                    <img src={image9} alt="" />
                                </figure>
                                <h3><span>8,714</span>+</h3>
                                <p>Commercial And Investor Property Experts</p>
                            </div>
                        </li>
                        <li>
                            <div className="happy-customers-inner">
                                <figure>
                                    <img src={image10} alt="" />
                                </figure>
                                <h3><span>8,714</span>+</h3>
                                <p>65+ Lenders, Banks and Credit Unions</p>
                            </div>
                        </li>
                    </ul>
                    <div className="Partner-icons-nw">
                        <ul className="d-flex align-items-center justify-content-center">
                            <li>
                                <figure>
                                    <img src={image11} alt="" />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={image12} alt="" />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={image13} alt="" />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={image14} alt="" />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={image15} alt="" />
                                </figure>
                            </li>
                            <li>
                                <figure>
                                    <img src={image16} alt="" />
                                </figure>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="buy-home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="buy-home-inner">
                                <figure>
                                    <img src={image17} alt="" />
                                </figure>
                                <h2>Purchase, Refinance and Renewal</h2>
                                <p>Offering the best rate options for All Income, Credit and Property Types</p>
                                <a href="javascript:void(0);" className="text-btn">More details</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="buy-home-inner">
                                <figure>
                                    <img src={image18} alt="" />
                                </figure>
                                <h2>Home Equity Loans Approved in 24 Hours</h2>
                                <p>Low Rates, Flexible Terms, No Penalty Options, No Stress Test Required</p>
                                <a href="javascript:void(0);" className="text-btn">More details</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="buy-home-inner">
                                <figure>
                                    <img src={image19} alt="" />
                                </figure>
                                <h2>Committed to your Success</h2>
                                <p>99% Funding Track Record, Top Rated Advisor</p>
                                <a href="javascript:void(0);" className="text-btn">More details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="expore-all">
                <div className="container text-center">
                    <h2>Looking for better Mortgage Options?</h2>
                    <p>Your bank or broker may only have access to select lenders. As experts, we know the criteria before we submit and work with several lenders across Canada. A solution for every situation</p>

                    <OwlCarousel className="lender-carousel owl-carousel owl-theme"
                        {...options}
                    >

                        <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-7.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-8.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-9.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-10.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-11.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-12.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-1.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-2.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-3.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-4.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-5.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-6.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-7.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-8.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-9.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-10.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-11.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-12.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-1.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-2.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-3.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-4.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-5.png" alt="" />
                            </figure>
                        </div> <div className="item">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/lender-6.png" alt="" />
                            </figure>
                        </div>

                    </OwlCarousel>
                </div>
            </section>
            <section className="why-choose">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="why-choose-inner">
                                <h2>List of lenders </h2>
                                <h3>Why choose us</h3>
                                <p>Shop several lenders with just 1 Credit Check<br></br>
                                    Nationwide Mortgage Approvals<br></br>
                                    No Rate Gimmicks, No Hidden Fees<br></br><br></br>
                                    Call your bank or broker, then call us. <br></br><br></br>
                                    Mortgage rates can differ lender to lender, always check in to make sure you are getting your best mortgage rate! <br></br>
                                    Get a Free No obligation Consult </p>

                                <a href="tel:+16476575455" className="blue-btn">Call: 6476575455</a>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="owl-wrapper">
                                <OwlCarousel className="explore-carousel owl-carousel owl-theme"
                                    {...options1}
                                >

                                    <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/chat.svg" alt="" />
                                            </figure>
                                            <h3>Ongoing Communication</h3>
                                            <p>The biggest complaint from so many clients that come to us is, lacking in communication. Our technology keeps you connected with us from Application to Funding with constant Text and Email updates. Working with us lets you speak with mortgage experts on your timelines. </p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/percentage.png" alt="" />
                                            </figure>
                                            <h3>Compare Mortgage Rates</h3>
                                            <p>Getting the best mortgage rates in Canada is on every homebuyer’s mind. A lower interest rate can save you thousands of dollars over your mortgage term, so getting anything less the best current mortgage rates in Canada could cost you a significant amount.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/agent.svg" alt="" />
                                            </figure>
                                            <h3>Self Employed Programs</h3>
                                            <p>When big banks hear self-employed, they think unstable, irregular income. When we hear self-employed, we think employed. Being your own boss is the last reason you should be denied a mortgage. If you're self-employed and need a mortgage, we can help.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/family.svg" alt="" />
                                            </figure>
                                            <h3>Private Options</h3>
                                            <p>As lenders scale back on exceptions, more rigourous qualification rules can leave you stranded. Get access to in-house private funds, Mortgage Investors and over 400 Mortgage Investment corps catering to 1st, 2nd, Constrcution Infill, Development and Land Mortgage Lenders.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/chat.svg" alt="" />
                                            </figure>
                                            <h3>Ongoing Communication</h3>
                                            <p>The biggest complaint from so many clients that come to us is, lacking in communication. Our technology keeps you connected with us from Application to Funding with constant Text and Email updates. Working with us lets you speak with mortgage experts on your timelines. </p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/percentage.png" alt="" />
                                            </figure>
                                            <h3>Compare Mortgage Rates</h3>
                                            <p>Getting the best mortgage rates in Canada is on every homebuyer’s mind. A lower interest rate can save you thousands of dollars over your mortgage term, so getting anything less the best current mortgage rates in Canada could cost you a significant amount.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/agent.svg" alt="" />
                                            </figure>
                                            <h3>Self Employed Programs</h3>
                                            <p>When big banks hear self-employed, they think unstable, irregular income. When we hear self-employed, we think employed. Being your own boss is the last reason you should be denied a mortgage. If you're self-employed and need a mortgage, we can help.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/family.svg" alt="" />
                                            </figure>
                                            <h3>Private Options</h3>
                                            <p>As lenders scale back on exceptions, more rigourous qualification rules can leave you stranded. Get access to in-house private funds, Mortgage Investors and over 400 Mortgage Investment corps catering to 1st, 2nd, Constrcution Infill, Development and Land Mortgage Lenders.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/chat.svg" alt="" />
                                            </figure>
                                            <h3>Ongoing Communication</h3>
                                            <p>The biggest complaint from so many clients that come to us is, lacking in communication. Our technology keeps you connected with us from Application to Funding with constant Text and Email updates. Working with us lets you speak with mortgage experts on your timelines. </p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/percentage.png" alt="" />
                                            </figure>
                                            <h3>Compare Mortgage Rates</h3>
                                            <p>Getting the best mortgage rates in Canada is on every homebuyer’s mind. A lower interest rate can save you thousands of dollars over your mortgage term, so getting anything less the best current mortgage rates in Canada could cost you a significant amount.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/agent.svg" alt="" />
                                            </figure>
                                            <h3>Self Employed Programs</h3>
                                            <p>When big banks hear self-employed, they think unstable, irregular income. When we hear self-employed, we think employed. Being your own boss is the last reason you should be denied a mortgage. If you're self-employed and need a mortgage, we can help.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/family.svg" alt="" />
                                            </figure>
                                            <h3>Private Options</h3>
                                            <p>As lenders scale back on exceptions, more rigourous qualification rules can leave you stranded. Get access to in-house private funds, Mortgage Investors and over 400 Mortgage Investment corps catering to 1st, 2nd, Constrcution Infill, Development and Land Mortgage Lenders.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/chat.svg" alt="" />
                                            </figure>
                                            <h3>Ongoing Communication</h3>
                                            <p>The biggest complaint from so many clients that come to us is, lacking in communication. Our technology keeps you connected with us from Application to Funding with constant Text and Email updates. Working with us lets you speak with mortgage experts on your timelines. </p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/percentage.png" alt="" />
                                            </figure>
                                            <h3>Compare Mortgage Rates</h3>
                                            <p>Getting the best mortgage rates in Canada is on every homebuyer’s mind. A lower interest rate can save you thousands of dollars over your mortgage term, so getting anything less the best current mortgage rates in Canada could cost you a significant amount.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/agent.svg" alt="" />
                                            </figure>
                                            <h3>Self Employed Programs</h3>
                                            <p>When big banks hear self-employed, they think unstable, irregular income. When we hear self-employed, we think employed. Being your own boss is the last reason you should be denied a mortgage. If you're self-employed and need a mortgage, we can help.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div> <div className="item">
                                        <div className="explore-carousel-inner blue">
                                            <figure>
                                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/family.svg" alt="" />
                                            </figure>
                                            <h3>Private Options</h3>
                                            <p>As lenders scale back on exceptions, more rigourous qualification rules can leave you stranded. Get access to in-house private funds, Mortgage Investors and over 400 Mortgage Investment corps catering to 1st, 2nd, Constrcution Infill, Development and Land Mortgage Lenders.</p>
                                            <a href="javascript:void(0);">Click Here...</a>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="podcast d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="podcast-inner">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/podcast-yellow.svg" alt="" />
                                </figure>
                                <h3><span>500</span>+</h3>
                                <p>Podcasts</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="podcast-inner">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/view-yellow.svg" alt="" />
                                </figure>
                                <h3><span>24</span>M</h3>
                                <p>Views</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="podcast-inner">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/subscriber-yellow.svg" alt="" />
                                </figure>
                                <h3><span>600</span>K</h3>
                                <p>Subscribers</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="podcast-inner">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/view2-yellow.svg" alt="" />
                                </figure>
                                <h3><span>24</span>M</h3>
                                <p>Views</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="how-it-works-here d-none">
                <div className="container">
                    <h2>How it Works</h2>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/podcast.svg" alt="" />
                            </figure>
                            <h3>500+ Podcast</h3>
                            <p>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. </p>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/views.svg" alt="" />
                            </figure>
                            <h3>24M Views</h3>
                            <p>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. </p>
                        </div>
                        <div className="col-md-4">
                            <figure>
                                <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/icons/subs.svg" alt="" />
                            </figure>
                            <h3>600k Subs</h3>
                            <p>Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="what-our-listener-say">
                <div className="container">
                    <div className="cs-heading">
                        <h2>What Our Clients say</h2>
                        <p>We strive every day to make our clients happy with excellent service, best rates and good mortgage advice!</p>
                    </div>

                    <OwlCarousel className="listener-carousel owl-carousel owl-theme"
                        {...options2}
                    >

                        <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Adam McInroy </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Sarah is a great mortgage broker. Her insight into the options available, timely responsiveness are incredibly comforting. Also her honesty, if it might be a challenging approval process, she's upfront about the possible challenges, but has a solution if plan A doesn't work out. Highly recommend Sarah. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Jessica Harness</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>As a first time home buyer, I couldn’t have had a better experience with Sarah. She was incredible patient, supportive through each and every step we took. It can be such a overwhelming process and regardless of the circumstances she was always a text or a phone call away. She was very professional and always followed up with every question we had. It may have taken longer than expected to find our dream home, but she was always the first one to remind us that the right house is out there. And to keep our hopes high, when it’s right it will happen. Finally, when everything starting to come together. We all celebrated and she was genuinely so happy and excited for our new journey to begin. I will recommend her to anyone a hundred and fifty percent. She is very knowledgeable, and we trusted her through each step. Thank you Sarah for everything!! We can’t express our appreciation enough for helping make our dream come true! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Dallas D </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>As first time home buyers, we were overwhelmed and full of questions. Sarah stuck with us throughout the process, always answered our questions quickly and in great detail. For anyone just starting or looking to re-finance, I highly recommend Sarah as she's knowledgeable, experienced and truly wants what's best for her clients. Thank you Sarah! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Sameh Noureldin</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Gabi is so professional with compassion, got my job done with multi offers, Then helped me to make the right decision. She was available for me when ever I needed her, by calls, texts, and in person meeting. Always following up with me and nicely reminded me for any missing documents. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Javorka Dobrasinovic</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Gabi is amazing when it comes to mortgages! She provided an amazing customer experience at the most stressful time in our lives. She guided us step by step through the whole process made it easy and effortless. She made sure that the mortgage we got is the right fit for our goals and our family!  If you want highly professional personalized service she is your person! Highly recommend her services </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Martin Polakiewicz </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> Couldn't be happier with the professionalism and service received from Gabi. We were first time homebuyers and Gabi walked us through the entire process in detail making it as s simple as possible. Our numerous questions were promptly answered and our minds were quickly put to ease whenever concerns came up. 100% will be reaching out to Gabi when requiring a new mortgage and would recommend Gabi to friends and family.</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">ioan mihali </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Gabi was a pleasure to say the least. Gabi helped resolve countless unexpected issues that arose throughout the process and did not give up on our file until it was resolved. Our family is very thankful and highly recommended. Multumesc foarte mult :)
                                </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Jennifer Bernard </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Raman has been such a pleasure working with! Such a well done job helping me to understand the ins and outs of becoming a first time home buyer, working closely with me to ensure I am satisfied and to get me the financing I needed. I highly recommend her expertise. On a more personal note, we built great rapport and though we never met in person, I feel she really cared about me as a client and feels like I have know her for years!! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Drea KS</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Raman was an absolute pleasure. She maintains a positive and professional atmosphere at all times, and is extremely supportive with such a personal life experience and information. She works in a timely manner, and was able to meet short deadlines as and when required at all times especially during unprecedented times.  Working with Raman was seamless, and I highly recommend Raman to anyone seeking a mortgage approval. Excellent work Raman, and thanks for all your hard work, trust and dedication!  It truly is appreciated. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">J. Legault </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Raman Saini is absolutely amazing.  She answers all our questions with patience and is very thorough so we understand.  We would absolutely recommend her for your mortgage needs - you will not be disappointed. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Mrs. Sukhwinder Hira</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Mr. Ali is best ,very knowledgeable and understanding. He knows what's best for you and help you in every phase of the mortgage.
                                    He made my mortgage possible with the knowledge he have.
                                    Very professional and experienced 😊
                                    Highly recommend for any of your mortgage services 👌 </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Elizabeth Barnett</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> The expertise, guidance and patience of the Rate Shop Team is impeccable. I have now had the pleasure of doing business with Ali twice and I look forward to future experiences. Ali takes all finances into consideration before recommending an excellent solution. He truly takes the 'stress' of off the client. Thank-you for all your teams hard work.</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Hermion James</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Preet &amp; Al has whole heartedly…
                                    Preet &amp; Al has whole heartedly committed and dedicated their time &amp; efforts in making sure my mortage was approved. Getting the best rates.Preet went over and beyond doing more than expected of her. She make sure everything was explained and answered question with transparency. My calls were answered and returned within 2 minutes. Preet supported me with making photo copies/printing of important documents for my file. It was important to my husband and I how warm, polite and patience they were to us. They gained us our trust securing our mortgages &amp; investments.<br></br>
                                    Ahh big Thank you preet &amp; Ali </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Andrea Katona-Smith </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Raman was an absolute pleasure. She maintains a positive and professional atmosphere at all times, and is extremely supportive with such a personal life experience and information. She works in a timely manner, and was able to meet short deadlines as and when required at all times especially during unprecedented times. I highly recommend Raman to anyone seeking a mortgage approval. Excellent work Raman! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Megan Mousavi</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> Chantel is nothing short of exceptional. I have never met someone who is as responsive, enthusiastic and relentlessly hardworking as Chantel. She helped me purchase my first home and I am very grateful for her guidance and professionalism during this stressful time! Chantel was able to find the exact rate, down payment and timeline I was requesting. I highly recommend her to all home buyers!</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Romit Patel</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>If you are First time Home buyer, this place is best for you. Will get very informative advices from team as well as they do plan for long term innings.
                                    I was a bit nervous during the process of mortgage because, my closing date was so close and I didn’t get any response from lender. But, broker called me and explained me there is no need to worry. Finally I got the key. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Shibin Sundar </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Rateshop is your ultimate destination when it comes to housing loans . Excellent service you can find in Ontario. At this moment I would like to thank RateShop , Mississauga for making my dream of desired home come true. RateShop made it possible for me which other leading banks could not. These guys are really smart and really knows what they are doing. I’m a very happy customer and I give a five star rating for this company </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Katie Matte </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> As first time home buyers, our experience with Sarah was nothing short of amazing. We had a less than 30 day closing and Sarah worked diligently to ensure it was a smooth and untroubled process. We always felt comfortable asking questions, no matter how silly they seemed. She was always keeping us updated and was always a short text or phone call away. Even with our short closing, we felt confident and reassured that our application was in Sarah's hands. We will continue to use Sarah's services for our future mortgage needs. Thank you Sarah for making our home owning dreams a reality!</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Delaney Evans</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Sarah was a great mortgage advisor. My Fiance and I had such a great experience with her and we are so happy we had the pleasure of working with her. She was quick to answer emails and always made time to have a phone conversation. Sarah truly cares about helping her clients receive the best rate! I would 100% recommend Sarah to anyone looking for their future mortgage inquiries </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Adam McInroy </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Sarah is a great mortgage broker. Her insight into the options available, timely responsiveness are incredibly comforting. Also her honesty, if it might be a challenging approval process, she's upfront about the possible challenges, but has a solution if plan A doesn't work out. Highly recommend Sarah. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Jessica Harness</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>As a first time home buyer, I couldn’t have had a better experience with Sarah. She was incredible patient, supportive through each and every step we took. It can be such a overwhelming process and regardless of the circumstances she was always a text or a phone call away. She was very professional and always followed up with every question we had. It may have taken longer than expected to find our dream home, but she was always the first one to remind us that the right house is out there. And to keep our hopes high, when it’s right it will happen. Finally, when everything starting to come together. We all celebrated and she was genuinely so happy and excited for our new journey to begin. I will recommend her to anyone a hundred and fifty percent. She is very knowledgeable, and we trusted her through each step. Thank you Sarah for everything!! We can’t express our appreciation enough for helping make our dream come true! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Dallas D </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>As first time home buyers, we were overwhelmed and full of questions. Sarah stuck with us throughout the process, always answered our questions quickly and in great detail. For anyone just starting or looking to re-finance, I highly recommend Sarah as she's knowledgeable, experienced and truly wants what's best for her clients. Thank you Sarah! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Sameh Noureldin</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Gabi is so professional with compassion, got my job done with multi offers, Then helped me to make the right decision. She was available for me when ever I needed her, by calls, texts, and in person meeting. Always following up with me and nicely reminded me for any missing documents. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Javorka Dobrasinovic</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Gabi is amazing when it comes to mortgages! She provided an amazing customer experience at the most stressful time in our lives. She guided us step by step through the whole process made it easy and effortless. She made sure that the mortgage we got is the right fit for our goals and our family!  If you want highly professional personalized service she is your person! Highly recommend her services </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Martin Polakiewicz </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> Couldn't be happier with the professionalism and service received from Gabi. We were first time homebuyers and Gabi walked us through the entire process in detail making it as s simple as possible. Our numerous questions were promptly answered and our minds were quickly put to ease whenever concerns came up. 100% will be reaching out to Gabi when requiring a new mortgage and would recommend Gabi to friends and family.</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">ioan mihali </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Gabi was a pleasure to say the least. Gabi helped resolve countless unexpected issues that arose throughout the process and did not give up on our file until it was resolved. Our family is very thankful and highly recommended. Multumesc foarte mult :)
                                </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Jennifer Bernard </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Raman has been such a pleasure working with! Such a well done job helping me to understand the ins and outs of becoming a first time home buyer, working closely with me to ensure I am satisfied and to get me the financing I needed. I highly recommend her expertise. On a more personal note, we built great rapport and though we never met in person, I feel she really cared about me as a client and feels like I have know her for years!! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Drea KS</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Raman was an absolute pleasure. She maintains a positive and professional atmosphere at all times, and is extremely supportive with such a personal life experience and information. She works in a timely manner, and was able to meet short deadlines as and when required at all times especially during unprecedented times.  Working with Raman was seamless, and I highly recommend Raman to anyone seeking a mortgage approval. Excellent work Raman, and thanks for all your hard work, trust and dedication!  It truly is appreciated. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">J. Legault </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Raman Saini is absolutely amazing.  She answers all our questions with patience and is very thorough so we understand.  We would absolutely recommend her for your mortgage needs - you will not be disappointed. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Mrs. Sukhwinder Hira</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Mr. Ali is best ,very knowledgeable and understanding. He knows what's best for you and help you in every phase of the mortgage.
                                    He made my mortgage possible with the knowledge he have.
                                    Very professional and experienced 😊
                                    Highly recommend for any of your mortgage services 👌 </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Elizabeth Barnett</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> The expertise, guidance and patience of the Rate Shop Team is impeccable. I have now had the pleasure of doing business with Ali twice and I look forward to future experiences. Ali takes all finances into consideration before recommending an excellent solution. He truly takes the 'stress' of off the client. Thank-you for all your teams hard work.</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Hermion James</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Preet &amp; Al has whole heartedly…
                                    Preet &amp; Al has whole heartedly committed and dedicated their time &amp; efforts in making sure my mortage was approved. Getting the best rates.Preet went over and beyond doing more than expected of her. She make sure everything was explained and answered question with transparency. My calls were answered and returned within 2 minutes. Preet supported me with making photo copies/printing of important documents for my file. It was important to my husband and I how warm, polite and patience they were to us. They gained us our trust securing our mortgages &amp; investments.<br></br>
                                    Ahh big Thank you preet &amp; Ali </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Andrea Katona-Smith </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Working with Raman was an absolute pleasure. She maintains a positive and professional atmosphere at all times, and is extremely supportive with such a personal life experience and information. She works in a timely manner, and was able to meet short deadlines as and when required at all times especially during unprecedented times. I highly recommend Raman to anyone seeking a mortgage approval. Excellent work Raman! </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Megan Mousavi</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> Chantel is nothing short of exceptional. I have never met someone who is as responsive, enthusiastic and relentlessly hardworking as Chantel. She helped me purchase my first home and I am very grateful for her guidance and professionalism during this stressful time! Chantel was able to find the exact rate, down payment and timeline I was requesting. I highly recommend her to all home buyers!</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Romit Patel</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>If you are First time Home buyer, this place is best for you. Will get very informative advices from team as well as they do plan for long term innings.
                                    I was a bit nervous during the process of mortgage because, my closing date was so close and I didn’t get any response from lender. But, broker called me and explained me there is no need to worry. Finally I got the key. </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Shibin Sundar </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Rateshop is your ultimate destination when it comes to housing loans . Excellent service you can find in Ontario. At this moment I would like to thank RateShop , Mississauga for making my dream of desired home come true. RateShop made it possible for me which other leading banks could not. These guys are really smart and really knows what they are doing. I’m a very happy customer and I give a five star rating for this company </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Katie Matte </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p> As first time home buyers, our experience with Sarah was nothing short of amazing. We had a less than 30 day closing and Sarah worked diligently to ensure it was a smooth and untroubled process. We always felt comfortable asking questions, no matter how silly they seemed. She was always keeping us updated and was always a short text or phone call away. Even with our short closing, we felt confident and reassured that our application was in Sarah's hands. We will continue to use Sarah's services for our future mortgage needs. Thank you Sarah for making our home owning dreams a reality!</p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name"> Delaney Evans</h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Sarah was a great mortgage advisor. My Fiance and I had such a great experience with her and we are so happy we had the pleasure of working with her. She was quick to answer emails and always made time to have a phone conversation. Sarah truly cares about helping her clients receive the best rate! I would 100% recommend Sarah to anyone looking for their future mortgage inquiries </p>
                            </div>
                        </div> <div className="item">
                            <div className="listener-inner">
                                <h4 className="listener-name">Adam McInroy </h4>
                                <ul className="ratings d-flex align-items-center">
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                    <li><i className="fa-solid fa-star"></i></li>
                                </ul>
                                <p>Sarah is a great mortgage broker. Her insight into the options available, timely responsiveness are incredibly comforting. Also her honesty, if it might be a challenging approval process, she's upfront about the possible challenges, but has a solution if plan A doesn't work out. Highly recommend Sarah. </p>
                            </div>
                        </div>
                    </OwlCarousel>
                    <div className="row review-bottom-images">

                        <div className="col-md-6">
                            <a href="#top">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/image-2.png" alt="" />
                                </figure>
                                </a>
                        </div>
                        <div className="col-md-6">
                            <a href="#top">
                                <figure>
                                    <img src="https://www.rateshop.ca/themes/frontend/sweepstake/images/image-3.png" alt="" />
                                </figure>
                                </a>
                        </div>
                    </div>
                    <div className="text-center">
                        <a href="javascript:void(0);" className="blue-btn">See all</a>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Sweaptake