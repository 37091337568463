import React from 'react';
import { Outlet } from "react-router-dom"; 
import Header from '../components/sweaptake/header/Header'
import Footer from '../components/sweaptake/footer/Footer' 

const SweaptakeHead=()=>{
    
     return (
    
        <>
        <Header /> 
        <Outlet />
        <Footer/>
      </>
     )
}
  export default SweaptakeHead 
  