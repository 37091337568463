import React, { useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import {  
    useNavigate, NavLink } from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import {
    renewing,
    hoursapproval,
}
from "../../../../core/Images";
import axios from 'axios';
import {useParams} from 'react-router-dom';

const Sitemap = () => {
    const [agentsData, setAgentsData] = React.useState({});
    const data = {
      type: 'full',
      Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiQWJoaXNoZWsgUHVyaSIsIm5hbWUiOiJSZWFjdCBSUyIsInBhc3N3b3JkIjpudWxsLCJBUElfVElNRSI6MTY3NjY0MjQyNn0.kcYNItsCPFOtwPIgffGx1Zf-BGDN_Vsx-r0vnGcucOs',
    };
    const navigate = useNavigate();
    useEffect(() => {
 
            axios.post(`https://crm.rateshop.ca/ReactController/getStaffSlugs`, { data })
                .then(res => {
                // console.log('<url>'+
                // '<loc>https://www.rateshop.ca/fizza-fawad/mortgage-rates/</loc>'+
                // '<lastmod>2023-06-08T16:23:11+01:00</lastmod>'+
                // '<priority>0.6</priority>'+
                // '</url>');
                res.data.data.map((item) =>
                    console.log('<url>'+
                    '<loc>https://www.rateshop.ca/'+item.Slug+'/alberta/fort-mcMurray/</loc>'+
                    '<lastmod>2023-06-08T16:23:11+01:00</lastmod>'+
                    '<priority>0.6</priority>'+
                    '</url>')
                )
                setAgentsData(res.data.data);
                if(res.data.status != 200){
                    navigate('/404', { replace: true });
                }
            })
      }, []);
      if ((Object.keys(agentsData).length === 0 && agentsData.constructor === Object) ) {
        return '';
    }
    return (<>
        <section className='com-bg-banner mortgage-banner'>
            <Container>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} item  className="cs-p0">
                        {agentsData.map((item) =>
                            <p>{`/agent/${item.Slug}`}</p>
                        )}
                    </Grid>
                    
                </Grid>
            </Container>
        </section>
    </>
    )
}

export default Sitemap