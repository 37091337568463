import React from 'react'
import "./popup-common.scss";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'; 
import DialogContent from '@mui/material/DialogContent'; 
import Slide from '@mui/material/Slide'; 
import { NavLink } from 'react-router-dom';
// images
import logo from '../../assets/public/sweepstake/logo.png';
import marble from '../../assets/public/images/landing-popup/marble.png';
import img1 from '../../assets/public/images/landing-popup/img-1.png';
import img2 from '../../assets/public/images/landing-popup/img-2.png';
import img3 from '../../assets/public/images/landing-popup/img-3.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ApplyLanding = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (<>

        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Popup
        </Button>
        <Dialog
            open={open}  fullWidth="fullWidth" maxWidth="md" 
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className="modal-dialog landing-popup" >

            <DialogContent dividers className='dialog-body'>
                <div className="rateshop-marble">
                    <div className="rm-top-logo">
                        <NavLink to='/'>
                            <img src={logo} alt="" />
                        </NavLink>
                        <NavLink to='/'>
                            <img src={marble} alt="" />
                        </NavLink>
                    </div>
                    <div className="rm-content">
                        <h3>Before you go here is sweet deal...</h3>
                        <ul>
                            <li>Get 100% Free Access</li>
                            <li>Credit reporting and Monitoring</li>
                            <li>With MyMable Powered by TransUnion</li>
                            <li>Plus Get a Free Mortgage Quote!</li>
                        </ul>
                    </div>
                    <div className="rm-options">
                        <div className="rm-options-outer">
                            <figure>
                                <img src={img1} alt="" />
                                <figcaption>Improve Credit Score  &amp; Manage Debt</figcaption>
                            </figure>
                        </div>
                        <div className="rm-options-outer">
                            <figure>
                                <img src={img2} alt="" />
                                <figcaption>Personalized Financial Guidance</figcaption>
                            </figure>
                        </div>
                        <div className="rm-options-outer">
                            <figure>
                                <img src={img3} alt="" />
                                <figcaption>AI-powered Technology</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className="rm-buttons"> 
                        <Button variant="contained" color="secondary">Get Unlimited Credit Monitoring</Button>
                        <Button variant="contained" color="secondary"  onClick={handleClose}>I Don't Want Free Credit Monitoring</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    </>)
}

export default ApplyLanding;