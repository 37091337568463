import React from "react";
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { array, object, string } from 'yup';

import { FieldArray, Form, Formik } from "formik";
import Typography from "@mui/joy/Typography";

const ApplicationLiabilities = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const emptyLiabilities = {liabilities_type: '', amount: ''}
  const initialValues = {
    liabilities: [emptyLiabilities]
  }
  const formSchema = object({
    liabilities: array(
      object({
        liabilities_type: string()
          .required('This is required field'),
        amount: string()
          .required('This is required field')
          .min(1, 'Amount needs to be at least 1')
      })
    )
      .min(1, 'You need to provide at least 1 liability')
  });

  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldTouched}) => (
            <Form autoComplete="off" className="form-2" method="post" name="liabilities" id="liabilities" >
              <FieldArray name="liabilities">
                {({ push, remove }) => (
                  <>
                    <div className="mb-10 address-qstn">
                      <h2>Where is your liabilities coming from?</h2>

                      {values.liabilities.map((_, index) => (
                        <div key={index}>
                          <div className="payment-box">
                            <div className="asset">
                              <h3>Add Liabilities</h3>
                              <div className="form-group">
                                <Select
                                  name={`liabilities.${index}.liabilities_type`}
                                  onClose={() => {setFieldTouched(`liabilities.${index}.liabilities_type`)}}
                                  value={_.liabilities_type}
                                  onChange={(e, newValue) => {setFieldValue(`liabilities.${index}.liabilities_type`, newValue)}}
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Choose an liabilities type</Option>
                                  <Option value="credit card">Credit Card</Option>
                                  <Option value="loan">Loan</Option>
                                  <Option value="auto loan">Auto Loan</Option>
                                  <Option value="personal loan">Personal Loan</Option>
                                  <Option value="student loan">Student Loan</Option>
                                  <Option value="line of credit">Line Of Credit</Option>
                                  <Option value="other">Other</Option>
                                </Select>
                                {(touched?.liabilities && touched?.liabilities[index] && touched?.liabilities[index].liabilities_type && errors?.liabilities && errors?.liabilities[index] && errors?.liabilities[index].liabilities_type) &&
                                  <Typography color="danger">
                                    {errors?.liabilities[index].liabilities_type}
                                  </Typography>
                                }
                              </div>
                            </div>
                            <div className="add-liabilities step-1">
                              <div className="form-group  ">
                                <Input name={`liabilities.${index}.amount`}
                                       onBlur={handleBlur}
                                       value={_.amount}
                                       onChange={handleChange}
                                       placeholder="0" startDecorator="$" />
                                {(touched?.liabilities && touched?.liabilities[index] && touched?.liabilities[index].amount && errors?.liabilities && errors?.liabilities[index] && errors?.liabilities[index].amount) &&
                                  <Typography color="danger">
                                    {errors?.liabilities[index].amount}
                                  </Typography>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mb-10 address-qstn moreLiabilities"></div>
                    <p className="cs-error" id="instAmt"></p>
                    <div className="button-grp d-flex align-items-start">
                      <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
                        Previous
                      </Button>
                      <button type="button" className="prev btn step-1 addLiabilities" onClick={() => push(emptyLiabilities)}>Add more liabilities +</button>
                      <Button  type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
                        Finished with Liabilities
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ApplicationLiabilities;
