import React from 'react';

const CreditCardSvg = () => {

	return (

		<>
			<svg version="1.1" id="Layer_1" x="0px" y="0px"
				viewBox="0 0 2000 1368.78" >
				<g>
					<path className="st0" d="M1665.33,1351.19c76.99,0,139.62-64.78,139.62-144.42v-41.82h41.09c76.98,0,139.61-64.78,139.61-144.42V165.22
		c0-79.63-62.63-144.42-139.61-144.42H334.67c-76.99,0-139.62,64.78-139.62,144.42v41.82h-41.09
		c-76.98,0-139.61,64.78-139.61,144.42v102.72v183.86v568.72c0,79.63,62.63,144.42,139.61,144.42H1665.33z M90.01,351.46
		c0-37.91,28.69-68.75,63.95-68.75h41.09h37.83h37.83h1394.61c35.26,0,63.96,30.84,63.96,68.75v64.89H270.72h-37.83h-37.83H90.01
		V351.46z M270.72,165.22c0-37.91,28.69-68.75,63.96-68.75h1511.37c35.26,0,63.95,30.84,63.95,68.75v855.31
		c0,37.91-28.69,68.75-63.95,68.75h-41.09V638.05V454.19V351.46c0-79.63-62.63-144.42-139.62-144.42H270.72V165.22z M1729.28,600.22
		H270.72h-37.83h-37.83H90.01v-108.2h105.04h37.83h37.83h1458.57V600.22z M90.01,675.88h105.04h37.83h37.83h1458.57v413.41v37.83
		v37.83v41.82c0,37.91-28.69,68.76-63.96,68.76H153.96c-35.26,0-63.95-30.85-63.95-68.76V675.88z"/>
					<path className="st0" d="M1152.97,1127.12c22.14,18.42,48.73,31.64,77.9,37.83c12.12,2.57,24.68,3.95,37.56,3.95
		c12.88,0,25.44-1.38,37.56-3.95c19.1-4.05,37.08-11.14,53.44-20.72c16.36,9.57,34.34,16.67,53.44,20.72
		c12.12,2.57,24.68,3.95,37.56,3.95c12.88,0,25.44-1.38,37.56-3.95c29.17-6.19,55.76-19.41,77.9-37.83
		c13.13-10.92,24.7-23.66,34.31-37.83c19.6-28.91,31.06-63.75,31.06-101.23c0-99.72-81.12-180.84-180.84-180.84
		c-33.17,0-64.26,9.02-91.01,24.67c-26.75-15.65-57.84-24.67-91.01-24.67c-99.72,0-180.84,81.13-180.84,180.84
		c0,37.48,11.46,72.32,31.06,101.23C1128.26,1103.46,1139.83,1116.2,1152.97,1127.12z M1300.06,887.76
		c-19.22,28.72-30.46,63.22-30.46,100.3c0,37.08,11.23,71.58,30.46,100.3c-1.13,0.36-2.29,0.61-3.43,0.93
		c-8.99,2.51-18.42,3.95-28.19,3.95s-19.2-1.44-28.19-3.95c-44.33-12.37-76.99-53.01-76.99-101.23c0-58,47.18-105.18,105.18-105.18
		C1279.45,882.88,1290.07,884.6,1300.06,887.76z M1555.63,988.06c0,48.22-32.66,88.86-76.99,101.23
		c-8.99,2.51-18.42,3.95-28.19,3.95c-9.78,0-19.2-1.44-28.19-3.95c-1.14-0.32-2.31-0.57-3.44-0.93
		c-12.32-3.89-23.63-10.05-33.54-17.9c-10.39-8.23-19.16-18.38-25.84-29.91c-8.96-15.47-14.17-33.37-14.17-52.5
		c0-19.13,5.22-37.02,14.17-52.5c6.68-11.53,15.45-21.67,25.84-29.91c9.91-7.85,21.22-14,33.54-17.9
		c9.99-3.16,20.61-4.88,31.63-4.88C1508.45,882.88,1555.63,930.06,1555.63,988.06z"/>
					<polygon className="st0" points="466.42,882.88 466.42,845.05 466.42,807.22 270.72,807.22 238.8,807.22 238.8,845.05 238.8,882.88 
		270.72,882.88 	"/>
					<polygon className="st0" points="794.56,1025.89 794.56,950.23 270.72,950.23 238.8,950.23 238.8,988.06 238.8,1025.89 270.97,1025.89 
			"/>
					<polygon className="st0" points="723.61,1164.95 723.61,1131.07 723.61,1127.12 723.61,1093.24 260.39,1093.24 238.8,1093.24 
		238.8,1125.38 238.8,1131.07 238.8,1168.9 723.61,1168.9 	"/>
				</g>
			</svg>
		</>
	)

}

export default CreditCardSvg