import React from 'react';

const InvestingSvg = () => {

    return (

        <>
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 1753.04 2000" >

                <g id="loan_1_">
                    <g>
                        <g>
                            <path className="st0" d="M1388,962.79h-281.07c-10.84,0-21.71-1.19-32.29-3.54l-263.23-58.5H659.04c-13.69,0-24.79-11.1-24.79-24.79
				c0-13.69,11.1-24.79,24.79-24.79h155.1c1.81,0,3.61,0.2,5.38,0.59l265.88,59.08c7.06,1.57,14.31,2.37,21.54,2.37H1388
				c29.09,0,52.76-23.67,52.76-52.76s-23.67-52.76-52.76-52.76c-13.69,0-24.79-11.1-24.79-24.79c0-13.69,11.1-24.79,24.79-24.79
				h62.04c29.09,0,52.76-23.67,52.76-52.76s-23.67-52.76-52.76-52.76h-46.53c-13.69,0-24.79-11.1-24.79-24.79
				c0-13.69,11.1-24.79,24.79-24.79h77.55c29.09,0,52.76-23.67,52.76-52.76s-23.67-52.76-52.76-52.76h-62.04
				c-13.69,0-24.79-11.1-24.79-24.79c0-13.69,11.1-24.79,24.79-24.79h31.02c29.09,0,52.76-23.67,52.76-52.76
				c0-29.09-23.67-52.76-52.76-52.76h-356.73c-13.69,0-24.79-11.1-24.79-24.79c0-13.69,11.1-24.79,24.79-24.79h356.73
				c56.43,0,102.33,45.91,102.33,102.33c0,24.57-8.7,47.14-23.19,64.8c32.23,17.25,54.21,51.25,54.21,90.3s-21.98,73.05-54.21,90.3
				c14.48,17.66,23.19,40.23,23.19,64.8c0,48.22-33.52,88.75-78.48,99.53c10.4,16.01,16.44,35.1,16.44,55.57
				C1490.33,916.88,1444.43,962.79,1388,962.79z"/>
                        </g>
                        <g>
                            <path className="st0" d="M695.38,404.43h-67.36c-13.69,0-24.79-11.1-24.79-24.79c0-13.69,11.1-24.79,24.79-24.79h67.36
				c9.95,0,19.31-3.87,26.34-10.91L863.1,202.57c20.85-20.85,47.15-34.93,76.07-40.71l118.26-23.65
				c13.44-2.67,26.48,6.02,29.17,19.44c2.68,13.42-6.02,26.48-19.44,29.16l-118.26,23.65c-19.29,3.86-36.84,13.25-50.74,27.16
				L756.77,379C740.37,395.4,718.57,404.43,695.38,404.43z"/>
                        </g>
                        <g>
                            <path className="st0" d="M628.02,993.81h-310.2c-13.69,0-24.79-11.1-24.79-24.79s11.1-24.79,24.79-24.79h285.41V311.37H38.64
				c-13.69,0-24.79-11.1-24.79-24.79c0-13.69,11.1-24.79,24.79-24.79h589.37c13.69,0,24.79,11.1,24.79,24.79v682.43
				C652.8,982.71,641.71,993.81,628.02,993.81z"/>
                        </g>
                        <g>
                            <path className="st0" d="M457.41,869.73c-39.32,0-71.31-31.99-71.31-71.32c0-39.32,31.99-71.31,71.31-71.31s71.31,31.99,71.31,71.31
				C528.72,837.74,496.73,869.73,457.41,869.73z M457.41,776.67c-11.99,0-21.74,9.75-21.74,21.74c0,11.99,9.75,21.74,21.74,21.74
				c11.99,0,21.74-9.75,21.74-21.74C479.15,786.42,469.4,776.67,457.41,776.67z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path className="st0" d="M1310.45,342.39h-186.12c-11.62,0-21.68-8.07-24.19-19.41L1038.1,43.81c-1.63-7.34,0.16-15.02,4.86-20.88
				c4.7-5.86,11.82-9.28,19.33-9.28h155.1c4.89,0,9.68,1.45,13.75,4.16c72.87,48.58,118.98,7.74,124,2.92
				c7.79-7.79,19.71-9.41,29.37-4.11c9.66,5.3,14.57,16.43,12.18,27.19l-62.04,279.18C1332.13,334.32,1322.07,342.39,1310.45,342.39
				z M1144.21,292.82h146.35l46.05-207.26c-33.46,9.27-77.59,7.95-126.51-22.35h-116.92L1144.21,292.82z"/>
                        </g>
                        <g>
                            <path className="st0" d="M1186.37,1986.43c-179.81,0-319.64-45.97-415.6-136.63c-89.31-84.37-136.52-206.6-136.52-353.45
				c0-97.33,44.6-205.56,132.56-321.69c61.96-81.8,145.52-167.77,248.38-255.51c10.41-8.88,26.05-7.65,34.94,2.77
				c8.89,10.41,7.65,26.06-2.77,34.94c-237.83,202.89-363.54,389.44-363.54,539.49c0,284.07,178.48,440.51,502.55,440.51
				c324.07,0,502.55-156.44,502.55-440.51c0-182.04-171.92-398.17-332.8-539.74c-10.28-9.04-11.28-24.7-2.23-34.98
				c9.04-10.28,24.7-11.28,34.98-2.24c82.02,72.17,349.63,327.07,349.63,576.96c0,146.86-47.21,269.08-136.52,353.45
				C1506.01,1940.47,1366.18,1986.43,1186.37,1986.43z"/>
                        </g>
                        <g>
                            <g>
                                <path className="st0" d="M1217.39,1645.22h-62.04c-3.16,0-77.79-0.86-115.23-75.74c-6.12-12.24-1.16-27.13,11.08-33.25
					c12.24-6.13,27.13-1.16,33.25,11.08c23.46,46.92,69.18,48.31,71.11,48.34h61.82c37.64,0,68.27-30.63,68.27-68.27
					s-30.63-68.27-68.27-68.27h-62.04c-64.98,0-117.84-52.87-117.84-117.84s52.87-117.84,117.84-117.84h62.04
					c3.16,0,77.79,0.86,115.23,75.74c6.12,12.24,1.16,27.13-11.08,33.25c-12.25,6.12-27.14,1.16-33.25-11.08
					c-23.46-46.92-69.18-48.31-71.11-48.34h-61.82c-37.64,0-68.27,30.63-68.27,68.27s30.63,68.27,68.27,68.27h62.04
					c64.98,0,117.84,52.86,117.84,117.84S1282.37,1645.22,1217.39,1645.22z"/>
                            </g>
                            <g>
                                <path className="st0" d="M1186.37,1272.98c-13.69,0-24.79-11.1-24.79-24.79v-62.04c0-13.69,11.1-24.79,24.79-24.79
					s24.79,11.1,24.79,24.79v62.04C1211.16,1261.89,1200.06,1272.98,1186.37,1272.98z"/>
                            </g>
                            <g>
                                <path className="st0" d="M1186.37,1707.26c-13.69,0-24.79-11.1-24.79-24.79v-62.04c0-13.69,11.1-24.79,24.79-24.79
					s24.79,11.1,24.79,24.79v62.04C1211.16,1696.16,1200.06,1707.26,1186.37,1707.26z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </>


    )

}

export default InvestingSvg      