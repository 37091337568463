import { Container } from '@mui/material'
import React from 'react'
import './footer.scss'
import image2 from '../../../assets/public/sweepstake/linkedin.svg'
import image3 from '../../../assets/public/sweepstake/facebook.svg'
import image4 from '../../../assets/public/sweepstake/twitter.svg'
import image5 from '../../../assets/public/sweepstake/logo.png'
const Footer = () => {

    return (

        <footer className='sweepstake-footer'>
            <Container>
                <div className="row">
                    <div className="col-md-7">
                        <div className="footer-details">
                            <ul className="d-flex flex-wrap">
                                <li>
                                    <p>Phone
                                        <a href="tel:+16476575455"><i className="fa-solid fa-phone"></i> 6476575455</a>
                                    </p>
                                </li>
                                <li>
                                    <p>Email
                                        <a href="mailto:michael@rateshop.ca"><i className="fa-solid fa-envelope"></i> michael@rateshop.ca</a>
                                    </p>
                                </li>
                                <li>
                                    <p>Address
                                        <a href="javascript:void(0);"><i className="fa-solid fa-address-book"></i> 734 Broadway, Floor 5 New York, NY 10003</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Follow us
                                    </p>
                                    <ul className="social-icons">
                                        <li>
                                            <a href="#">
                                                <img src={image2} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src={image3} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src={image4} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <a className="footer-logo" href="javascript:void(0);">
                            <img src={image5} alt="" />
                        </a>
                        <div className="footer-text">
                            <p>RateShop Inc. is a Canada Wide Licensed mortgage brokerage with 65+ Lenders, banks, monolines, credit unions and trust companies on their roster to compare lowest mortgage rates and find the best mortgage options. </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="copyright-text">
                            <p>© Apartb. All Rights Reserved 2022   -   Designed by 128.digital. Powered by Webflow</p>
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer