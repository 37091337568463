import React from 'react';

const FacebookSvg = () => {

    return (

        <>
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 250 250"  xml=':space="preserve"'>

                <g>
                    <path className="st0" style={{ fill: '#3D6AD5' }} d="M96.8,190.2c0-15.7,0-31.5,0.1-47.2c0-2.3-0.6-2.9-2.9-2.9c-9.1,0.1-18.3,0.1-27.4-0.1c-4.3-0.1-5.7-1.6-5.8-6
		c-0.1-12.2-0.1-24.5,0-36.7c0-4.6,1.9-6.2,6.9-6.2c8.7-0.1,17.5-0.1,26.2,0c2.2,0,2.9-0.5,2.9-2.9C97,77.1,96.4,66,97.4,55
		c2.3-27.1,22.5-47.2,49.5-49.3c12.3-1,24.7-0.3,37-0.4c4.2,0,6,1.8,6,6.2c0.1,11.4,0.1,22.7,0,34.1c0,4.1-1.5,5.7-5.6,5.8
		c-8,0.3-15.9,0.3-23.9,0.4c-9.2,0.2-12.9,3.8-12.9,13c-0.1,7.8,0.1,15.5-0.1,23.3c-0.1,2.5,0.8,3,3.1,3c10-0.1,20-0.1,30,0
		c6.6,0,7.9,1.3,7.9,7.8c0,11.6,0,23.1,0,34.7c0,5-1.1,6.4-6.1,6.5c-10.5,0.2-21,0.3-31.5,0.1c-3.1-0.1-3.6,0.9-3.6,3.8
		c0.1,30.1,0.1,60.2,0.1,90.3c0,9.1-0.8,9.9-10,9.9c-11.1,0-22.1,0-33.2,0c-5.7,0-7.3-1.6-7.3-7.3C96.8,221.2,96.8,205.7,96.8,190.2
		z"/>
                </g>
            </svg>

        </>


    )

}

export default FacebookSvg      