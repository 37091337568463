import React from "react";
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { array, object, string } from 'yup';

import { FieldArray, Form, Formik } from "formik";
import Typography from "@mui/joy/Typography";

const ApplicationDownpayment = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const emptyAsset = {asset_type: '', institution: '', amount: ''}
  const initialValues = {
    down_payment: [emptyAsset]
  }
  const formSchema = object({
    down_payment: array(
      object({
        asset_type: string()
          .required('This is required field'),
        institution: string()
          .required('This is required field'),
        amount: string()
          .required('This is required field')
          .min(1, 'Amount needs to be at least 1')
      })
    )
      .min(1, 'You need to provide at least 1 asset')
  });
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldTouched}) => (
            <Form className="form-2" method="post" name="downPayment" id="downPayment">
              <FieldArray name="down_payment">
                {({ push, remove }) => (
                  <>
                    <div className="mb-10 address-qstn">
                      <h2>Where is your down payment coming from?</h2>
                      {values.down_payment.map((_, index) => (
                        <div key={index}>
                          <div className="payment-box">
                        <div className="asset">
                          <div className="form-group">
                            <label>Add Asset</label>
                            <Select
                              name={`down_payment.${index}.asset_type`}
                              onClose={() => {setFieldTouched(`down_payment.${index}.asset_type`)}}
                              value={_.asset_type}
                              onChange={(e, newValue) => {setFieldValue(`down_payment.${index}.asset_type`, newValue)}}
                              slotProps={{
                                button: {
                                  id: 'select-button',
                                  'aria-labelledby': 'select-label select-button',
                                }
                              }}
                            >
                              <Option value="">Choose an asset type</Option>
                              <Option value="deposit">Deposit</Option>
                              <Option value="savings account">Savings Account</Option>
                              <Option value="gift">Gift</Option>
                              <Option value="invested">Investment accounts</Option>
                              <Option value="property sale">Property Sale</Option>
                              <Option value="property rrsp">Property RRSP</Option>
                              <Option value="tfsa">TFSA</Option>
                              <Option value="other">Other</Option>
                            </Select>
                            {(touched?.down_payment && touched?.down_payment[index] && touched?.down_payment[index].asset_type && errors?.down_payment && errors?.down_payment[index] && errors?.down_payment[index].asset_type) &&
                              <Typography color="danger">
                                {errors?.down_payment[index].asset_type}
                              </Typography>
                            }
                          </div>
                        </div>
                        <div className="add-asset step-1">
                          <div className="form-group vert-group">
                            <Input name={`down_payment.${index}.institution`}
                                   onBlur={handleBlur}
                                   value={_.institution}
                                   onChange={handleChange}
                                   placeholder="Enter Institution" />
                            {(touched?.down_payment && touched?.down_payment[index] && touched?.down_payment[index].institution && errors?.down_payment && errors?.down_payment[index] && errors?.down_payment[index].institution) &&
                              <Typography color="danger">
                                {errors?.down_payment[index].institution}
                              </Typography>
                            }
                            <Input name={`down_payment.${index}.amount`}
                                   onBlur={handleBlur}
                                   value={_.amount}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$" />
                            {(touched?.down_payment && touched?.down_payment[index] && touched?.down_payment[index].amount && errors?.down_payment && errors?.down_payment[index] && errors?.down_payment[index].amount) &&
                              <Typography color="danger">
                                {errors?.down_payment[index].amount}
                              </Typography>
                            }
                          </div>
                        </div>
                      </div>
                        </div>
                      ))}
                    </div>
                    <div className="mb-10 address-qstn moreAssets"></div>
                    <p className="cs-error" id="instAmt"></p>
                    <div className="button-grp d-flex align-items-start">
                      <button className="prev btn previousTab" type="button">Previous</button>
                      <button type="button" className="prev btn step-1 addAssets" onClick={() => push(emptyAsset)}>Add more Asset +</button>
                      <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
                        Finished with Assets
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ApplicationDownpayment;
