import React, {useEffect} from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";

const ApplicationApplicant = () => {
  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    dobd: "",
    dobm: "",
    doby: "",
    marital_status: "",
    home: "",
    work: "",
    mobile: "",
    fax_number: "",
    email: "",
    contact_method: "",
    gender: "",
    sin: "",
    dependents: "",
    rewards: "",
    citizenship: "",
    credit_score: "",
  };
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formSchema = Yup.object({
    first_name: Yup.string().required("This is required field"),
    middle_name: Yup.string().required("This is required field"),
    last_name: Yup.string().required("This is required field"),
    dobd: Yup.string().required("This is required field"),
    dobm: Yup.string().required("This is required field"),
    doby: Yup.string().required("This is required field"),
    marital_status: Yup.string().required("This is required field"),
    home: Yup.string().required("This is required field"),
    work: Yup.string().required("This is required field"),
    mobile: Yup.string().required("This is required field").matches(phoneRegExp, 'Phone number is not valid'),
    fax_number: Yup.string().required("This is required field"),
    email: Yup.string().email('Enter a valid email').required("This is required field"),
    contact_method: Yup.string().required("This is required field"),
    gender: Yup.string().required("This is required field"),
    sin: Yup.string().required("This is required field"),
    dependents: Yup.string().required("This is required field"),
    rewards: Yup.string().required("This is required field"),
    citizenship: Yup.string().required("This is required field"),
    credit_score: Yup.string().required("This is required field")
  });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <form className="form-2" method="post" name="applicant" id="applicant" onSubmit={handleSubmit}>
          <div className="mb-10">
            <h2>Applicant</h2>
            <Grid container>
              <Grid xs={12} lg={4} md={4} item>
                <FormControl>
                  <FormLabel>First name</FormLabel>
                  <Input name="first_name" onBlur={handleBlur} value={values.first_name} onChange={handleChange} placeholder="Placeholder" />
                  {touched.first_name && <p>{errors.first_name}</p>}
                </FormControl>
              </Grid>
              <Grid xs={12} lg={4} md={4} item>
                <FormControl>
                  <FormLabel>Middle name</FormLabel>
                  <Input name="middle_name" onBlur={handleBlur} value={values.middle_name} onChange={handleChange} placeholder="Placeholder" />
                  {touched.middle_name && <p>{errors.middle_name}</p>}
                </FormControl>
              </Grid>
              <Grid xs={12} lg={4} md={4} item>
                <FormControl>
                  <FormLabel>Last name</FormLabel>
                  <Input name="last_name" onBlur={handleBlur} value={values.last_name} onChange={handleChange} placeholder="Placeholder" />
                  {touched.last_name && <p>{errors.last_name}</p>}
                </FormControl>
              </Grid>
              {/* </Grid>
            <Grid container> */}
              <Grid xs={12} xl={4} lg={6} item>
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <div className="birthdate tw-inputs  thre-inputs d-flex select-cstm-wrap subject-birth-date ">
                    <Select
                      name="dobd" onClose={() => {setFieldTouched('dobd')}} value={values.dobd} onChange={(e, newValue) => {setFieldValue('dobd', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="option1">Option I</Option>
                      <Option value="option2">Option II</Option>
                    </Select>
                    {touched.dobd && <p>{errors.dobd}</p>}
                    <Select
                      name="dobm" onClose={() => {setFieldTouched('dobm')}} value={values.dobm} onChange={(e, newValue) => {setFieldValue('dobm', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="option1">Option I</Option>
                      <Option value="option2">Option II</Option>
                    </Select>
                    {touched.dobm && <span>{errors.dobm}</span>}
                    <Select
                      name="doby" onClose={() => {setFieldTouched('doby')}} value={values.doby} onChange={(e, newValue) => {setFieldValue('doby', newValue)}}
                      slotProps={{
                        button: {
                          id: 'select-button',
                          'aria-labelledby': 'select-label select-button',
                        }
                      }}
                    >
                      <Option value="option1">Option I</Option>
                      <Option value="option2">Option II</Option>
                    </Select>
                    {touched.doby && <span>{errors.doby}</span>}
                  </div>
                </div>
              </Grid>
              <Grid xs={12} xl={4} md={6}  item>
                <div className="form-group">
                  <label>Marital Status</label>
                  <Select
                    name="marital_status" onClose={() => {setFieldTouched('marital_status')}} value={values.marital_status} onChange={(e, newValue) => {setFieldValue('marital_status', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.marital_status && <p>{errors.marital_status}</p>}

                </div>
              </Grid>
              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Home</FormLabel>
                  <Input name="home" onBlur={handleBlur} value={values.home} onChange={handleChange} placeholder="Placeholder" />
                  {touched.home && <span>{errors.home}</span>}
                </FormControl>
              </Grid>
              {/* </Grid>
            <Grid container> */}
              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Work</FormLabel>
                  <Input name="work" onBlur={handleBlur} value={values.work} onChange={handleChange} placeholder="Placeholder" />
                  {touched.work && <span>{errors.work}</span>}
                </FormControl>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Mobile</FormLabel>
                  <Input name="mobile" onBlur={handleBlur} value={values.mobile} onChange={handleChange} placeholder="Placeholder" />
                  {touched.mobile && <span>{errors.mobile}</span>}
                </FormControl>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Fax Number</FormLabel>
                  <Input name="fax_number" onBlur={handleBlur} value={values.fax_number} onChange={handleChange} placeholder="Placeholder" />
                  {touched.fax_number && <span>{errors.fax_number}</span>}
                </FormControl>
              </Grid>
              {/* </Grid>
            <Grid container> */}

              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Email Address</FormLabel>
                  <Input name="email" onBlur={handleBlur} value={values.email} onChange={handleChange} placeholder="Placeholder" />
                  {touched.email && <span>{errors.email}</span>}
                </FormControl>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <div className="form-group">
                  <label>Contact Method</label>
                  <Select
                    name="contact_method" onClose={() => {setFieldTouched('contact_method')}} value={values.contact_method} onChange={(e, newValue) => {setFieldValue('contact_method', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.contact_method && <span>{errors.contact_method}</span>}
                </div>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <div className="form-group">
                  <label>Gender</label>
                  <Select
                    name="gender" onClose={() => {setFieldTouched('gender')}} value={values.gender} onChange={(e, newValue) => {setFieldValue('gender', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.gender && <span>{errors.gender}</span>}
                </div>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Sin</FormLabel>
                  <Input name="sin" onBlur={handleBlur} value={values.sin} onChange={handleChange} placeholder="Placeholder" />
                  {touched.sin && <span>{errors.sin}</span>}
                </FormControl>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <FormControl>
                  <FormLabel>Dependents</FormLabel>
                  <Input name="dependents" onBlur={handleBlur} value={values.dependents} onChange={handleChange} placeholder="Placeholder" />
                  {touched.dependents && <span>{errors.dependents}</span>}
                </FormControl>
              </Grid>

              <Grid xs={12} lg={4} md={6} item>
                <div className="form-group">
                  <label>What Rewards do you prefer?</label>
                  <Select
                    name="rewards" onClose={() => {setFieldTouched('rewards')}} value={values.rewards} onChange={(e, newValue) => {setFieldValue('rewards', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.rewards && <span>{errors.rewards}</span>}
                </div>
              </Grid>
              {/* </Grid>
            <Grid container> */}
              <Grid xs={12} md={6} item>
                <div className="form-group">
                  <label>Citizenship</label>
                  <Select
                    name="citizenship" onClose={() => {setFieldTouched('citizenship')}} value={values.citizenship} onChange={(e, newValue) => {setFieldValue('citizenship', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.citizenship && <span>{errors.citizenship}</span>}
                </div>
              </Grid>
              <Grid xs={12} md={6} item>
                <div className="form-group">
                  <label>Credit score</label>
                  <Select
                    name="credit_score" onClose={() => {setFieldTouched('credit_score')}} value={values.credit_score} onChange={(e, newValue) => {setFieldValue('credit_score', newValue)}}
                    slotProps={{
                      button: {
                        id: 'select-button',
                        'aria-labelledby': 'select-label select-button',
                      }
                    }}
                  >
                    <Option value="option1">Option I</Option>
                    <Option value="option2">Option II</Option>
                  </Select>
                  {touched.credit_score && <span>{errors.credit_score}</span>}
                </div>
              </Grid>

            </Grid>
            <Grid container>
              <Grid xs={12} lg={8} md={12} item>
                <div className="ask-credit">
                  <figure>
                    <svg className="h-6 w-6 text-yellow-400 fill-current" viewBox="0 0 24 24">
                      <path d="M11 0v3h2V0h-2zM4.223 2.809L2.809 4.223l2.12 2.12L6.345 4.93 4.223 2.809zm15.554 0l-2.12 2.12 1.413 1.415 2.121-2.121-1.414-1.414zM12 5c-3.854 0-7 3.146-7 7 0 2.768 1.656 5.102 4 6.234V21c0 1.093.907 2 2 2h2c1.093 0 2-.907 2-2v-2.766c2.344-1.132 4-3.466 4-6.234 0-3.854-3.146-7-7-7zm0 2c2.774 0 5 2.226 5 5a4.99 4.99 0 01-3.334 4.709l-.666.234V21h-2v-4.057l-.666-.234A4.99 4.99 0 017 12c0-2.774 2.226-5 5-5zM0 11v2h3v-2H0zm21 0v2h3v-2h-3zM4.93 17.656l-2.121 2.121 1.414 1.414 2.12-2.12-1.413-1.415zm14.14 0l-1.414 1.414 2.121 2.121 1.414-1.414-2.12-2.12z"></path>
                    </svg>
                  </figure>
                  <span>We ask for your credit score in order to avoid having it impacted by a formal inquiry prematurely. It's simply to confirm that we are showing the right mortgage products!</span>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="button-grp d-flex align-items-start">
            <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
              Previous
            </Button>
            <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
              Next
            </Button>
          </div>
        </form>
      </div >
    </>
  );
};

export default ApplicationApplicant;
