import React from 'react';
import { useFormContext } from 'react-hook-form';
import three from "../../../assets/public/images/get-quotes/3.png";
import four from "../../../assets/public/images/get-quotes/4.png";
import five from "../../../assets/public/images/get-quotes/5.png";

const QuickRadio = (props, ref) => {

    const {
        id,
        label,
        name,
        value,
        image,
        description,
        data_id=null,
        register = () => { },
        clickEventHandler = () => {}
    } = props;

    return (

        <div className="step-radio-outer">
            <input
                type="radio"
                name={name}
                value={value}
                data-id={data_id}
                id={id}
                {...register(name, { required: {value:true,message:'This field is required.'} })}
                onClick={(e) => {clickEventHandler()}}

            />
            <label htmlFor={id} >
                {image && <img src={image} alt="Rateshop"/>}
                <h4>{label}</h4>
            </label>
            {description && <span>{description}</span>}
        </div>
    );
}

const StepSeven = (props) => {
    
    const {handleNext} = props;
    const {register,handleSubmit} = useFormContext();

    return (

        <>
            <div className="heading">
                <h4>What <span>Rewards</span> do You Prefer</h4>
            </div>
            <div className="step-radio-box" onChange={handleSubmit(handleNext)}>
                <QuickRadio
                    id="reward_type_1"
                    name="reward_type"
                    label="Free Legals"
                    value="free legals"
                    image={three}
                    key="1"
                    register={register}
                />
                <QuickRadio
                    id="reward_type_2"
                    name="reward_type"
                    label="Free Appraisal"
                    value="free appraisals"
                    image={five}
                    key="2"
                    register={register}
                />
                <QuickRadio
                    id="reward_type_3"
                    name="reward_type"
                    label="upto $2500 in Cashback"
                    value="cashback"
                    image={four}
                    key="3"
                    register={register}
                />
            </div>
        </>
    );
    
}

export default StepSeven;