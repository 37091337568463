import React from "react"; 
import { Button,  Container, Grid, Avatar } from "@mui/material"; 
import './header.scss' 
import logo from '../../../assets/public/sweepstake/logo.png'
import image1 from '../../../assets/public/sweepstake/michael-squeo.jpg'
const Header = () => { 
  return (
    <> 
      <header className="sweepstake-header">
        <Container className="st-container"> 
            <Grid container className="justify-content-between align-items-center"> 
                <div className="site-logo"> 
                    <a className="navbar-brand" href="javascript:void(0);">
                        <img src={logo} alt=""/>
                    </a>
                </div>
                <div className="top-right-sec d-flex flex-wrap justify-content-end align-items-center">
                    <a href="#" className="apply-btn" target="_blank">Apply Now</a>
                    <a href="tel:+16476575455" className="call-btn">
                        <img src={image1} alt=""/>
                        <span>Call
                            <strong>6476575455</strong>
                        </span>
                    </a>
                </div>  
            </Grid> 
        </Container> 
    </header>  
    </>
  );
};

export default Header;
