import React, { useEffect, useState } from "react";
import '../../application/application.scss';
import Avatar from '@mui/joy/Avatar';
import FormControl from '@mui/joy/FormControl';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { usePlacesWidget } from "react-google-autocomplete";
import { Oval } from 'react-loader-spinner';
import * as Yup from "yup";
import { useFormik } from "formik";

const ApplicationPurchase = () => {
  const [propertyAddress, setPropertyAddress] = useState('');

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyDiKmRh2vEg2hiV1ZIVeyNlxPjVegpChvE',
    onPlaceSelected: (place) => {
      setPropertyAddress(place.formatted_address);
      const addressObj = {
        city: place.formatted_address,
        // encId: encryptData('Abhishek')
      }
      setPropertyAddress(addressObj);
      setFieldValue('autoaddress', place.formatted_address);
      // console.log(propertyAddress);
    },
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "ca" },
    },
  });


  const initialValues = {
    mort_type: "",
    property_use: "",
    gco_applicant: "",
    autoaddress: "",
    spend_amount: "",
    down_payment_amount: "",
    downPaymentPercentage: ""
  };
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
    const purchaseData = ({...values, ...propertyAddress})
    // console.log('purchaseData', purchaseData);
    fetch('https://crm.rateshop.ca/ReactController/getStarted', {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(purchaseData),
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(result['message'])
          // if (result['status'] === 'ok') {
          //   window.location.href = '/';
          // }
          // console.log('result', result);
        }
      )
  }
  const formSchema = Yup.object({
    mort_type: Yup.string()
      .required("Please Select your Type"),
    property_use: Yup.string()
      .required("Required"),
    gco_applicant: Yup.string()
      .required("Required"),
    autoaddress: Yup.string()
      .required("Address is required"),
    spend_amount: Yup.string()
      .max(10, 'Max allowed length is 10')
      .required("Spend Amount is required"),
    down_payment_amount: Yup.string()
      .max(10, 'Max allowed length is 10')
      .required("Down Payment Amount is required"),
    downPaymentPercentage: Yup.string()
      .required("Down Payment Percentage is required"),
  });
  const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  /*if (showLoading) {
    return <Oval
      height={60}
      width={60}
      color="#09318b"
      wrapperStyle={{}}
      wrapperClass="csLoader"
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#2661e7"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />;
  }*/
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <form className="form-1" name="purchase" id="purchase" onSubmit={handleSubmit} >
          <div className="mb-4">

            <h2>What are you looking to do?</h2>
            <div className="cs-selected-options">
              <div className="cs-radio-btn">
                <div className="cs-radio-outer">
                  <FormControl>
                    <RadioGroup
                      overlay
                      role="group"
                      orientation="horizontal"
                      sx={{ gap: 2, mt: 1 }}
                    >
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'purchase'}
                          onChange={handleChange}
                          value="purchase"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">A Purchase</Typography>
                        <FormHelperText>Pre-Approvals & New Purchases</FormHelperText>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'refinance'}
                          onChange={handleChange}
                          value="refinance"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">A Refinance</Typography>
                        <FormHelperText>Debt Consolidation & One Lower Payment</FormHelperText>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'renewalSwitch'}
                          onChange={handleChange}
                          value="renewalSwitch"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">A Renewal/Switch</Typography>
                        <FormHelperText>Same Mortgage Balance & Lower Rates</FormHelperText>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'homeEquityLine'}
                          onChange={handleChange}
                          value="homeEquityLine"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">A Home Equity Line</Typography>
                        <FormHelperText>Access Home Equity Loans in 24 hours</FormHelperText>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'privateFirst'}
                          onChange={handleChange}
                          value="privateFirst"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">Private 1st</Typography>
                        <FormHelperText>Same Mortgage Balance & Lower Rates</FormHelperText>
                      </Sheet>
                      <Sheet
                        component="label"

                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          boxShadow: 'sm',
                          borderRadius: 'md',
                          bgcolor: 'background.body',
                          gap: 1.5,
                        }}
                      >
                        <Radio
                          name="mort_type"
                          checked={values.mort_type === 'privateSecond'}
                          onChange={handleChange}
                          value="privateSecond"
                          sx={{
                            mt: -1,
                            mr: -1,
                            mb: 0.5,
                            alignSelf: 'flex-end',
                            '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                          }}
                        />
                        <Avatar alt="s" src="https://www.rateshop.ca/themes/frontend/getQuote/images/purchasers.png" />
                        <Typography level="body2">Private 2nd</Typography>
                        <FormHelperText>Access Home Equity Loans in 24 hours</FormHelperText>
                      </Sheet>
                    </RadioGroup>
                  </FormControl>
                  <p>{errors.mort_type}</p>
                </div>
              </div>
            </div>

          </div>
          <div className="mb-10">

            <h2>How will you use this property?</h2>
            <div className="cstm-tick-radiogroup">
              <FormControl>
                <RadioGroup
                  overlay
                  sx={{ gap: 2, mt: 1 }}
                >
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="property_use"
                      checked={values.property_use === 'Owner-occupied'}
                      onChange={handleChange}
                      value="Owner-occupied"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Owner-occupied</Typography>
                  </Sheet>
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="property_use"
                      checked={values.property_use === 'Second home'}
                      onChange={handleChange}
                      value="Second home"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Second/Vacation Home</Typography>
                  </Sheet>
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="property_use"
                      checked={values.property_use === 'Investment'}
                      onChange={handleChange}
                      value="Investment"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Investment</Typography>
                  </Sheet>
                </RadioGroup>
              </FormControl>
              <p>{errors.property_use}</p>
            </div>
          </div>
          <div className="mb-10">

            <h2>Do you have a co-borrower?</h2>
            <div className="cstm-tick-radiogroup">
              <FormControl>
                <RadioGroup
                  overlay
                  name="gco_applicant"
                  checked={values.gco_applicant === 'Owner-occupied'}
                  onChange={handleChange}
                  value="Owner-occupied"
                  orientation="vertical"
                  sx={{ gap: 2, mt: 1 }}
                >
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="gco_applicant"
                      checked={values.gco_applicant === '1'}
                      onChange={handleChange}
                      value="1"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">Yes, this is a joint application</Typography>
                  </Sheet>
                  <Sheet
                    component="label"

                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      boxShadow: 'sm',
                      borderRadius: 'md',
                      bgcolor: 'background.body',
                      gap: 1.5,
                    }}
                  >
                    <Radio
                      name="gco_applicant"
                      checked={values.gco_applicant === '2'}
                      onChange={handleChange}
                      value="2"
                      sx={{
                        mt: -1,
                        mr: -1,
                        mb: 0.5,
                        alignSelf: 'flex-end',
                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                      }}
                    />
                    <Typography level="body2">No, it is just myself</Typography>
                  </Sheet>
                </RadioGroup>
              </FormControl>
              <p>{errors.gco_applicant}</p>
            </div>

          </div>
          <div className="mb-10">

            <h2>What city is the property in?</h2>
            <div className="max-320">
              <input name="autoaddress" value={values.autoaddress} onChange={handleChange}  ref={ref} style={{ width: "90%" }} />
            </div>
            <p className="cs-error" id="cityError">{errors.autoaddress}</p>

          </div>
          <div className="mb-10">

            <h2>How much do you want to spend?</h2>
            <div role="radiogroup">
              <Input
                name="spend_amount"
                value={values.spend_amount}
                onChange={handleChange}
                placeholder="0"
                startDecorator="$"
                sx={{ width: 320 }}
              />
              <p>{errors.spend_amount}</p>
            </div>

          </div>
          <div className="mb-10">

            <h2>How much is your down payment?</h2>
            <div className="tw-inputs">
              <Input
                name="down_payment_amount"
                value={values.down_payment_amount}
                onChange={handleChange}
                placeholder="0"
                startDecorator="$"
                sx={{ width: 226 }}
              />
              <p>{errors.down_payment_amount}</p>
              <Input
                name="downPaymentPercentage"
                value={values.downPaymentPercentage}
                onChange={handleChange}
                placeholder="0"
                endDecorator="%"
                sx={{ width: 90 }}
              />
              <p>{errors.downPaymentPercentage}</p>

            </div>

          </div>
          <div className="mb-10">
            <h3 className="require" id="mortgage-insurance-content">You may require a minimum downpayment, CHMC insurance and a mortgage.</h3>
          </div>

          <div className="button-grp">
            <Button variant="soft" type="submit" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ApplicationPurchase;
