import React from "react";
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";

const ApplicationConcent = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const initialValues = {
    understand_privacy: "",
    understand_anti_spam: "",
    message: "",
  }
  const formSchema = Yup.object({
    understand_privacy: Yup.string().required("This is required field"),
    understand_anti_spam: Yup.string().required("This is required field"),
    message: Yup.string().required("This is required field"),
  });
  const { values, errors, touched, handleChange, handleSubmit} = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <form className="form-1" method="post" name="rates" id="rates" onSubmit={handleSubmit}>
          <div className="cs-consent-sec consent-data">
          <Grid container>
            <Grid xs={12} item>
              <div className="sub_heading">
                <h4><i className="fa fa-lock" aria-hidden="true"></i> Consent and Privacy Agreement</h4>
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="form-group form-group-lg form_bigtext">
                <div id="form_term_text">
                  <p>It’s important to get the right details on your mortgage application so we can assess your file better. The information provided by you will be relied upon to be accurate in order for the lenders or any other persons we may submit to on your behalf to determine credit worthiness You agree the information provided is true and complete and that you have not withheld or misrepresented any information.</p>
                  <p>We require your consent to request a credit bureau to review your credit worthiness to determine your suitability to a mortgage product. Your SIN is not required, however helps us accurately request your bureau. You permit us to request a credit bureau, collect, use and maintain personal information for the following purposes: (a) to determine the suitability of the products and services for you (b) to determine your eligibility of the various products and services (c) to establish, manage and offer products and services that meet your needs (d) to provide on-going service and (e) to meet our legal and regulatory requirements.</p>
                  <p>RateShop Inc. and its agents, work exclusively on your behalf, shopping with the same lenders across multiple brokerages can impact the consistency in your application. You authorize RateShop Inc. to act exclusively and irrevocably on your behalf for six months from this application submission date. You agree that an advisor will quote a maximum rate for your mortgage and a onetime market rate reduction may be applicable prior to closing. Upon acceptance of this rate, once a lender commitment has been issued, inability to close the transaction with RateShop Inc and its agents will result in a penalty of $1500.00 payable on closing date.</p>
                  <p>We will continue to work diligently to offer the most competitive rate even after the approval, and match any rate with the same lender, with the same terms and features in either a buydown or cashback resulting in a similar Net Rate, your choice to not proceed with an issued commitment, signed or unsigned after will result in a penalty of $1500.00 payable on closing.</p>
                  <p>We rely on accuracy in information to find you’re the best products. You authorize RateShop Inc. to disclose to or retrieve from any information lender, insurer, insurance agent, financial advisor, credit bureau agencies, past and present employers, creditors and tenants and any transaction related parties.</p>
                  <p>
                    <b>Canada Anti-Spam Legislation:</b>
                    I/We authorize Rateshop Inc., affiliated companies and authorized agents to keep in touch with me/us via electronic messaging in order to provide me/us with content and provide insightful information on mortgages, finances, etc. I/We wish to be kept informed and consent to the receiving of these informative electronic communications. I/We understand that I/we can withdraw consent at any time. I/We understand that even if I/We do not provide my/our express consent to receive promotional communications, I/We may still be contacted, if authorized under applicable anti-spam legislation, for example if I/We have recently entered into a transaction with Rateshop Inc. (and therefore, Rateshop Inc. has my/our implied consent) as well as for transactional purposes such as contacts for customer service and/or product or service information, status updates or renewals, reminder notices or answers to my/our questions or inquiries.</p>
                  <p>
                    <b> Ongoing Commitment:</b>
                    I/We acknowledge the Rateshop Inc. Privacy Policy is available for review at www.rateshop.ca, and understand that the collection, use and disclosure of my/our personal information by Rateshop Inc. will be done in accordance with such Privacy Policy. I/We agree that a photocopy or electronic copy of this Consent, Privacy &amp; Service Agreement has the same value as the original one.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="form-group">

                <Checkbox name="understand_privacy" value={values.understand_privacy.toString()} onChange={handleChange} label="I have read and understand the Privacy/Consent/Anti-Spam Agreement." variant="outlined" />
                {touched.understand_privacy && <p>{errors.understand_privacy}</p>}
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="form-group">
                <Checkbox name="understand_anti_spam" value={values.understand_privacy.toString()} onChange={handleChange} label="I have read and understand the Canada Anti-Spam Legislation section and consent to the communications." variant="outlined" />
                {touched.understand_anti_spam && <p>{errors.understand_anti_spam}</p>}
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="sub_heading">
                <h4><i className="fa fa-comment" aria-hidden="true"></i> Comments/Special Instructions</h4>
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="form-group msg-textarea">
                <label>Message</label>
                <Textarea value={values.message} onChange={handleChange}  minRows={2} name="message" />
                {touched.message && <p>{errors.message}</p>}
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className="button-grp d-flex  justify-content-center">
                <input type="submit" name="rates" className="next btn" value="Submit" />
              </div>
            </Grid>
            </Grid>
          </div>
        </form>
      </div>
    </>
  );
};

export default ApplicationConcent;
