import './application-sidebar.scss'
import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Logo from "../shared/logo/Logo";
import {decryptData} from "../../helpers/helpers";
const AppSidebar = () => {
  const {appId} = useParams();
  const [userId, setUserId] = useState(0);
  useEffect(() => {
    if (appId) {
      decryptData(appId).then((resId) => {
        setUserId(resId);
      }).catch(() => {});
    }
  }, [])

  return (
    <div className='left-sidebar'>
      <div className="logo">
        <Logo />
      </div>
      <h3>Applicant {!!(userId) && <>#{userId} </>}</h3>
      <MenuList className='desktop-menu'>
        <MenuItem>
          <NavLink to='application/purchase'>
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-blue-500 fill-current" viewBox="0 0 24 24">
              <path d="M11.45 2a1 1 0 00-.4.1S6 4.53 6 9.56c0 .98.27 2.28.6 3.61-.39.13-.92.33-1.52.76A4.91 4.91 0 003 18a1 1 0 001 1h15a1 1 0 001-1c0-2-1.07-3.35-2.08-4.07a5.47 5.47 0 00-1.53-.76c.34-1.35.61-2.66.61-3.65 0-5.04-5.06-7.42-5.06-7.42a1 1 0 00-.5-.1zm.05 2.22c.7.39 3.5 2.05 3.5 5.3 0 .78-.48 2.97-.98 4.8-.32 1.19-.6 2.08-.78 2.68H9.76c-.19-.6-.46-1.48-.78-2.67-.5-1.81-.98-4-.98-4.77 0-3.25 2.81-4.95 3.5-5.34zm0 2.78A1.5 1.5 0 0010 8.5a1.5 1.5 0 001.5 1.5A1.5 1.5 0 0013 8.5 1.5 1.5 0 0011.5 7zm-4.38 8.1l.55 1.9H5.24a2.83 2.83 0 011.88-1.9zm8.75 0a2.83 2.83 0 011.88 1.9h-2.42c.16-.53.35-1.18.54-1.9zM10 21c0 1.48 1.5 2.97 1.5 2.97S13 22.48 13 21h-3z"></path>
            </svg>Get Started</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/applicant">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M12 1a3 3 0 00-2.82 2H5a2 2 0 00-2 2v14c0 1.1.9 2 2 2h14a2 2 0 002-2V5a2 2 0 00-2-2h-4.18A3 3 0 0012 1zm0 2a1 1 0 010 2h7v14H5V5h7a1 1 0 010-2zm0 5a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zm0 5c-2.19 0-4 .9-4 2.22V16h8v-.78C16 13.9 14.19 13 12 13z"></path>
            </svg>Applicant
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/address">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M12 1a6 6 0 00-6 6c0 4.29 6 11 6 11s6-6.71 6-11a6 6 0 00-6-6zm0 2a4 4 0 014 4c0 2.01-2.08 5.36-4 7.86-1.92-2.5-4-5.84-4-7.86a4 4 0 014-4zm0 2a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zM4.8 15L2 22h20l-2.8-7H16.8c-.48.73-.97 1.4-1.42 2h2.46l1.2 3H4.95l1.2-3h2.46c-.45-.6-.94-1.27-1.42-2H4.8z"></path>
            </svg>Address
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/income">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M12 2a9.98 9.98 0 00-8.4 4.6L2 5v5h5L5.05 8.05A7.98 7.98 0 0120 12a8 8 0 01-9.76 7.8l-.44 1.96A10 10 0 1012 2zm-.29 4v1.08c-.34.04-2.23.4-2.23 2.69 0 3.34 3.46 2.35 3.46 4.58 0 1.12-.73 1.14-.87 1.14s-1.02.07-1.02-1.63H9.16c0 2.89 2.1 3.12 2.41 3.16V18h1v-.98a2.5 2.5 0 002.25-2.68c0-3.21-3.45-2.6-3.45-4.56 0-1.13.62-1.16.75-1.16.24 0 .83.21.83 1.58h1.89c0-2.62-1.8-3.03-2.12-3.1V6h-1zM2 12v.16a10 10 0 00.28 2.2l1.94-.47A8 8 0 014 12.13V12H2zm2.83 3.55l-1.8.9c.34.65.74 1.28 1.2 1.86l1.56-1.27c-.37-.46-.7-.96-.96-1.49zm2.22 2.74l-1.23 1.57c.58.46 1.21.85 1.88 1.17l.86-1.8a8.03 8.03 0 01-1.5-.94z"></path>
            </svg>Income
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/properties">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M17 15h2v2h-2zM17 11h2v2h-2zM17 7h2v2h-2zM13.74 7l1.26.84V7z"></path>
              <path d="M10 3v1.51l2 1.33V5h9v14h-4v2h6V3z"></path>
              <path d="M8.17 5.7L15 10.25V21H1V10.48L8.17 5.7zM10 19h3v-7.84L8.17 8.09 3 11.38V19h3v-6h4v6z"></path>
            </svg>Properties
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/down-payment">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M10.5 1a3.5 3.5 0 00-2.98 5.3 7.17 7.17 0 00-5.18 4.76A1 1 0 002 11a1 1 0 00-1 1 1 1 0 001 1c0 1.16.4 2.35 1.07 3.44l.8 4.72a1 1 0 00.98.84h2.3a1 1 0 00.99-.84l.12-.71c1.01.34 2.1.55 3.24.55.7 0 1.37-.06 2.02-.16l.4.67c.19.3.51.49.87.49H17a1 1 0 00.98-1.2l-.3-1.5c1-.63 1.82-1.41 2.4-2.3h1.14a1 1 0 00.97-.76l.59-2.34a1 1 0 00-.66-1.2l-1.43-.47a7.77 7.77 0 00-1.3-2.36c.35-1.1.61-2.42.61-3.87l-5.57.62c-.32-.1-.64-.18-.98-.26A3.5 3.5 0 0010.5 1zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8c1.38 0 2.68.18 3.85.53l.39.12.4-.04 3.11-.35c-.07.34-.16.68-.26 1.02l-.31 1 .65.82c.44.56.77 1.16.96 1.75l.32.96.95.32.55.18-.17.69h-1.46l-.59.91a5.8 5.8 0 01-1.78 1.7l-1.16.74.27 1.35.06.3h-.43l-.11-.2-.7-1.15-1.33.21c-.57.1-1.15.14-1.71.14-.86 0-1.73-.15-2.6-.44l-2.23-.76-.36 2.2h-.62l-.64-3.88-.07-.4-.21-.34C4.27 14.58 4 13.75 4 13c0-.05.16-5 6-5zm6 4a1 1 0 100 2 1 1 0 000-2z"></path>
            </svg>Down Payment
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/liabilities">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M10.5 1a3.5 3.5 0 00-2.98 5.3 7.17 7.17 0 00-5.18 4.76A1 1 0 002 11a1 1 0 00-1 1 1 1 0 001 1c0 1.16.4 2.35 1.07 3.44l.8 4.72a1 1 0 00.98.84h2.3a1 1 0 00.99-.84l.12-.71c1.01.34 2.1.55 3.24.55.7 0 1.37-.06 2.02-.16l.4.67c.19.3.51.49.87.49H17a1 1 0 00.98-1.2l-.3-1.5c1-.63 1.82-1.41 2.4-2.3h1.14a1 1 0 00.97-.76l.59-2.34a1 1 0 00-.66-1.2l-1.43-.47a7.77 7.77 0 00-1.3-2.36c.35-1.1.61-2.42.61-3.87l-5.57.62c-.32-.1-.64-.18-.98-.26A3.5 3.5 0 0010.5 1zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8c1.38 0 2.68.18 3.85.53l.39.12.4-.04 3.11-.35c-.07.34-.16.68-.26 1.02l-.31 1 .65.82c.44.56.77 1.16.96 1.75l.32.96.95.32.55.18-.17.69h-1.46l-.59.91a5.8 5.8 0 01-1.78 1.7l-1.16.74.27 1.35.06.3h-.43l-.11-.2-.7-1.15-1.33.21c-.57.1-1.15.14-1.71.14-.86 0-1.73-.15-2.6-.44l-2.23-.76-.36 2.2h-.62l-.64-3.88-.07-.4-.21-.34C4.27 14.58 4 13.75 4 13c0-.05.16-5 6-5zm6 4a1 1 0 100 2 1 1 0 000-2z"></path>
            </svg>Liabilities
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/subject-properties">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M2 3v10h9v4h2v-4h9V3H2zm3.84 2.24c.98 0 1.68.64 1.68 1.66H6.44c0-.11 0-.77-.62-.77-.6 0-.6.53-.6.62 0 1.03 2.3.57 2.3 2.52 0 1.44-1.4 1.49-1.66 1.49-.23 0-1.86-.09-1.86-1.77h1.09c0 .16-.05.89.77.89.1 0 .57-.01.57-.6 0-1.16-2.3-.74-2.3-2.54 0-.7.46-1.5 1.7-1.5zm3.91.08h1l1.68 5.36h-1.16L11 9.58H9.5l-.29 1.1H8.07l1.68-5.36zm3.37 0h1.08v4.46h1.9v.9h-2.98V5.32zm3.8 0h3.07v.9H18V7.5h1.7v.87H18v1.42h2v.9h-3.08V5.32zm-6.68 1.45l-.5 1.91h1l-.5-1.91zM5.6 15L2 18.59V21h20v-2.41L18.41 15H15v2h2.59l2 2H4.4l2-2H9v-2H5.59z"></path>
            </svg>Subject Property
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="application/consent">
            <svg className="flex-shrink-0 -ml-1 mr-8 h-6 w-6 text-gray-500 fill-current" viewBox="0 0 24 24">
              <path d="M7.5 4A3.52 3.52 0 004 7.5C4 9.42 5.58 11 7.5 11S11 9.42 11 7.5 9.42 4 7.5 4zm8.69.42l-10 14 1.62 1.16 10-14-1.62-1.16zM7.5 6C8.34 6 9 6.66 9 7.5S8.34 9 7.5 9 6 8.34 6 7.5 6.66 6 7.5 6zm9 7a3.52 3.52 0 00-3.5 3.5c0 1.92 1.58 3.5 3.5 3.5s3.5-1.58 3.5-3.5-1.58-3.5-3.5-3.5zm0 2c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5-1.5-.66-1.5-1.5.66-1.5 1.5-1.5z"></path>
            </svg>Consent
          </NavLink>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppSidebar;
