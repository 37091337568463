import logo from '../assets/public/images/webp/logo.webp';
import logo2 from '../assets/public/images/webp/logo-2.webp';
import logo3 from '../assets/public/images/webp/logo-3.webp';
import services1 from '../assets/public/images/webp/img-1.webp';
import services2 from '../assets/public/images/webp/img-2.webp';
import services3 from '../assets/public/images/webp/img-3.webp';
import services4 from '../assets/public/images/webp/img-4.webp';
import worksImg1 from '../assets/public/images/webp/mobile-1.webp';
import worksImg2 from '../assets/public/images/webp/mobile-2.webp';
import worksImg3 from '../assets/public/images/webp/mobile-3-1.webp';
import searchImage from '../assets/public/images/search.svg';
import callImage from '../assets/public/images/webp/call.webp';
import blog1 from '../assets/public/images/webp/blog-1.webp';
import blog2 from '../assets/public/images/webp/blog-2.webp';
import blog3 from '../assets/public/images/webp/blog-3.webp';
import company1 from '../assets/public/images/webp/company-1.webp';
import company2 from '../assets/public/images/webp/company-2.webp';
import company3 from '../assets/public/images/webp/company-3.webp';
import company4 from '../assets/public/images/webp/company-4.webp';
import company5 from '../assets/public/images/webp/company-5.webp';
import company6 from '../assets/public/images/webp/company-6.webp';
import blogShape from '../assets/public/images/webp/shape2.webp';
import Quoteicon from '../assets/public/images/webp/Quoteicon.webp';
import teamMemberOne from '../assets/public/images/webp/team-1.webp';
import teamMemberTwo from '../assets/public/images/webp/team-2.webp';
import teamMemberThree from '../assets/public/images/webp/team-3.webp';
import choose1 from '../assets/public/images/webp/choose1.webp';
import choose2 from '../assets/public/images/webp/choose2.webp';
import choose3 from '../assets/public/images/webp/choose3.webp';
import choose4 from '../assets/public/images/webp/choose4.webp';
import choose5 from '../assets/public/images/webp/choose5.webp';
import choose6 from '../assets/public/images/webp/choose6.webp';
import centerImage from '../assets/public/images/webp/center-img.webp';
import csLogo1 from '../assets/public/images/webp/csLogo1.webp';
import csLogo2 from '../assets/public/images/webp/csLogo2.webp';
import csLogo3 from '../assets/public/images/webp/csLogo3.webp';
import csLogo4 from '../assets/public/images/webp/csLogo4.webp';
import csLogo5 from '../assets/public/images/webp/csLogo5.webp';
import csLogo6 from '../assets/public/images/webp/csLogo6.webp';
import csLogo7 from '../assets/public/images/webp/csLogo7.webp';
import csLogo8 from '../assets/public/images/webp/csLogo8.webp';
import csLogo9 from '../assets/public/images/webp/csLogo9.webp';
import csLogo10 from '../assets/public/images/webp/csLogo10.webp';
import csLogo12 from '../assets/public/images/webp/csLogo12.webp';
import csLogo13 from '../assets/public/images/webp/csLogo13.webp';
import csLogo14 from '../assets/public/images/webp/csLogo14.webp';
import csLogo15 from '../assets/public/images/webp/csLogo15.webp';
import csLogo16 from '../assets/public/images/webp/csLogo16.webp';
import csLogo17 from '../assets/public/images/webp/csLogo17.webp';
import csLogo18 from '../assets/public/images/webp/csLogo18.webp';
import csLogo19 from '../assets/public/images/webp/csLogo19.webp';
import marble from '../assets/public/images/webp/marble-logo.webp';
import marbleRightImg from '../assets/public/images/webp/marble-right-img.webp';
import banner from '../assets/public/images/webp/banner-2-nw.webp';
import homevalue from '../assets/public/images/webp/home-value.webp';
import cmhcinsurance from '../assets/public/images/webp/cmhc-insurance.webp';
import landtransfer from '../assets/public/images/webp/land-transfer.webp';
import affordabilitycalc from '../assets/public/images/webp/affordability-calc.webp';
import paymentcalc from '../assets/public/images/webp/payment-calc.webp';
import hoursapproval from '../assets/public/images/webp/hours-approval.webp';
import renewing from '../assets/public/images/webp/renewing.webp';
import b2b from '../assets/public/images/webp/B2B-bank.webp';
import cmls from '../assets/public/images/webp/CMLS.webp';
import logodesjardins from '../assets/public/images/webp/logo-desjardins.webp';
import manulifelogo from '../assets/public/images/webp/manulife_logo.webp';
import howitwork from '../assets/public/images/webp/how-itwork-1.webp';
import howitworkslider from '../assets/public/images/webp/how-it-work-slider.webp';
import renovation from '../assets/public/images/webp/renovation.webp';
import emergencies from '../assets/public/images/webp/emergencies.webp';
import debtconsolidation from '../assets/public/images/webp/debt_consolidation.webp';
import education from '../assets/public/images/webp/education.webp';
import businesscapital from '../assets/public/images/webp/business_capital.webp';
import lifeevents from '../assets/public/images/webp/life_events.webp';
import stresstest from '../assets/public/images/webp/stress-test.webp';
import homebuyer from '../assets/public/images/webp/homebuyer.webp';
import blogbig from '../assets/public/images/webp/blog_big-1.webp';
import housing from '../assets/public/images/webp/housing.webp';
import hour from '../assets/public/images/webp/hour.webp';
import approval from '../assets/public/images/webp/approval.webp';
import application from '../assets/public/images/webp/application.webp';
import creditcardbanner from '../assets/public/images/webp/credit-card-banner.webp';
import visacard from '../assets/public/images/webp/visa-card.webp';
import visainfinitecard from '../assets/public/images/webp/visa-infinite-card.webp';
import creditcard from '../assets/public/images/webp/credit-card.webp';
import mastercard from '../assets/public/images/webp/mastercard.webp';
import bankingbanner from '../assets/public/images/webp/banking-banner.webp';
import chequing from '../assets/public/images/webp/chequing.webp';
import business from '../assets/public/images/webp/business.webp';
import children from '../assets/public/images/webp/children.webp';
import savings from '../assets/public/images/webp/savings.webp';
import senior from '../assets/public/images/webp/senior.webp';
import student from '../assets/public/images/webp/student.webp';
import eqbank from '../assets/public/images/webp/eq-bank.webp';
import alternabank from '../assets/public/images/webp/alterna-bank.webp';
import tangerine from '../assets/public/images/webp/tangerine.webp';
import insurancebanner from '../assets/public/images/webp/insurance-banner.webp';
import homeinsurance from '../assets/public/images/webp/home-insurance.webp';
import businessinsurance from '../assets/public/images/webp/business-insurance.webp';
import lifeinsurance from '../assets/public/images/webp/life-insurance.webp';
import rentersinsurance from '../assets/public/images/webp/renters-insurance.webp';
import travelinsurance from '../assets/public/images/webp/travel-insurance.webp';
import currenciesdirect from '../assets/public/images/webp/currenciesdirect.webp';
import ofx from '../assets/public/images/webp/ofx.webp';
import transferwise from '../assets/public/images/webp/transferwise.webp';
import worldfirst from '../assets/public/images/webp/worldfirst.webp';
import worldremit from '../assets/public/images/webp/worldremit.webp';
import contactmap from '../assets/public/images/webp/contact-map.webp';
import location from '../assets/public/images/webp/location.webp';
import call from '../assets/public/images/webp/call.webp';
import career from '../assets/public/images/webp/career.webp';
import email from '../assets/public/images/webp/email.webp';
import phone from '../assets/public/images/webp/phone.webp';
import facebook from '../assets/public/images/webp/facebook.webp';
import linkedin from '../assets/public/images/webp/linkedin.webp';
import twitter from '../assets/public/images/webp/twitter.webp';
import rss from '../assets/public/images/webp/rss.webp';
import blogimg1 from '../assets/public/images/webp/blog1.webp';
import blogimg2 from '../assets/public/images/webp/blog2.webp';
import blogimg3 from '../assets/public/images/webp/blog3.webp';
import blogimg4 from '../assets/public/images/webp/blog4.webp';
import latestblog1 from '../assets/public/images/webp/latest-blog-1.webp';
import latestblog2 from '../assets/public/images/webp/latest-blog-2.webp';
import latestblog3 from '../assets/public/images/webp/latest-blog-3.webp';
import latestblog4 from '../assets/public/images/webp/latest-blog-4.webp';
import latestblog5 from '../assets/public/images/webp/latest-blog-5.webp';
import latestblog6 from '../assets/public/images/webp/latest-blog-6.webp';
import chatrobot from '../assets/public/images/webp/chat-robot.webp';
import koho from '../assets/public/images/webp/koho.webp';
import home from '../assets/public/images/webp/home.webp';
import bmo from '../assets/public/images/webp/bmo.webp';
import duca from '../assets/public/images/webp/duca.webp';
import highInterest from '../assets/public/images/webp/high-interest.webp';
import savingBanner from '../assets/public/images/webp/saving-banner.webp';
import agent1 from '../assets/public/images/webp/agent-1.webp';
import agent2 from '../assets/public/images/webp/agent-2.webp';
import agent3 from '../assets/public/images/webp/agent-3.webp';
import agent4 from '../assets/public/images/webp/agent-4.webp';
import agent5 from '../assets/public/images/webp/agent-5.webp';
import agent6 from '../assets/public/images/webp/agent-6.webp';
import agent7 from '../assets/public/images/webp/agent-7.webp'; 

import adImage1 from '../assets/public/images/webp/ad-image-1.webp';
import adImage2 from '../assets/public/images/webp/ad-image-2.webp';
import adImage3 from '../assets/public/images/webp/ad-image-3.webp';
import adImage4 from '../assets/public/images/webp/ad-image-4.webp';
import adImage5 from '../assets/public/images/webp/ad-image-5.webp';
import adImage6 from '../assets/public/images/webp/ad-image-6.webp';
import adImage7 from '../assets/public/images/webp/ad-image-7.webp';
import adImage8 from '../assets/public/images/webp/ad-image-8.webp';
import rsLogo from '../assets/public/images/webp/rs_logo.webp';
import defaultProfileImage from '../assets/public/images/webp/thumb_Rate.webp';



import trusted1 from '../assets/public/images/agent/webp/bbb.webp';
import trusted2 from '../assets/public/images/agent/webp/bbb.webp';
import trusted3 from '../assets/public/images/agent/webp/independent.webp';
import trusted4 from '../assets/public/images/agent/webp/mortgage.webp';
import trusted5 from '../assets/public/images/agent/webp/reader.webp';

import viewImg from '../assets/public/images/webp/eye.webp';
import reading from '../assets/public/images/webp/reading.webp';


import blogBanner from '../assets/public/images/blog/webp/blog-banner.webp';
import clock from '../assets/public/images/webp/clock.webp';


// ccommercial mortgage
import hourApproval from '../assets/public/images/commercial-mortgage/24hour-approvals.png';
import freeAppraiel from '../assets/public/images/commercial-mortgage/free-appraisal.png';
import quickClosing from '../assets/public/images/commercial-mortgage/quick-closing.png';
import bestRates from '../assets/public/images/commercial-mortgage/best-rates.png';

import whichmortgage from '../assets/public/partners/whichmortgage.svg';
import Toronto_Star_logo from '../assets/public/partners/Toronto_Star_logo.svg';
import cmp_top_ind_broker from '../assets/public/partners/cmp_top_ind_broker.png';
import top_independent_brokerages_2020 from '../assets/public/partners/top_independent_brokerages_2020.avif';
import cmp from '../assets/public/partners/cmp.svg';

export {
    whichmortgage,
    Toronto_Star_logo,
    cmp_top_ind_broker,
    top_independent_brokerages_2020,
    cmp,
    logo,
    logo2,
    logo3,
    rsLogo,
    services1,
    services2,
    services3,
    services4,
    worksImg1,
    worksImg2,
    worksImg3,
    searchImage,
    callImage,
    blog1,
    blog2,
    blog3,
    blogShape,
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    choose1,
    choose2,
    choose3,
    choose4,
    choose5,
    choose6,
    centerImage,
    Quoteicon,
    teamMemberOne,
    teamMemberTwo,
    teamMemberThree,
    csLogo1,
    csLogo2,
    csLogo3,
    csLogo4,
    csLogo5,
    csLogo6,
    csLogo7,
    csLogo8,
    csLogo9,
    csLogo10, 
    csLogo12,
    csLogo13,
    csLogo14,
    csLogo15,
    csLogo16,
    csLogo17,
    csLogo18,
    csLogo19, 
    marble,
    marbleRightImg,
    banner,
    homevalue,
    cmhcinsurance,
    landtransfer,
    affordabilitycalc,
    paymentcalc,
    renewing,
    hoursapproval,
    b2b,
    cmls,
    logodesjardins,
    manulifelogo,
    howitwork,
    howitworkslider,
    renovation,
    emergencies,
    debtconsolidation,
    education,
    businesscapital,
    lifeevents,
    stresstest,
    homebuyer,
    blogbig,
    housing,
    hour,
    approval,
    application,
    creditcardbanner,
    visacard,
    visainfinitecard,
    creditcard,
    mastercard,
    bankingbanner,
    chequing,
    business,
    children,
    savings,
    senior,
    student,
    eqbank,
    alternabank,
    tangerine,
    insurancebanner,
    homeinsurance,
    businessinsurance,
    lifeinsurance,
    rentersinsurance,
    travelinsurance,
    currenciesdirect,
    ofx,
    transferwise,
    worldfirst,
    worldremit,
    contactmap,
    location,
    call,
    career,
    email,
    phone,
    facebook,
    linkedin,
    twitter,
    rss,
    blogimg1,
    blogimg2,
    blogimg3,
    blogimg4,
    latestblog1,
    latestblog2,
    latestblog3,
    latestblog4,
    latestblog5,
    latestblog6,
    chatrobot,
    koho,
    home,
    bmo,
    duca,
    highInterest,
    savingBanner,
    agent1,
    agent2,
    agent3,
    agent4,
    agent5,
    agent6,
    agent7,
    adImage1,
    adImage2,
    adImage3,
    adImage4,
    adImage5,
    adImage6,
    adImage7,
    adImage8,
    defaultProfileImage,
    trusted1,
    trusted2,
    trusted3,
    trusted4,
    trusted5,
    blogBanner,
    viewImg,
    reading,
    clock,
      hourApproval ,
  freeAppraiel ,
  quickClosing ,
  bestRates 
}