import React from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Checkbox from '@mui/joy/Checkbox';
import { Grid } from "@mui/material";
import {array, object, string} from "yup";
import {FieldArray, Form, Formik} from "formik";

{/* <Grid container>
<Grid xs={12} md={4} item> */}
const ApplicationIncome = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const emptyAsset = {
    salary_type: '',
    income: '',
    income_rec: '1',
    business_type: '',
    emp_name_type: '',
    emp_street: '',
    emp_city: '',
    emp_province: '',
    emp_type: '',
    emp_postal: '',
    emp_country: '',
    emp_phone: '',
    emp_email: '',
    sector: '',
    occupation: '',
    job_title: '',
    // time_at_job_year: '',
    // time_at_job_month: '',
    time_at_year: '',
    time_at_month: '',
    year_in_indus: '',
  }
  const initialValues = {
    employment: [emptyAsset],
    is_investment: '',
    investment_income_amount: '',
    investment_income_rec: '1',
    interest_income_amount: '',
    interest_income_rec: '1',
    is_pension: '',
    pension: '',
    pension_rec: '1',
    disability: '',
    disability_rec: '1',
    child_support: '',
    child_support_rec: '1',
    alimony: '',
    alimony_rec: '1',
    other: '',
    other_rec: '1',
    dont_earn: false,
  }
  const formSchema = object({
    employment: array(
      object({
        salary_type: string(),
        income: string().when('salary_type', {is: 'salaried', then: () => string().required('This is required field')}),
        income_rec: string(),
        emp_name_type: string().when('salary_type', {is: 'salaried', then: () => string().required('This is required field')}),
        business_type: string(),
        emp_street: string(),
        emp_city: string(),
        emp_province: string(),
        emp_type: string(),
        emp_postal: string(),
        emp_country: string(),
        emp_phone: string(),
        emp_email: string().email("Enter valid email"),
        sector: string(),
        occupation: string(),
        job_title: string(),
        // time_at_job_year: string(),
        // time_at_job_month: string(),
        time_at_year: string().when('salary_type', {is: 'salaried', then: () => string().required('This is required field')}),
        time_at_month: string().when('salary_type', {is: 'salaried', then: () => string().required('This is required field')}),
        year_in_indus: string()
      }),
    ),
    is_investment: string(),
    investment_income_amount: string().when('is_investment', {is: 'yes', then: () => string().required('This is required field')}),
    investment_income_rec: string().when('is_investment', {is: 'yes', then: () => string().required('This is required field')}),
    interest_income_amount: string().when('is_investment', {is: 'yes', then: () => string().required('This is required field')}),
    interest_income_rec: string().when('is_investment', {is: 'yes', then: () => string().required('This is required field')}),
    is_pension: string(),
    pension: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    pension_rec: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    disability: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    disability_rec: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    child_support: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    child_support_rec: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    alimony: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    alimony_rec: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    other: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    other_rec: string().when('is_pension', {is: 'yes', then: () => string().required('This is required field')}),
    dont_earn: string(),
  });
  const ErrorTypoArray = (touched, errors, index, fieldName) => {
    return (
      <>
        {(touched?.employment && touched?.employment[index] &&
        touched?.employment[index][fieldName] && errors?.employment && errors?.employment[index] &&
        errors?.employment[index][fieldName]) &&
          <Typography color="danger">
            {errors?.employment[index][fieldName]}
          </Typography>
        }
        </>
    )
  }
  const ErrorTypo = (touched, errors, fieldName) => {
    return (
      <>
        {touched[fieldName] &&
          <Typography color="danger">
            {errors[fieldName]}
          </Typography>
        }
        </>
    )
  }
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldTouched}) => (
            <Form className="form-2" method="post" name="income" id="addIncome">
          <div className="mb-10 address-qstn">
            <h2>How do you earn money?</h2>
            <p>All totals should be gross income before tax</p>
            {
              (!values.dont_earn || values.dont_earn === 'false') &&
              <>
                <FieldArray name="employment">
                  {({ push, remove }) => (
                    <>
                      {values.employment.map((_, index) => (
                        <div key={index} role="radiogroup" className=" cs-radio-btn-nw">
                          <div className="cstm-tick-radiogroup">
                            <FormControl>
                              <RadioGroup
                                overlay
                                name={`employment.${index}.salary_type`}
                                onBlur={handleBlur}
                                value={_.salary_type}
                                onChange={handleChange}
                                orientation="vertical"
                                sx={{gap: 2, mt: 1}}
                              >
                                <Sheet
                                  component="label"

                                  variant="outlined"
                                  sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    boxShadow: 'sm',
                                    borderRadius: 'md',
                                    bgcolor: 'background.body',
                                    gap: 1.5,
                                  }}
                                >
                                  <Radio
                                    value="salaried"
                                    sx={{
                                      mt: -1,
                                      mr: -1,
                                      mb: 0.5,
                                      alignSelf: 'flex-end',
                                      '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                    }}
                                  />
                                  <Typography level="body2">Salary</Typography>
                                </Sheet>
                                <Sheet
                                  component="label"

                                  variant="outlined"
                                  sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    boxShadow: 'sm',
                                    borderRadius: 'md',
                                    bgcolor: 'background.body',
                                    gap: 1.5,
                                  }}
                                >
                                  <Radio
                                    value="self-employed"
                                    sx={{
                                      mt: -1,
                                      mr: -1,
                                      mb: 0.5,
                                      alignSelf: 'flex-end',
                                      '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                    }}
                                  />
                                  <Typography level="body2">Self-Employed</Typography>
                                </Sheet>
                              </RadioGroup>
                              {ErrorTypoArray(touched, errors, index, 'salary_type')}
                            </FormControl>
                          </div>
                          {_.salary_type &&
                            <div className="form-added2  form-show2">
                              <Grid container className="emploment-income my-3">
                                <Grid xs={12} md={6} item>
                                  <div className="form-group">
                                    <label>{_.salary_type === 'self-employed' ? 'Income' : 'Employment income'}</label>
                                    <div className="tw-inputs">
                                      <Input name={`employment.${index}.income`}
                                             onBlur={handleBlur}
                                             value={_.income}
                                             onChange={handleChange}
                                             placeholder=" " startDecorator="$"/>

                                      <Select
                                        value={_.income_rec}
                                        slotProps={{
                                          button: {
                                            id: 'select-button',
                                            'aria-labelledby': 'select-label select-button',
                                          }
                                        }}
                                      >
                                        <Option value="1">Yearly</Option>
                                      </Select>
                                    </div>
                                    {ErrorTypoArray(touched, errors, index, 'income')}
                                    <p className="cs-error" id="empInc"></p>
                                  </div>
                                </Grid>
                                {!!(_.salary_type && _.salary_type === 'self-employed') &&
                                  <Grid xs={12} md={6} item className="form-group">
                                    <label htmlFor="self-employed-income">Business Type</label>
                                    <Select
                                      name={`employment.${index}.business_type`}
                                      onClose={() => {setFieldTouched(`employment.${index}.business_type`)}}
                                      value={_.business_type}
                                      onChange={(e, newValue) => {setFieldValue(`employment.${index}.business_type`, newValue)}}
                                      slotProps={{
                                        button: {
                                          id: 'select-button',
                                          'aria-labelledby': 'select-label select-button',
                                        }
                                      }}
                                    >
                                      <Option value="">Please Select</Option>
                                      <Option value="Sole Proprietorship">Sole Proprietorship</Option>
                                      <Option value="Partnership">Partnership</Option>
                                      <Option value="Corporation">Corporation</Option>
                                    </Select>
                                    {ErrorTypoArray(touched, errors, index, 'business_type')}
                                    <div className="cs-error" id="selfEmpType"></div>
                                  </Grid>
                                }
                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>{_.salary_type === 'self-employed' ? 'Business Name' : 'Employer Name'}</FormLabel>
                                    <Input name={`employment.${index}.emp_name_type`}
                                           onBlur={handleBlur}
                                           value={_.emp_name_type}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_name_type')}
                                  </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer Street and Name</FormLabel>
                                    <Input name={`employment.${index}.emp_street`}
                                           onBlur={handleBlur}
                                           value={_.emp_street}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_street')}
                                  </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer City</FormLabel>
                                    <Input name={`employment.${index}.emp_city`}
                                           onBlur={handleBlur}
                                           value={_.emp_city}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_city')}
                                  </FormControl>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                  <div className="form-group">
                                    <label>Employer Province</label>
                                    <Select
                                      name={`employment.${index}.emp_province`}
                                      onClose={() => {setFieldTouched(`employment.${index}.emp_province`)}}
                                      value={_.emp_province}
                                      onChange={(e, newValue) => {setFieldValue(`employment.${index}.emp_province`, newValue)}}
                                      slotProps={{
                                        button: {
                                          id: 'select-button',
                                          'aria-labelledby': 'select-label select-button',
                                        }
                                      }}
                                    >
                                      <Option value="">Please Choose</Option>
                                      <Option value="Alberta">Alberta</Option>
                                      <Option value="British Columbia">British Columbia</Option>
                                      {/* <Option value="Manitoba">Manitoba</Option> */}
                                      <Option value="New Brunswick">New Brunswick</Option>
                                      <Option value="Newfoundland and Labrador">Newfoundland &amp; Labrador</Option>
                                      <Option value="Northwest Territories">Northwest Territories</Option>
                                      <Option value="Nova Scotia">Nova Scotia</Option>
                                      <Option value="Nunavut">Nunavut</Option>
                                      <Option value="Ontario">Ontario</Option>
                                      <Option value="Prince Edward Island">Prince Edward Island</Option>
                                      <Option value="Quebec">Quebec</Option>
                                      <Option value="Saskatchewan">Saskatchewan</Option>
                                      <Option value="Yukon">Yukon</Option>
                                    </Select>
                                    {ErrorTypoArray(touched, errors, index, 'emp_province')}
                                  </div>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                  <div className="form-group">
                                    <label>Employment Type</label>
                                    <Select
                                      name={`employment.${index}.emp_type`}
                                      onClose={() => {setFieldTouched(`employment.${index}.emp_type`)}}
                                      value={_.emp_type}
                                      onChange={(e, newValue) => {setFieldValue(`employment.${index}.emp_type`, newValue)}}
                                      slotProps={{
                                        button: {
                                          id: 'select-button',
                                          'aria-labelledby': 'select-label select-button',
                                        }
                                      }}
                                    >
                                      <Option value="">Select Employment Type</Option>
                                      <Option value="Full Time">Full Time</Option>
                                      <Option value="Part Time">Part Time</Option>
                                      <Option value="Seasonal">Seasonal</Option>
                                    </Select>
                                    {ErrorTypoArray(touched, errors, index, 'emp_type')}
                                  </div>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer Postal Code</FormLabel>
                                    <Input name={`employment.${index}.emp_postal`}
                                           onBlur={handleBlur}
                                           value={_.emp_postal}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_postal')}
                                  </FormControl>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer Country</FormLabel>
                                    <Input name={`employment.${index}.emp_country`}
                                           onBlur={handleBlur}
                                           value={_.emp_country}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_country')}
                                  </FormControl>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer Phone Number</FormLabel>
                                    <Input name={`employment.${index}.emp_phone`}
                                           onBlur={handleBlur}
                                           value={_.emp_phone}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_phone')}
                                  </FormControl>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Employer Email</FormLabel>
                                    <Input name={`employment.${index}.emp_email`}
                                           onBlur={handleBlur}
                                           value={_.emp_email}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'emp_email')}
                                  </FormControl>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Industry Sector</FormLabel>
                                    <Input name={`employment.${index}.sector`}
                                           onBlur={handleBlur}
                                           value={_.sector}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'sector')}
                                  </FormControl>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <div className="form-group">
                                    <label>Occupation</label>
                                    <Select
                                      name={`employment.${index}.occupation`}
                                      onClose={() => {setFieldTouched(`employment.${index}.occupation`)}}
                                      value={_.occupation}
                                      onChange={(e, newValue) => {setFieldValue(`employment.${index}.occupation`, newValue)}}
                                      slotProps={{
                                        button: {
                                          id: 'select-button',
                                          'aria-labelledby': 'select-label select-button',
                                        }
                                      }}
                                    >
                                      <Option value="">Occupation Type</Option>
                                      <Option value="Other">Other</Option>
                                      <Option value="Management">Management</Option>
                                      <Option value="Clerical">Clerical</Option>
                                      <Option value="Labour/Tradesperson">Labour/Tradesperson</Option>
                                      <Option value="Retired">Retired</Option>
                                      <Option value="Professional">Professional</Option>
                                      <Option value="Self-employed">Self-employed</Option>
                                    </Select>
                                    {ErrorTypoArray(touched, errors, index, 'occupation')}
                                  </div>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Job Title</FormLabel>
                                    <Input name={`employment.${index}.job_title`}
                                           onBlur={handleBlur}
                                           value={_.job_title}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'job_title')}
                                  </FormControl>
                                </Grid>

                                {/*<Grid xs={12} md={6} item>
                            <FormControl>
                              <FormLabel>Time at Job (Year)</FormLabel>
                              <Input name={`employment.${index}.time_at_job_year`}
                                     onBlur={handleBlur}
                                     value={_.time_at_job_year}
                                     onChange={handleChange}
                                     placeholder=" "/>
                              {ErrorTypoArray(touched, errors, index, 'time_at_job_year')}
                            </FormControl>
                          </Grid>

                          <Grid xs={12} md={6} item>
                            <FormControl>
                              <FormLabel>Time at Job (Month)</FormLabel>
                              <Input name={`employment.${index}.time_at_job_month`}
                                     onBlur={handleBlur}
                                     value={_.time_at_job_month}
                                     onChange={handleChange}
                                     placeholder=" "/>
                              {ErrorTypoArray(touched, errors, index, 'time_at_job_month')}
                            </FormControl>
                          </Grid>*/}

                                <Grid xs={12} md={6} item>
                                  <label>Time at Job</label>
                                  <div className="tw-inputs">

                                    <Input name={`employment.${index}.time_at_year`}
                                           onBlur={handleBlur}
                                           value={_.time_at_year}
                                           onChange={handleChange}
                                           placeholder="In Year"/>
                                    {ErrorTypoArray(touched, errors, index, 'time_at_year')}

                                    <Input name={`employment.${index}.time_at_month`}
                                           onBlur={handleBlur}
                                           value={_.time_at_month}
                                           onChange={handleChange}
                                           placeholder="In Month"/>
                                    {ErrorTypoArray(touched, errors, index, 'time_at_month')}
                                  </div>
                                </Grid>

                                <Grid xs={12} md={6} item>
                                  <FormControl>
                                    <FormLabel>Years in Industry</FormLabel>
                                    <Input name={`employment.${index}.year_in_indus`}
                                           onBlur={handleBlur}
                                           value={_.year_in_indus}
                                           onChange={handleChange}
                                           placeholder=" "/>
                                    {ErrorTypoArray(touched, errors, index, 'year_in_indus')}
                                  </FormControl>
                                </Grid>
                              </Grid>
                              {/*<div className="form-added3 ">


                          <Grid container className="  emploment-income my-3">
                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Type Of Income</label>
                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Please Choose...</Option>
                                  <Option value="Salary">Salary</Option>
                                  <Option value="Full time hourly">Full time hourly</Option>
                                  <Option value="Part time hourly">Part time hourly</Option>
                                  <Option value="Self-Employed">Self-Employed</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                                <p className="cs-error" id="typeOfIncome"></p>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Income Period</label>
                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Select a Value</Option>
                                  <Option value="1">Annual</Option>
                                  <Option value="2">Monthly</Option>
                                  <Option value="3">Bi Weekly</Option>
                                  <Option value="4">Weekly</Option>
                                </Select>
                              </div>
                            </Grid>
                          </Grid>
                        </div>*/}

                              {/* <!-- Previous Add Remove Button --> */}
                              <div className="csaddress-btn my-3">
                                {values.employment.length === 1 &&
                                  <button onClick={() => push(emptyAsset)} className="next btn addPrevAddress" type="button">Add Previous Employment</button>
                                }
                                {!!(values.employment.length === 2  && index === 0) &&
                                  <button onClick={() => remove(1)} className="next btn removePrevAddress" type="button">Remove Previous Employment</button>
                                }
                              </div>
                              {/* <!-- Previous Add Remove Button --> */}

                              {/*<div className="previous-address-wrap" id="salaryPrevAddress">
                          <div role="radiogroup" className=" cs-radio-btn-nw">
                            <div className="cstm-tick-radiogroup">
                              <FormControl>
                                <RadioGroup
                                  overlay
                                  name="member"
                                  defaultValue="person1"
                                  orientation="vertical"
                                  sx={{gap: 2, mt: 1}}
                                >
                                  <Sheet
                                    component="label"

                                    variant="outlined"
                                    sx={{
                                      p: 2,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'start',
                                      boxShadow: 'sm',
                                      borderRadius: 'md',
                                      bgcolor: 'background.body',
                                      gap: 1.5,
                                    }}
                                  >
                                    <Radio
                                      value="home equity"
                                      sx={{
                                        mt: -1,
                                        mr: -1,
                                        mb: 0.5,
                                        alignSelf: 'flex-end',
                                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                      }}
                                    />
                                    <Typography level="body2">Salary</Typography>
                                  </Sheet>
                                  <Sheet
                                    component="label"

                                    variant="outlined"
                                    sx={{
                                      p: 2,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'start',
                                      boxShadow: 'sm',
                                      borderRadius: 'md',
                                      bgcolor: 'background.body',
                                      gap: 1.5,
                                    }}
                                  >
                                    <Radio
                                      value="private"
                                      sx={{
                                        mt: -1,
                                        mr: -1,
                                        mb: 0.5,
                                        alignSelf: 'flex-end',
                                        '--Radio-actionRadius': (theme) => theme.vars.radius.md,
                                      }}
                                    />
                                    <Typography level="body2">Self-Employed</Typography>
                                  </Sheet>
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>

                          <Grid container className="row emploment-income my-3" id="empsection">
                            <Grid xs={12} md={6} item className=" form-group prevSelfEmpInc">
                              <label htmlFor="self-employed-income">Income</label>
                              <div className="tw-inputs">
                                <Input placeholder=" " startDecorator="$"/>

                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="1">Yearly</Option>
                                </Select>
                              </div>
                              <div className="cs-error" id="selfPrevEmp"></div>
                            </Grid>
                            <Grid xs={12} md={6} item className="form-group prevSelfEmpBus">
                              <label htmlFor="self-employed-income">Business Type</label>
                              <Select
                                slotProps={{
                                  button: {
                                    id: 'select-button',
                                    'aria-labelledby': 'select-label select-button',
                                  }
                                }}
                              >
                                <Option value="">Please Select</Option>
                                <Option value="Sole Proprietorship">Sole Proprietorship</Option>
                                <Option value="Partnership">Partnership</Option>
                                <Option value="Corporation">Corporation</Option>
                              </Select>
                              <div className="cs-error" id="selfPrevEmpType"></div>
                            </Grid>
                            <Grid xs={12} md={6} item className="empIncSalType">
                              <div className="form-group">
                                <label>Employment income</label>
                                <div className="tw-inputs">
                                  <Input placeholder=" " startDecorator="$"/>

                                  <Select
                                    slotProps={{
                                      button: {
                                        id: 'select-button',
                                        'aria-labelledby': 'select-label select-button',
                                      }
                                    }}
                                  >
                                    <Option value="1">Yearly</Option>
                                  </Select>
                                </div>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Business Name</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer Street and Name</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer City</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Employer Province</label>
                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Please Choose</Option>
                                  <Option value="Alberta">Alberta</Option>
                                  <Option value="British Columbia">British Columbia</Option>
                                  <Option value="Manitoba">Manitoba</Option>
                                  <Option value="New Brunswick">New Brunswick</Option>
                                  <Option value="Newfoundland and Labrador">Newfoundland &amp; Labrador</Option>
                                  <Option value="Northwest Territories">Northwest Territories</Option>
                                  <Option value="Nova Scotia">Nova Scotia</Option>
                                  <Option value="Nunavut">Nunavut</Option>
                                  <Option value="Ontario">Ontario</Option>
                                  <Option value="Prince Edward Island">Prince Edward Island</Option>
                                  <Option value="Quebec">Quebec</Option>
                                  <Option value="Saskatchewan">Saskatchewan</Option>
                                  <Option value="Yukon">Yukon</Option>
                                </Select>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Employment Type</label>
                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Select Employment Type</Option>
                                  <Option value="Full Time">Full Time</Option>
                                  <Option value="Part Time">Part Time</Option>
                                  <Option value="Seasonal">Seasonal</Option>
                                </Select>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer Postal Code</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer Country</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer Phone Number</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Employer Email</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Industry Sector</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Occupation</label>
                                <Select
                                  slotProps={{
                                    button: {
                                      id: 'select-button',
                                      'aria-labelledby': 'select-label select-button',
                                    }
                                  }}
                                >
                                  <Option value="">Occupation Type</Option>
                                  <Option value="Other">Other</Option>
                                  <Option value="Management">Management</Option>
                                  <Option value="Clerical">Clerical</Option>
                                  <Option value="Labour/Tradesperson">Labour/Tradesperson</Option>
                                  <Option value="Retired">Retired</Option>
                                  <Option value="Professional">Professional</Option>
                                  <Option value="Self-employed">Self-employed</Option>
                                </Select>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <FormControl>
                                <FormLabel>Job Title</FormLabel>
                                <Input placeholder=" "/>
                              </FormControl>
                            </Grid>
                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Time at Job</label>
                                <div className="tw-inputs">
                                  <Input placeholder=" In Year" startDecorator="$"/>
                                  <Input placeholder=" In Month" startDecorator="$"/>
                                </div>
                              </div>
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div className="form-group">
                                <label>Years in Industry</label>
                                <input type="text" id="" className="form-control" name="prevSalaryYearsInIndustry"
                                       value=""/>
                              </div>
                            </Grid>
                          </Grid>
                        </div>*/}
                            </div>
                          }
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className="earn-money">
                  <ul className="unstyled">
                    <li>
                      <Checkbox
                        name="is_investment"
                        value={values.is_investment.toString()}
                        onChange={handleChange}
                        label="Investment"
                        variant="outlined"/>
                    </li>
                  </ul>
                  {values.is_investment &&
                    <div className="form-added4 ">

                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="investment-income" className="emp">Investment income</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="investment_income_amount"
                                   onBlur={handleBlur}
                                   value={values.investment_income_amount}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            value={values.investment_income_rec}
                            name="investment_income_rec"
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'investment_income_amount')}
                          <p className="cs-error" id="investInc"></p>
                        </Grid>
                      </Grid>
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="interest-income" className="emp">Interest income</label>
                        </Grid>

                        <Grid xs={12} md={6} item>
                          <div className="tw-inputs">
                            <Input name="interest_income_amount"
                                   onBlur={handleBlur}
                                   value={values.interest_income_amount}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="interest_income_rec"
                            value={values.interest_income_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'interest_income_amount')}
                          <p className="cs-error" id="interestInc"></p>
                        </Grid>
                      </Grid>
                    </div>
                  }

                </div>
                <div className="earn-money">
                  <ul className="unstyled">
                    <li>
                      <Checkbox name="is_pension"
                                value={values.is_pension.toString()}
                                onChange={handleChange}
                                label="Pension, disability, other" variant="outlined"/>
                    </li>
                  </ul>
                  {values.is_pension &&
                    <div className="form-added5 ">
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="pension-income" className="emp">Pension</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="pension"
                                   onBlur={handleBlur}
                                   value={values.pension}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="pension_rec"
                            value={values.pension_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'pension')}
                          <p className="cs-error" id="pensionInc"></p>
                        </Grid>
                      </Grid>
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="disability-income" className="emp">Disability</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="disability"
                                   onBlur={handleBlur}
                                   value={values.disability}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="disability_rec"
                            value={values.disability_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'disability')}
                          <p className="cs-error" id="disInc"></p>
                        </Grid>
                      </Grid>
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="child-support-income" className="emp">Child support</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="child_support"
                                   onBlur={handleBlur}
                                   value={values.child_support}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="child_support_rec"
                            value={values.child_support_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'child_support')}
                          <p className="cs-error" id="childInc"></p>
                        </Grid>
                      </Grid>
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="alimony-income" className="emp">Alimony</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="alimony"
                                   onBlur={handleBlur}
                                   value={values.alimony}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="alimony_rec"
                            value={values.alimony_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'alimony')}
                          <p className="cs-error" id="alimonyInc"></p>
                        </Grid>
                      </Grid>
                      <Grid container className="emploment-income   my-3">

                        <Grid xs={12} md={6} item>
                          <label htmlFor="other-income" className="emp">Other</label>
                        </Grid>

                        <Grid xs={12} md={6} item>

                          <div className="tw-inputs">
                            <Input name="other"
                                   onBlur={handleBlur}
                                   value={values.other}
                                   onChange={handleChange}
                                   placeholder="0" startDecorator="$"/> <Select
                            name="other_rec"
                            value={values.other_rec}
                            slotProps={{
                              button: {
                                id: 'select-button',
                                'aria-labelledby': 'select-label select-button',
                              }
                            }}
                          >
                            <Option value="1">Yearly</Option>

                          </Select>
                          </div>
                          {ErrorTypo(touched, errors, 'other')}
                          <p className="cs-error" id="otherInc"></p>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </div>
              </>
            }
            { !values.employment[0].salary_type && <div className="earn-money">
              <ul className="unstyled">
                <li>
                  <Checkbox name="dont_earn"
                            value={values.dont_earn.toString()}
                            onChange={handleChange}
                            label="I don't current earn an income" variant="outlined"/>
                </li>
              </ul>
            </div>}
            <div className="total">
              <div className="mr-5">Total $<span id="total-income">0</span></div>
            </div>
          </div >
          <div className="button-grp d-flex align-items-start">
            <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
              Previous
            </Button>
            <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
              Next
            </Button>
          </div>
        </Form >
          )}
        </Formik>
      </div >
    </>
  );
};

export default ApplicationIncome;
