import React from 'react';
const MortgageSvg = () => {

	return (
		<>
			<svg id="Layer_1" x="0px" y="0px"
				viewBox="0 0 1613.91 1592" >
				<g>
					<g>
						<path className="st0" d="M1345.24,1581.48H268.67c-66.84,0-123.31-56.47-123.31-123.31l3.29-562.87c-7.12-1.13-13.9-4.64-19.07-10.45
			L15.76,756.91c-7.12-8-9.54-18.56-7.46-28.3c-0.59-2.75-0.83-5.6-0.68-8.46c0.44-8.62,4.32-16.7,10.76-22.45L778.62,18.74
			c7.95-7.1,18.54-9.6,28.34-7.5c9.78-2.1,20.39,0.4,28.34,7.5l353.13,315.37l3.38-276.85c0.21-17.71,14.63-31.95,32.34-31.95
			h211.72c8.6,0,16.84,3.42,22.91,9.51c6.07,6.09,9.47,14.35,9.44,22.94l-1.81,524.61l129.14,115.33
			c6.43,5.75,10.31,13.83,10.75,22.45c0.15,2.86-0.09,5.7-0.68,8.46c2.08,9.73-0.33,20.29-7.46,28.3l-113.83,127.94
			c-5.55,6.24-12.96,9.82-20.66,10.66l4.88,562.38C1468.55,1525.01,1412.08,1581.48,1345.24,1581.48z M181.33,806.93
			c4.56,0,9.15,0.96,13.46,2.93c11.57,5.3,18.96,16.88,18.88,29.6l-3.61,618.9c0,31.58,26.84,58.43,58.62,58.43h1076.57
			c30.68,0,58.62-27.94,58.62-58.62l-5.38-620.2c-0.11-12.74,7.27-24.36,18.85-29.67c11.58-5.32,25.21-3.34,34.8,5.05l5.2,4.55
			l77.54-87.16c-0.01-0.17-0.01-0.34-0.02-0.51l-122.39-109.29c-6.9-6.16-10.83-14.98-10.8-24.24l1.75-506.7h-147.31l-3.84,315.99
			c-0.16,12.68-7.7,24.1-19.31,29.21c-11.6,5.11-25.12,2.97-34.58-5.48L808.19,81.26c-0.83,0.03-1.65,0.03-2.45,0L79.07,730.23
			c0,0.17-0.01,0.34-0.02,0.51l77.55,87.17l3.43-3C166.05,809.66,173.65,806.93,181.33,806.93z"/>
					</g>
					<g>
						<path className="st0" d="M806.94,756.95c-17.86,0-32.34-14.48-32.34-32.34v-90.38c0-17.86,14.48-32.34,32.34-32.34
			c17.86,0,32.34,14.48,32.34,32.34v90.38C839.29,742.47,824.81,756.95,806.94,756.95z"/>
					</g>
					<g>
						<path className="st0" d="M806.94,1241.49c-17.86,0-32.34-14.48-32.34-32.34v-90.38c0-17.86,14.48-32.34,32.34-32.34
			c17.86,0,32.34,14.48,32.34,32.34v90.38C839.29,1227.01,824.81,1241.49,806.94,1241.49z"/>
					</g>
					<g>
						<path className="st0" d="M806.96,1370.22c-247.32,0-448.54-201.21-448.54-448.54s201.22-448.54,448.54-448.54
			s448.54,201.21,448.54,448.54S1054.29,1370.22,806.96,1370.22z M806.96,537.84c-211.66,0-383.85,172.19-383.85,383.85
			s172.19,383.85,383.85,383.85c211.65,0,383.85-172.19,383.85-383.85S1018.61,537.84,806.96,537.84z"/>
					</g>
					<g>
						<path className="st0" d="M824.93,1136.82c-10.92,0-22.18-0.64-33.71-1.94c-38.67-4.38-75.94-16.8-115.4-29.94l-7.73-2.58
			c-16.95-5.63-26.13-23.94-20.5-40.89c5.63-16.95,23.93-26.15,40.89-20.5l7.78,2.59c37.73,12.57,70.31,23.42,102.23,27.03
			c65.68,7.45,104.34-12.41,118.67-33.44c8.69-12.77,9.65-27.01,2.7-40.1c-14.31-26.96-55.68-43.01-110.76-43.01
			c-0.66,0-1.33,0-1.98,0c-82.3,0-144.37-28.07-170.42-77.09c-17.99-33.86-15.53-73.48,6.57-105.97
			c32.78-48.2,100.6-71.57,181.41-62.47c38.82,4.36,76.34,16.85,116.06,30.08l7.39,2.45c16.95,5.63,26.13,23.94,20.5,40.89
			c-5.63,16.95-23.94,26.14-40.89,20.5l-7.43-2.47c-37.99-12.65-70.79-23.57-102.85-27.17c-66.56-7.46-106,12.95-120.7,34.57
			c-8.61,12.66-9.65,26.6-2.93,39.24c14.24,26.8,56.54,42.76,113.27,42.76c0.66,0,1.33,0,1.99,0c80.62,0,141.78,28.16,167.9,77.36
			c18.21,34.3,15.83,74.23-6.36,106.84C942.99,1114.17,889.86,1136.81,824.93,1136.82z"/>
					</g>
				</g>
			</svg>
		</>
	)
}

export default MortgageSvg