import React, {useEffect, useState} from "react";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import { Grid } from "@mui/material";
import { array, object, string } from 'yup';

import {FieldArray, Form, Formik} from "formik";
import Typography from "@mui/joy/Typography";

const ApplicationProperties = () => {
  const handleFormSubmit = (values) => {
    // console.log('valuesss', values);
  }
  const emptyAsset = {
    located: '',
    street_number: '',
    street_name: '',
    unit: '',
    city: '',
    province: '',
    dependents: '',
    postal: '',
    unit2: '',
    property_purpose: '',
    title_type: '',
    property_value: '',
    property_tax: '',
    heating_expense: '',
    mortgage: '',
    mortgage_payment: '',
    mortgage_recurring: '',
    mortgage_balance: '',
    maturity_date: '',
    maturity_month: '',
    maturity_year: '',
    lender: '',
    other_Lender: '',
    loan_type: '',
    interest_rate_type: '',
    interest_rate: '',
  }
  const initialValues = {
    properties: []
  }
  const formSchema = object({
    properties: array(
      object({
        located: string().required('This is required field'),
        street_number: string().required('This is required field'),
        street_name: string().required('This is required field'),
        unit: string(),
        city: string().required('This is required field'),
        province: string().required('This is required field'),
        dependents: string().required('This is required field'),
        postal: string().required('This is required field'),
        unit2: string().required('This is required field'),
        property_purpose: string().required('This is required field'),
        title_type: string().required('This is required field'),
        property_value: string().required('This is required field'),
        property_tax: string().required('This is required field'),
        heating_expense: string().required('This is required field'),
        mortgage: string().required('This is required field'),
        mortgage_payment: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        mortgage_recurring: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        mortgage_balance: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        maturity_date: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        maturity_month: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        maturity_year: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        lender: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        other_Lender: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        loan_type: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        interest_rate_type: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
        interest_rate: string().when('mortgage', {is: 'Yes', then: () => string().required('This is required field')}),
      })
    ).min(1, 'You need to provide at least 1 property')
  });
  return (
    <>
      <div className="tabbing-of-home">
        <h1>Let's Complete Your Application</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={async (values) => {
            handleFormSubmit(values);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, setFieldValue, setFieldTouched}) => (
            <Form className="form-1" method="post" name="properties" id="addProperties"  >
              <FieldArray name="properties">
                {({ push, remove }) => (
                  <>
                    <div className="mb-10">
                      <h2>Properties you own</h2>
                    </div>
                    {!values.properties.length &&  <div className="inline-div">
                      <div className="add-prop mb-3" onClick={() => push(emptyAsset)}>
                        <span>
                          <svg viewBox="0 0 24 24" className="fill-current">
                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                          </svg>
                        </span>
                        <p>Add another property</p>
                      </div>
                    </div>}
                    <div className="add-property mb-3 ">
                      {values.properties.map((_, index) => (
                        <div key={index}>
                          <Grid container>
                            <Grid xs={12} item>
                              <FormControl>
                                <FormLabel>Where is this property located?</FormLabel>
                                <Input name={`properties.${index}.located`}
                                       onBlur={handleBlur}
                                       value={_.located}
                                       onChange={handleChange}
                                       placeholder="Enter a Location" />
                                {(touched?.properties && touched?.properties[index] &&
                                    touched?.properties[index].located && errors?.properties && errors?.properties[index] &&
                                    errors?.properties[index].located) &&
                                  <Typography color="danger">
                                    {errors?.properties[index].located}
                                  </Typography>
                                }
                              </FormControl>
                              <div className="form-added ">
                                <Grid container>
                                <Grid xs={12} item className="enter-address">Enter Address Manually</Grid>
                                  <Grid xs={12} md={3} item>
                                    <FormControl>
                                      <FormLabel>Street Number</FormLabel>
                                      <Input name={`properties.${index}.street_number`}
                                             onBlur={handleBlur}
                                             value={_.street_number}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].street_number && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].street_number) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].street_number}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>

                                  <Grid xs={12} md={6} item>
                                    <FormControl>
                                      <FormLabel>Street Name</FormLabel>
                                      <Input name={`properties.${index}.street_name`}
                                             onBlur={handleBlur}
                                             value={_.street_name}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].street_name && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].street_name) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].street_name}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={12} md={3} item>

                                    <FormControl>
                                      <FormLabel>Unit</FormLabel>
                                      <Input name={`properties.${index}.unit`}
                                             onBlur={handleBlur}
                                             value={_.unit}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].unit && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].unit) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].unit}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={12} md={5} item>
                                    <FormControl>
                                      <FormLabel>City</FormLabel>
                                      <Input name={`properties.${index}.city`}
                                             onBlur={handleBlur}
                                             value={_.city}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].city && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].city) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].city}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <div className="form-group">
                                      <label>Province</label> <Select
                                      name={`properties.${index}.province`}
                                      onClose={() => {setFieldTouched(`properties.${index}.province`)}}
                                      value={_.province}
                                      onChange={(e, newValue) => {setFieldValue(`properties.${index}.province`, newValue)}}
                                      slotProps={{
                                          button: {
                                            id: 'select-button',
                                            'aria-labelledby': 'select-label select-button',
                                          }
                                        }}
                                      >
                                        <Option value="">Please Choose</Option>
                                        <Option value="Alberta">Alberta</Option>
                                        <Option value="British Columbia">British Columbia</Option>
                                        {/* <Option value="Manitoba">Manitoba</Option> */}
                                        <Option value="New Brunswick">New Brunswick</Option>
                                        <Option value="Newfoundland and Labrador">Newfoundland &amp; Labrador</Option>
                                        <Option value="Northwest Territories">Northwest Territories</Option>
                                        <Option value="Nova Scotia">Nova Scotia</Option>
                                        <Option value="Nunavut">Nunavut</Option>
                                        <Option value="Ontario">Ontario</Option>
                                        <Option value="Prince Edward Island">Prince Edward Island</Option>
                                        <Option value="Quebec">Quebec</Option>
                                        <Option value="Saskatchewan">Saskatchewan</Option>
                                        <Option value="Yukon">Yukon</Option>
                                      </Select>
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].province && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].province) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].province}
                                        </Typography>
                                      }
                                    </div>
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    <FormControl>
                                      <FormLabel>Dependents</FormLabel>
                                      <Input name={`properties.${index}.dependents`}
                                             onBlur={handleBlur}
                                             value={_.dependents}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].dependents && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].dependents) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].dependents}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={12} md={3} item>
                                    <div className="form-group">
                                      <FormControl>
                                        <FormLabel>Postal Code</FormLabel>
                                        <Input name={`properties.${index}.postal`}
                                               onBlur={handleBlur}
                                               value={_.postal}
                                               onChange={handleChange}
                                               placeholder="Placeholder" />
                                        {(touched?.properties && touched?.properties[index] &&
                                            touched?.properties[index].postal && errors?.properties && errors?.properties[index] &&
                                            errors?.properties[index].postal) &&
                                          <Typography color="danger">
                                            {errors?.properties[index].postal}
                                          </Typography>
                                        }
                                      </FormControl>
                                    </div>
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                    <FormControl>
                                      <FormLabel>Unit</FormLabel>
                                      <Input name={`properties.${index}.unit2`}
                                             onBlur={handleBlur}
                                             value={_.unit2}
                                             onChange={handleChange}
                                             placeholder="Placeholder" />
                                      {(touched?.properties && touched?.properties[index] &&
                                          touched?.properties[index].unit2 && errors?.properties && errors?.properties[index] &&
                                          errors?.properties[index].unit2) &&
                                        <Typography color="danger">
                                          {errors?.properties[index].unit2}
                                        </Typography>
                                      }
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                          <div className="mb-10">
                        <h2>Property purpose</h2>

                        <Grid container>
                          <Grid xs={12} item>
                            <div className="form-group">
                              <label>How will this property be used?</label> <Select
                              name={`properties.${index}.property_purpose`}
                              onClose={() => {setFieldTouched(`properties.${index}.property_purpose`)}}
                              value={_.property_purpose}
                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.property_purpose`, newValue)}}
                              slotProps={{
                                  button: {
                                    id: 'select-button',
                                    'aria-labelledby': 'select-label select-button',
                                  }
                                }}
                              >
                                <Option value="">Please Select</Option>
                                <Option value="Owner-occupied">Owner occupied</Option>
                                <Option value="Owner-occupied and rental">Owner occupied w/rental</Option>
                                <Option value="Second home">Secondary residence</Option>
                                <Option value="Rental">Rental</Option>
                                <Option value="Selling/Sold">Selling/Sold</Option>
                              </Select>
                              {(touched?.properties && touched?.properties[index] &&
                                  touched?.properties[index].property_purpose && errors?.properties && errors?.properties[index] &&
                                  errors?.properties[index].property_purpose) &&
                                <Typography color="danger">
                                  {errors?.properties[index].property_purpose}
                                </Typography>
                              }
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid xs={12} item>
                            <div className="form-group">
                              <label>Title type</label> <Select
                              name={`properties.${index}.title_type`}
                              onClose={() => {setFieldTouched(`properties.${index}.title_type`)}}
                              value={_.title_type}
                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.title_type`, newValue)}}
                              slotProps={{
                                  button: {
                                    id: 'select-button',
                                    'aria-labelledby': 'select-label select-button',
                                  }
                                }}
                              >
                                <Option value="">Please Select</Option>
                                <Option value="Freehold">Freehold</Option>
                                <Option value="Leasehold">Leasehold</Option>
                                <Option value="Condo / Strata">Condo / Strata</Option>
                                <Option value="Indian Reserve">Indian Reserve</Option>
                                <Option value="Other...">Other...</Option>
                              </Select>
                              {(touched?.properties && touched?.properties[index] &&
                                  touched?.properties[index].title_type && errors?.properties && errors?.properties[index] &&
                                  errors?.properties[index].title_type) &&
                                <Typography color="danger">
                                  {errors?.properties[index].title_type}
                                </Typography>
                              }
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid xs={12} item>
                            <div className="form-group">
                              <label>Estimated property value</label>
                              <Input
                                name={`properties.${index}.property_value`}
                                onBlur={handleBlur}
                                value={_.property_value}
                                onChange={handleChange}
                                placeholder="0"
                                startDecorator="$"
                              />
                              {(touched?.properties && touched?.properties[index] &&
                                  touched?.properties[index].property_value && errors?.properties && errors?.properties[index] &&
                                  errors?.properties[index].property_value) &&
                                <Typography color="danger">
                                  {errors?.properties[index].property_value}
                                </Typography>
                              }
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid xs={12} item>

                            <Grid container>

                              <Grid xs={12} md={6} item>
                                <FormControl>
                                  <FormLabel>Property tax</FormLabel>
                                  <Input
                                    name={`properties.${index}.property_tax`}
                                    onBlur={handleBlur}
                                    value={_.property_tax}
                                    onChange={handleChange}
                                    placeholder="0"
                                    startDecorator="$"
                                    endDecorator="/year"
                                  />
                                  {(touched?.properties && touched?.properties[index] &&
                                      touched?.properties[index].property_tax && errors?.properties && errors?.properties[index] &&
                                      errors?.properties[index].property_tax) &&
                                    <Typography color="danger">
                                      {errors?.properties[index].property_tax}
                                    </Typography>
                                  }
                                </FormControl>
                              </Grid>

                              <Grid xs={12} md={6} item>
                                <div className="form-group">
                                  <label>Heating expense</label>
                                  <Input
                                    name={`properties.${index}.heating_expense`}
                                    onBlur={handleBlur}
                                    value={_.heating_expense}
                                    onChange={handleChange}
                                    placeholder="0"
                                    startDecorator="$"
                                    endDecorator="/month"
                                  />
                                  {(touched?.properties && touched?.properties[index] &&
                                      touched?.properties[index].heating_expense && errors?.properties && errors?.properties[index] &&
                                      errors?.properties[index].heating_expense) &&
                                    <Typography color="danger">
                                      {errors?.properties[index].heating_expense}
                                    </Typography>
                                  }
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid xs={12} md={8} item>
                            <div className="form-group">

                              <Grid container>
                                <Grid xs={12} md={5} item>
                                  <label>Does this property have a mortgage?</label>
                                </Grid>
                                <Grid xs={12} md={7}>
                                  <div role="group" className="property-check d-flex ">
                                    <Radio name={`properties.${index}.mortgage`}
                                           checked={_.mortgage === 'Yes'}
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           label="Yes" value="Yes" className="mb-2 mr-2" />
                                    <Radio name={`properties.${index}.mortgage`}
                                           checked={_.mortgage === 'No'}
                                           onBlur={handleBlur}
                                           onChange={handleChange}
                                           label="No" value="No" className="mb-2" />
                                  </div>
                                  {(touched?.properties && touched?.properties[index] &&
                                      touched?.properties[index].mortgage && errors?.properties && errors?.properties[index] &&
                                      errors?.properties[index].mortgage) &&
                                    <Typography color="danger">
                                      {errors?.properties[index].mortgage}
                                    </Typography>
                                  }
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                            {!!(values.properties && values.properties[index] && values.properties[index].mortgage === 'Yes') &&
                              <div className="mortgage-property" >
                                <Grid container>
                                  <Grid xs={12} item>

                                    <Grid container>
                                      <Grid xs={12} md={6} item>
                                        <div className="form-group">
                                          <label>Mortgage Payment</label>
                                          <div className="input-groups-cs tw-inputs">
                                            <Input
                                              name={`properties.${index}.mortgage_Payment`}
                                              onBlur={handleBlur}
                                              value={_.mortgage_Payment}
                                              onChange={handleChange}
                                              placeholder="0"
                                              startDecorator="$"
                                            />
                                            {(touched?.properties && touched?.properties[index] &&
                                                touched?.properties[index].mortgage_Payment && errors?.properties && errors?.properties[index] &&
                                                errors?.properties[index].mortgage_Payment) &&
                                              <Typography color="danger">
                                                {errors?.properties[index].mortgage_Payment}
                                              </Typography>
                                            }
                                            <Select
                                              name={`properties.${index}.mortgage_recurring`}
                                              onClose={() => {setFieldTouched(`properties.${index}.mortgage_recurring`)}}
                                              value={_.mortgage_recurring}
                                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.mortgage_recurring`, newValue)}}
                                              slotProps={{
                                                button: {
                                                  id: 'select-button',
                                                  'aria-labelledby': 'select-label select-button',
                                                }
                                              }}
                                            >
                                              <Option value="2">Monthly</Option>
                                              <Option value="3">Bi-Weekly</Option>
                                              <Option value="4">Weekly</Option>
                                            </Select>
                                            {(touched?.properties && touched?.properties[index] &&
                                                touched?.properties[index].mortgage_recurring && errors?.properties && errors?.properties[index] &&
                                                errors?.properties[index].mortgage_recurring) &&
                                              <Typography color="danger">
                                                {errors?.properties[index].mortgage_recurring}
                                              </Typography>
                                            }
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item>
                                        <div className="form-group">
                                          <label>Mortgage Balance</label>
                                          <Input
                                            name={`properties.${index}.mortgage_balance`}
                                            onBlur={handleBlur}
                                            value={_.mortgage_balance}
                                            onChange={handleChange}
                                            placeholder="0"
                                            startDecorator="$"
                                          />
                                          {(touched?.properties && touched?.properties[index] &&
                                              touched?.properties[index].mortgage_balance && errors?.properties && errors?.properties[index] &&
                                              errors?.properties[index].mortgage_balance) &&
                                            <Typography color="danger">
                                              {errors?.properties[index].mortgage_balance}
                                            </Typography>
                                          }
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item>
                                        <div className="form-group">
                                          <label>Maturity date</label>
                                          <div className="tw-inputs thre-inputs">
                                            <Select
                                              name={`properties.${index}.maturity_date`}
                                              onClose={() => {setFieldTouched(`properties.${index}.maturity_date`)}}
                                              value={_.maturity_date}
                                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.maturity_date`, newValue)}}
                                              slotProps={{
                                                button: {
                                                  id: 'select-button',
                                                  'aria-labelledby': 'select-label select-button',
                                                }
                                              }}
                                            >
                                              <Option value="">Day</Option>
                                              <Option value="1">1</Option>
                                              <Option value="2">2</Option>
                                              <Option value="3">3</Option>
                                              <Option value="4">4</Option>
                                              <Option value="5">5</Option>
                                              <Option value="6">6</Option>
                                              <Option value="7">7</Option>
                                              <Option value="8">8</Option>
                                              <Option value="9">9</Option>
                                              <Option value="10">10</Option>
                                              <Option value="11">11</Option>
                                              <Option value="12">12</Option>
                                              <Option value="13">13</Option>
                                              <Option value="14">14</Option>
                                              <Option value="15">15</Option>
                                              <Option value="16">16</Option>
                                              <Option value="17">17</Option>
                                              <Option value="18">18</Option>
                                              <Option value="19">19</Option>
                                              <Option value="20">20</Option>
                                              <Option value="21">21</Option>
                                              <Option value="22">22</Option>
                                              <Option value="23">23</Option>
                                              <Option value="24">24</Option>
                                              <Option value="25">25</Option>
                                              <Option value="26">26</Option>
                                              <Option value="27">27</Option>
                                              <Option value="28">28</Option>
                                              <Option value="29">29</Option>
                                              <Option value="30">30</Option>
                                              <Option value="31">31</Option>
                                            </Select>
                                            {(touched?.properties && touched?.properties[index] &&
                                                touched?.properties[index].maturity_date && errors?.properties && errors?.properties[index] &&
                                                errors?.properties[index].maturity_date) &&
                                              <Typography color="danger">
                                                {errors?.properties[index].maturity_date}
                                              </Typography>
                                            }
                                            <Select
                                              name={`properties.${index}.maturity_month`}
                                              onClose={() => {setFieldTouched(`properties.${index}.maturity_month`)}}
                                              value={_.maturity_month}
                                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.maturity_month`, newValue)}}
                                              slotProps={{
                                                button: {
                                                  id: 'select-button',
                                                  'aria-labelledby': 'select-label select-button',
                                                }
                                              }}
                                            >
                                              <Option value="">Month</Option>
                                              <Option value="01">January</Option>
                                              <Option value="02">February</Option>
                                              <Option value="03">March</Option>
                                              <Option value="04">April</Option>
                                              <Option value="05">May</Option>
                                              <Option value="06">June</Option>
                                              <Option value="07">July</Option>
                                              <Option value="08">August</Option>
                                              <Option value="09">September</Option>
                                              <Option value="10">October</Option>
                                              <Option value="11">November</Option>
                                              <Option value="12">December</Option>
                                            </Select>
                                            {(touched?.properties && touched?.properties[index] &&
                                                touched?.properties[index].maturity_month && errors?.properties && errors?.properties[index] &&
                                                errors?.properties[index].maturity_month) &&
                                              <Typography color="danger">
                                                {errors?.properties[index].maturity_month}
                                              </Typography>
                                            }
                                            <Select
                                              name={`properties.${index}.maturity_year`}
                                              onClose={() => {setFieldTouched(`properties.${index}.maturity_year`)}}
                                              value={_.maturity_year}
                                              onChange={(e, newValue) => {setFieldValue(`properties.${index}.maturity_year`, newValue)}}
                                              slotProps={{
                                                button: {
                                                  id: 'select-button',
                                                  'aria-labelledby': 'select-label select-button',
                                                }
                                              }}
                                            >
                                              <Option value="">Year</Option>
                                              <Option value="2023">2023</Option>
                                              <Option value="2024">2024</Option>
                                              <Option value="2025">2025</Option>
                                              <Option value="2026">2026</Option>
                                              <Option value="2027">2027</Option>
                                              <Option value="2028">2028</Option>
                                              <Option value="2029">2029</Option>
                                              <Option value="2030">2030</Option>
                                              <Option value="2031">2031</Option>
                                              <Option value="2032">2032</Option>
                                              <Option value="2033">2033</Option>
                                              <Option value="2034">2034</Option>
                                              <Option value="2035">2035</Option>
                                              <Option value="2036">2036</Option>
                                              <Option value="2037">2037</Option>
                                              <Option value="2038">2038</Option>
                                              <Option value="2039">2039</Option>
                                              <Option value="2040">2040</Option>
                                              <Option value="2041">2041</Option>
                                              <Option value="2042">2042</Option>
                                              <Option value="2043">2043</Option>
                                              <Option value="2044">2044</Option>
                                              <Option value="2045">2045</Option>
                                              <Option value="2046">2046</Option>
                                              <Option value="2047">2047</Option>
                                              <Option value="2048">2048</Option>
                                              <Option value="2049">2049</Option>
                                              <Option value="2050">2050</Option>
                                              <Option value="2051">2051</Option>
                                              <Option value="2052">2052</Option>
                                              <Option value="2053">2053</Option>
                                              <Option value="2054">2054</Option>
                                              <Option value="2055">2055</Option>
                                              <Option value="2056">2056</Option>
                                              <Option value="2057">2057</Option>
                                              <Option value="2058">2058</Option>
                                              <Option value="2059">2059</Option>
                                              <Option value="2060">2060</Option>
                                              <Option value="2061">2061</Option>
                                              <Option value="2062">2062</Option>
                                              <Option value="2063">2063</Option>
                                              <Option value="2064">2064</Option>
                                              <Option value="2065">2065</Option>
                                              <Option value="2066">2066</Option>
                                              <Option value="2067">2067</Option>
                                              <Option value="2068">2068</Option>
                                              <Option value="2069">2069</Option>
                                              <Option value="2070">2070</Option>
                                              <Option value="2071">2071</Option>
                                              <Option value="2072">2072</Option>
                                              <Option value="2073">2073</Option>
                                            </Select>
                                            {(touched?.properties && touched?.properties[index] &&
                                                touched?.properties[index].maturity_year && errors?.properties && errors?.properties[index] &&
                                                errors?.properties[index].maturity_year) &&
                                              <Typography color="danger">
                                                {errors?.properties[index].maturity_year}
                                              </Typography>
                                            }
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item>
                                        <div className="form-group">
                                          <label>Lender</label> <Select
                                          name={`properties.${index}.lender`}
                                          onClose={() => {setFieldTouched(`properties.${index}.lender`)}}
                                          value={_.lender}
                                          onChange={(e, newValue) => {setFieldValue(`properties.${index}.lender`, newValue)}}
                                          slotProps={{
                                            button: {
                                              id: 'select-button',
                                              'aria-labelledby': 'select-label select-button',
                                            }
                                          }}
                                        >
                                          <Option value="" data-select2-id="2">Please Select</Option>
                                          <Option value="ADS Canadian Bank">ADS Canadian Bank</Option>
                                          <Option value="Alterna Bank">Alterna Bank</Option>
                                          <Option value="B2B Bank">B2B Bank</Option>
                                          <Option value="Bank of Montreal">Bank of Montreal</Option>
                                          <Option value="Bank of Nova Scotia">Bank of Nova Scotia</Option>
                                          <Option value="Bridgewater Bank">Bridgewater Bank</Option>
                                          <Option value="Canadian Imperial Bank of Commerce">Canadian Imperial Bank of Commerce</Option>
                                          <Option value="Canadian Tire Bank">Canadian Tire Bank</Option>
                                          <Option value="Canadian Western Bank">Canadian Western Bank</Option>
                                          <Option value="Continental Bank of Canada">Continental Bank of Canada</Option>
                                          <Option value="Concentra Bank">Concentra Bank</Option>
                                          <Option value="DirectCash Bank">DirectCash Bank</Option>
                                          <Option value="Duo Bank">Duo Bank</Option>
                                          <Option value="Equitable Bank">Equitable Bank</Option>
                                          <Option value="Exchange Bank of Canada">Exchange Bank of Canada</Option>
                                          <Option value="First Nations Bank of Canada">First Nations Bank of Canada</Option>
                                          <Option value="General Bank of Canada">General Bank of Canada</Option>
                                          <Option value="Haventree Bank">Haventree Bank</Option>
                                          <Option value="Home Bank">Home Bank</Option>
                                          <Option value="HomeEquity Bank">HomeEquity Bank</Option>
                                          <Option value="Laurentian Bank of Canada">Laurentian Bank of Canada</Option>
                                          <Option value="Manulife Bank of Canada">Manulife Bank of Canada</Option>
                                          <Option value="Motus Bank">Motus Bank</Option>
                                          <Option value="National Bank of Canada">National Bank of Canada</Option>
                                          <Option value="Rogers Bank">Rogers Bank</Option>
                                          <Option value="Royal Bank of Canada">Royal Bank of Canada</Option>
                                          <Option value="Street Capital Bank of Canada">Street Capital Bank of Canada</Option>
                                          <Option value="Tangerine Bank">Tangerine Bank</Option>
                                          <Option value="Toronto-Dominion Bank">Toronto-Dominion Bank</Option>
                                          <Option value="Vancity Community Investment Bank">Vancity Community Investment Bank</Option>
                                          <Option value="Versa Bank">Versa Bank</Option>
                                          <Option value="Wealth One Bank of Canada">Wealth One Bank of Canada</Option>
                                          <Option value="Zag Bank">Zag Bank</Option>
                                          <Option value="Vancity">Vancity</Option>
                                          <Option value="Coast Capital Savings">Coast Capital Savings</Option>
                                          <Option value="Meridian Credit Union">Meridian Credit Union</Option>
                                          <Option value="Servus Credit Union">Servus Credit Union</Option>
                                          <Option value="First West Credit Union">First West Credit Union</Option>
                                          <Option value="Conexus Credit Union">Conexus Credit Union</Option>
                                          <Option value="Steinbach Credit Union">Steinbach Credit Union</Option>
                                          <Option value="Affinity Credit Union">Affinity Credit Union</Option>
                                          <Option value="Alterna Savings and Credit Union Limited">Alterna Savings and Credit Union Limited</Option>
                                          <Option value="Connect First Credit Union">Connect First Credit Union</Option>
                                          <Option value="Assiniboine Credit Union">Assiniboine Credit Union</Option>
                                          <Option value="BlueShore Financial Credit Union">BlueShore Financial Credit Union</Option>
                                          <Option value="UNI Financial Cooperation">UNI Financial Cooperation</Option>
                                          <Option value="FirstOntario Credit Union Limited">FirstOntario Credit Union Limited</Option>
                                          <Option value="DUCA Credit Union">DUCA Credit Union</Option>
                                          <Option value="Libro Credit Union">Libro Credit Union</Option>
                                          <Option value="Innovation Credit Union">Innovation Credit Union</Option>
                                          <Option value="Access Credit Union">Access Credit Union</Option>
                                          <Option value="G&amp;F Financial Group">G&amp;F Financial Group</Option>
                                          <Option value="Pace Savings &amp; Credit Union">Pace Savings &amp; Credit Union</Option>
                                          <Option value="Other">Other</Option>
                                        </Select>
                                          {(touched?.properties && touched?.properties[index] &&
                                              touched?.properties[index].lender && errors?.properties && errors?.properties[index] &&
                                              errors?.properties[index].lender) &&
                                            <Typography color="danger">
                                              {errors?.properties[index].lender}
                                            </Typography>
                                          }
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item className="otherLender" >
                                        <div className="form-group">
                                          <label>Other Lender</label>
                                          <Input
                                            name={`properties.${index}.other_Lender`}
                                            onBlur={handleBlur}
                                            value={_.other_Lender}
                                            onChange={handleChange}
                                            placeholder="Other Lender"
                                            startDecorator="$"
                                          />
                                          {(touched?.properties && touched?.properties[index] &&
                                              touched?.properties[index].other_Lender && errors?.properties && errors?.properties[index] &&
                                              errors?.properties[index].other_Lender) &&
                                            <Typography color="danger">
                                              {errors?.properties[index].other_Lender}
                                            </Typography>
                                          }
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item>
                                        <div className="form-group">
                                          <label>Loan Type</label>

                                          <Select
                                            name={`properties.${index}.loan_type`}
                                            onClose={() => {setFieldTouched(`properties.${index}.loan_type`)}}
                                            value={_.loan_type}
                                            onChange={(e, newValue) => {setFieldValue(`properties.${index}.loan_type`, newValue)}}
                                            slotProps={{
                                              button: {
                                                id: 'select-button',
                                                'aria-labelledby': 'select-label select-button',
                                              }
                                            }}
                                          >
                                            <Option value="">Please Select</Option>
                                            <Option value="term">Standard mortgage (Term)</Option>
                                            <Option value="heloc">Home equity line of credit (HELOC)</Option>
                                          </Select>
                                          {(touched?.properties && touched?.properties[index] &&
                                              touched?.properties[index].loan_type && errors?.properties && errors?.properties[index] &&
                                              errors?.properties[index].loan_type) &&
                                            <Typography color="danger">
                                              {errors?.properties[index].loan_type}
                                            </Typography>
                                          }
                                        </div>
                                      </Grid>
                                      <Grid xs={12} md={6} item>

                                        <Grid container>
                                          <Grid xs={12} md={7} item>
                                            <div className="form-group">
                                              <label>Interest rate type</label>

                                              <Select
                                                name={`properties.${index}.interest_rate_type`}
                                                onClose={() => {setFieldTouched(`properties.${index}.interest_rate_type`)}}
                                                value={_.interest_rate_type}
                                                onChange={(e, newValue) => {setFieldValue(`properties.${index}.interest_rate_type`, newValue)}}
                                                slotProps={{
                                                  button: {
                                                    id: 'select-button',
                                                    'aria-labelledby': 'select-label select-button',
                                                  }
                                                }}
                                              >
                                                <Option value="">Please Select</Option>
                                                <Option value="fixed">Fixed</Option>
                                                <Option value="variable">Variable</Option>
                                                <Option value="adjustable">Adjustable</Option>
                                              </Select>
                                              {(touched?.properties && touched?.properties[index] &&
                                                  touched?.properties[index].interest_rate_type && errors?.properties && errors?.properties[index] &&
                                                  errors?.properties[index].interest_rate_type) &&
                                                <Typography color="danger">
                                                  {errors?.properties[index].interest_rate_type}
                                                </Typography>
                                              }
                                            </div>
                                          </Grid>
                                          <Grid xs={12} md={5} item>
                                            <div className="form-group">
                                              <label>Interest rate</label>
                                              <Input
                                                name={`properties.${index}.interest_rate`}
                                                onBlur={handleBlur}
                                                value={_.interest_rate}
                                                onChange={handleChange}
                                                placeholder="0.0"
                                                startDecorator="%"
                                              />
                                              {(touched?.properties && touched?.properties[index] &&
                                                  touched?.properties[index].interest_rate && errors?.properties && errors?.properties[index] &&
                                                  errors?.properties[index].interest_rate) &&
                                                <Typography color="danger">
                                                  {errors?.properties[index].interest_rate}
                                                </Typography>
                                              }
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid >
                              </div >
                            }
                      </div >
                        </div>
                      ))}
                    </div >
                    <div className="button-grp d-flex align-items-start">
                <Button variant="outlined" className="prev btn" startDecorator={<KeyboardArrowLeft />} color="success">
                  Previous
                </Button>
                <Button type="submit" variant="soft" className="next btn" endDecorator={<KeyboardArrowRight />} color="success">
                  Next
                </Button>
              </div>
                  </>
                )}
              </FieldArray>
            </Form >
          )}
        </Formik>
      </div >
    </>
  );
};

export default ApplicationProperties;
